import React, { createContext, useState, useContext } from "react";
// import useNotifications from "../testing-data/useNotifications";

/**
 *
 * @param {Component} child
 * @returns {ContextProvider}
 */

interface SidebarContextProps {
  children: React.ReactNode;
}

interface SidebarContextInterface {
  setShowSidebar: (arg0: boolean) => void;
  showSidebar: boolean;
}

const SidebarContext = createContext<SidebarContextInterface>(
  {} as SidebarContextInterface
);

const SidebarContextProvider = ({ children }: SidebarContextProps) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  const globalData = {
    setShowSidebar,
    showSidebar,
  };

  return (
    <SidebarContext.Provider value={globalData}>
      {children}
    </SidebarContext.Provider>
  );
};

const useSidebarContext = () => {
  let data = useContext(SidebarContext);
  return data;
};

export { useSidebarContext, SidebarContextProvider };
