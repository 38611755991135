import {
  useTable,
  useSortBy,
  usePagination,
  Cell,
  Row,
  ColumnInstance,
} from "react-table";
import React, { useState, useEffect, useRef, ReactNode } from "react";
import { useNavigate } from "react-router";
import FilterModal from "./FilterModal";
import { getHeaders } from "./headers";
import { fetchCasesMiddleware } from "../../middleware/conflictCaseMiddleware";
import { FILTER_FUNCTIONS } from "./filterStaticValues";
import { FilterListItems } from "./FilterListItems";
import { getTimeString, getTimeStringShort } from "../../utils/timeUtils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import { useTheme } from "@emotion/react";
import { FaFilter } from "react-icons/fa";
import {
  Button,
  Paper,
  Popover,
  Skeleton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import SingularCase from "../../model/store/singularCase";
import caseFilterSlice, {
  caseFilterActions,
} from "../../store/caseFilterSlice";
import Filter from "../../model/store/filter";
import logger from "../../utils/logger";
const INITIAL_PAGE_SIZE = 10;

const CasesList = () => {
  const allCasesForUser = useAppSelector(
    (state) => state.conflictCase.caseList
  );
  const pid = useAppSelector((state) => state.auth.pid);
  const filters = useAppSelector((state) => state.conflictCase.filters);
  const isLoading = useAppSelector((state) => state.conflictCase.isLoading);
  const currPageIndexInit = useAppSelector(
    (state) => state.caseFilter.caseListCurrentPageIndex
  );
  const currPageSizeInit = useAppSelector(
    (state) => state.caseFilter.caseListRowsPerPage
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAdminUser = useIsAdminAuthenticated();

  const [currentDisplayedCases, setCurrentlyDisplayedCases] =
    useState(allCasesForUser);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [editingFilter, setEditingFilter] = useState<Filter | null>(null);
  const filterButtonRef = useRef(null!);

  const columns = React.useMemo(() => getHeaders(pid), []);
  const data = React.useMemo(() => {
    return currentDisplayedCases;
  }, [currentDisplayedCases]);

  useEffect(() => {
    if (!isAdminUser()) {
      dispatch(fetchCasesMiddleware());
    }
  }, []);

  useEffect(() => {
    setCurrentlyDisplayedCases(allCasesForUser);
  }, [allCasesForUser]);

  useEffect(() => {
    applyFilters();
  }, [filters, allCasesForUser]);

  const {
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["caseId"],
        pageIndex: currPageIndexInit,
        pageSize: currPageSizeInit,
      },
    },
    useSortBy<SingularCase>,
    usePagination<SingularCase>
  );

  const applyFilters = () => {
    let tempCases = allCasesForUser;
    filters.forEach((filter) => {
      let filterFunction = FILTER_FUNCTIONS[filter.operator];
      tempCases = filterFunction(tempCases, filter);
    });
    dispatch(caseFilterSlice.actions.save(tempCases));
    setCurrentlyDisplayedCases(tempCases);

    if ((currPageIndexInit + 1) * currPageSizeInit > tempCases.length) {
      dispatch(
        caseFilterActions.setCaseListCurrentPageIndex(
          Math.floor(tempCases.length / currPageSizeInit)
        )
      );
    }
  };

  const navigateToCase = (caseId: string) => {
    if (isAdminUser()) {
      navigate(`/admin/case/${caseId}`);
    } else {
      navigate(`/case/${caseId}`);
    }
  };

  const handleFilterDropdownClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const onClickEdit = (filter: Filter) => {
    setEditingFilter(filter);
    setAnchorEl(filterButtonRef.current);
  };

  const handleFilterDropdownClose = () => {
    setAnchorEl(null);
    setEditingFilter(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="cases-page-container">
        <Paper elevation={4}>
          <TableContainer>
            <Toolbar
              className="case-list-toolbar-container"
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3" className="case-list-toolbar-text">
                Your Cases
              </Typography>
              <Stack
                flexDirection="row"
                className="case-list-filters-container"
              >
                {filters.length > 0 && (
                  <FilterListItems onClickEdit={onClickEdit} />
                )}
                <IconButton
                  className="caselist-filter-icon"
                  onClick={handleFilterDropdownClick}
                  ref={filterButtonRef}
                >
                  <FaFilter id={id} />
                </IconButton>
                <Popover
                  sx={{
                    padding: 4,
                  }}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleFilterDropdownClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box
                    sx={{
                      width: "50vw",
                      overflowY: "visible",
                    }}
                  >
                    <FilterModal
                      closeFilterDropdown={handleFilterDropdownClose}
                      filter={editingFilter}
                    />
                  </Box>
                </Popover>
              </Stack>
            </Toolbar>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                {headerGroups.map((headerGroup: any) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: ColumnInstance) => (
                      <TableCell
                        align="left"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <Typography variant="body1" color="white">
                          {column.render("Header")}
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " ↓"
                              : " ↑"
                            : ""}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  page.map((row: any) => {
                    prepareRow(row);
                    return (
                      <TableRow
                        className="data-row selectable"
                        key={row.id}
                        onClick={() => navigateToCase(row.values.caseId)}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell: Cell) => {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              key={`${cell.column.id}/${cell.row.id}`}
                              sx={{
                                p: 4,
                                pl: 2,
                              }}
                              align="left"
                            >
                              <Typography>
                                {cell.column.Header !== "Opened On" && //Needed so that the sorting works as expected. If not, it'll do it alphabetically w/ the Date!
                                cell.column.Header !== "Expiration Date"
                                  ? cell.render("Cell")
                                  : getTimeStringShort(cell.value)}
                              </Typography>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    {Array(5)
                      .fill(null)
                      .map(() => {
                        return (
                          <>
                            <TableRow className="data-row">
                              {Array(7)
                                .fill(null)
                                .map(() => {
                                  return (
                                    <>
                                      <TableCell
                                        sx={{
                                          p: 4,
                                          pl: 2,
                                        }}
                                      >
                                        <Skeleton variant="rectangular" />
                                      </TableCell>
                                    </>
                                  );
                                })}
                            </TableRow>
                          </>
                        );
                      })}
                  </>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <Tooltip
                    title={
                      <Typography>
                        Exports a CSV of all cases after any filters are
                        applied. CSV Export is organized by TN in each case.
                      </Typography>
                    }
                  >
                    <IconButton
                      onClick={() => downloadCases(currentDisplayedCases, pid)}
                    >
                      <DownloadIcon
                        fontSize="large"
                        sx={{ margin: "0.5rem" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={currentDisplayedCases.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={(event, newPage) => {
                      gotoPage(newPage);
                      dispatch(
                        caseFilterActions.setCaseListCurrentPageIndex(newPage)
                      );
                    }}
                    onRowsPerPageChange={(event) => {
                      let newRowsPerPage = parseInt(event.target.value, 10);
                      setPageSize(newRowsPerPage);
                      dispatch(
                        caseFilterActions.setCaseListRowsPerPage(newRowsPerPage)
                      );
                      gotoPage(0);
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};

const downloadCases = (caseList: SingularCase[], currentPid: string) => {
  const csvHeaders = [
    "TN",
    "Result",
    "Case ID",
    "Requesting NNID",
    "Requesting NNID Type",
    "Opened Date",
    "Expiration Date",
    "Opened By",
    "Opened Against",
    "Case Status",
    "Opened By User",
  ];

  const formatCase = (singularCase: SingularCase): string[][] => {
    let returned = [] as string[][];

    singularCase.tns.forEach((tn) => {
      let list: string[] = [];

      list.push(tn.tn);
      list.push(tn.result || "NOT_RESPONDED");
      list.push(singularCase.caseId.substring(0, 6));
      list.push(singularCase.nnidToProvisionTo);
      list.push(singularCase.nnidType);
      list.push(getTimeString(singularCase.openedTs));
      list.push(getTimeString(singularCase.expiresTs));
      list.push(singularCase.openedBy);
      list.push(singularCase.openedAgainst);
      list.push(singularCase.status);
      list.push(
        singularCase.openedBy === currentPid ? singularCase.openedByUser : "N/A"
      );

      returned.push(list);
    });

    return returned;
  };

  let data = [] as string[][];

  caseList.forEach((singularCase) => {
    data.push(...formatCase(singularCase));
  });

  data = data.sort((row1, row2) => row1[0].localeCompare(row2[0]));

  data.unshift(csvHeaders);

  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent += data.map((row) => row.join(",")).join("\n");

  let encodedUri = encodeURI(csvContent);

  const currTimeString = formatDate(new Date());
  const linkName = `nnsr-portal-export-${currTimeString}.csv`;

  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", linkName);
  document.body.appendChild(link); // Required for FF
  link.click();
};

function formatDate(date: Date) {
  let month =
    date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  let date1 = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
  let hours = date.getHours() < 9 ? "0" + date.getHours() : date.getHours();
  let year = date.getFullYear();

  return year + "" + month + "" + date1 + "" + hours;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

export default CasesList;
