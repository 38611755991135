import AddDocumentationInputDTO from "../model/input/addDocumentationInputDTO";
import RemoveDocumentationInputDTO from "../model/input/removeDocumentationInputDTO";
import Documentation from "../model/store/documentation";
import {
  addDocumentationAxios,
  editDocumentationAxios,
  removeDocumentationAxios,
} from "../service/documentationService";
import { documentationActions } from "../store/documentationSlice";
import { MiddlewareType } from "./middlewareType";
import { handleRequestError } from "./middlewareUtils";

export function addDocumentationMiddleware(
  displayName: string,
  description: string,
  fileName: string,
  fileData: number[]
): MiddlewareType {
  return (dispatch, getState) => {
    dispatch(documentationActions.setIsLoading(true));

    addDocumentationAxios({
      displayName: displayName,
      description: description,
      fileName: fileName,
      fileData: fileData,
    } as AddDocumentationInputDTO)
      .then((res) => {
        dispatch(
          documentationActions.addNewDocumentation({
            fileId: res.data.fileId,
            description: description,
            displayName: displayName,
            lastUpdated: res.data.timestamp,
          } as Documentation)
        );
        dispatch(documentationActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(documentationActions.setIsLoading(false));
      });
  };
}

interface EditDocumentationMiddlewareInput {
  fileId: string;
  displayName?: string;
  description?: string;
}

export function editDocumentationMiddleware(
  input: EditDocumentationMiddlewareInput
): MiddlewareType {
  return (dispatch, getState) => {
    dispatch(documentationActions.setIsLoading(true));
    editDocumentationAxios(input)
      .then((res) => {
        dispatch(
          documentationActions.editDocumentation({
            ...input,
            timeStamp: new Date().getTime(),
          })
        );
        dispatch(documentationActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(documentationActions.setIsLoading(false));
      });
  };
}

export function removeDocumentationMiddleware(fileId: string): MiddlewareType {
  return (dispatch, getState) => {
    dispatch(documentationActions.setIsLoading(true));
    removeDocumentationAxios({
      fileId: fileId,
    } as RemoveDocumentationInputDTO)
      .then((res) => {
        if (res.data.success) {
          dispatch(documentationActions.removeDocumentation(fileId));
        } else {
          //TODO: implement some logic on what to do if it fails somehow...
        }
        dispatch(documentationActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(documentationActions.setIsLoading(false));
      });
  };
}
