export default interface EmailSetting {
  emailAddress: string;
  receiveOneDayReminderEmails: boolean;
  receiveOneHourReminderEmails: boolean;
  receiveCommentEmails: boolean;
  receiveCaseOpenedEmails: boolean;
  receiveCaseClosedEmails: boolean;
  receiveTnsAcceptedEmails: boolean;
  receiveTnsRejectedEmails: boolean;
  receiveCaseExpiredInitiatingPartyEmails: boolean;
  receiveCaseExpiredReceivingPartyEmails: boolean;
  hiddenFromProvisioner: boolean;
}

export const EMAIL_SETTINGS_DEFAULTS: EmailSetting = {
  emailAddress: "",
  receiveOneDayReminderEmails: true,
  receiveOneHourReminderEmails: true,
  receiveCommentEmails: true,
  receiveCaseOpenedEmails: true,
  receiveCaseClosedEmails: true,
  receiveTnsAcceptedEmails: true,
  receiveTnsRejectedEmails: true,
  receiveCaseExpiredInitiatingPartyEmails: true,
  receiveCaseExpiredReceivingPartyEmails: true,
  hiddenFromProvisioner: false,
};
