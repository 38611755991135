import { createSlice } from "@reduxjs/toolkit";
import EmailSetting from "../model/store/emailSetting";
import * as EmailSettingReducer from "../reducer/emailSettingReducer";

export interface EmailSettingState {
  emailSettings: EmailSetting[];
}

const initEmailSettingState = (): EmailSettingState => {
  return {
    emailSettings: [],
  };
};

const emailSettingSlice = createSlice({
  name: "emailSetting",
  initialState: initEmailSettingState(),
  reducers: { ...EmailSettingReducer },
});

const emailSettingActions = emailSettingSlice.actions;

export { emailSettingSlice, emailSettingActions };
