import { useState, useRef } from "react";
import { useModalContext } from "../../context/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import { attemptProvisionNewCaseTnsMiddleware } from "../../middleware/conflictCaseMiddleware";
import DropDown from "../../components/forms/DropDown";
import { fileToText } from "../../utils/fileUtils";
import { isValidTn } from "../../utils/genericUtils";
import { conflictCaseActions } from "../../store/conflictCasesSlice";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import TextboxInput from "../../components/forms/TextboxInput";
import TextareaInput from "../../components/forms/TextareaInput";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { DisplayableValue } from "../../model/store/displayableValue";
import Nnid from "../../model/store/nnid";
import AutoComplete from "../../components/forms/AutoComplete";
import { useNavigate } from "react-router";

const FORMATTING_HELP_MODAL_CONTENT = (
  <>
    <p>
      <div className="bold">Number must be submitted as</div>
      <ol>
        <li>- 11 digits phone number (Areacode and 10 digits)</li>
        <li>- No spaces, dashes, dots, or pluses. Only digits</li>
        <li>- No duplicates</li>
      </ol>
    </p>
    <div className="bold">Some Examples</div>
    <ol>
      <li>
        <div className="tn">12012000008</div>
      </li>
      <li>
        <div className="tn">12012049579</div>
      </li>
      <li>
        <div className="tn">19899850111</div>
      </li>
    </ol>
    <p className="bold">For CSV Input</p>
    <p>
      If submitting a CSV, we will find any cell in the csv following the above
      description and interpret it as a number you are trying to submit. Cells
      are split by commas
    </p>
  </>
);

/**
 * The component associated with step 1 of Create new Case flow. Will allow user to input
 * NNID, TNs, and associated fields.
 *
 * @returns Component
 */
const NewCaseUpload = () => {
  const NO_ERROR_STATE = {
    nnidError: false,
    nnidErrorMessage: "",
    tnTextBoxError: false,
    tnTextBoxErrorMessage: [] as { message: string; caseId?: string }[],
    csvFileError: false,
    csvFileErrorMessage: "",
  };

  const [uploadFormat, setUploadFormat] = useState("input-text");
  const [seperator, setSeperator] = useState("new-line");
  const customSeperatorRef = useRef<HTMLInputElement>(null);
  const inputTextAreaRef = useRef<HTMLTextAreaElement>(null!);
  const { displayModal } = useModalContext();
  const csvInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const nnidList = useAppSelector((state) => state.conflictCase.nnidList);
  const nnid = useAppSelector((state) => state.conflictCase.newCaseNnid);
  const openCases = useAppSelector((state) => {
    return state.conflictCase.caseList.filter(
      (currentCase) => currentCase.status !== "CLOSED"
    );
  });
  const setNnid = (nnid: Nnid) =>
    dispatch(conflictCaseActions.setNewCaseNnid(nnid));
  const navigate = useNavigate();

  const [inputError, setInputError] = useState(NO_ERROR_STATE);

  const showHelpModal = () => {
    displayModal(
      FORMATTING_HELP_MODAL_CONTENT,
      "Formatting Tips",
      { width: "50%" },
      true
    );
  };

  const submit = async () => {
    let tns: string[] | undefined;
    let error = false;

    if (nnid === null || nnid.value === "") {
      setInputError((inputError) => {
        return {
          ...inputError,
          nnidError: true,
          nnidErrorMessage: "You did not select an NNID!",
        };
      });
      error = true;
    }

    tns = parseInputText();

    if (tns === undefined || tns?.length === 0) {
      setInputError((inputError) => {
        return {
          ...inputError,
          tnTextBoxError: true,
          tnTextBoxErrorMessage: [{ message: "You did not enter any TNs!" }],
        };
      });
      error = true;
      // This is the only situation an error state, we WANT to break the following execution
      return;
    }

    let invalidTnExists = false;

    tns.forEach((tn) => {
      if (!isValidTn(tn)) {
        invalidTnExists = true;
      }
    });

    if (invalidTnExists) {
      setInputError({
        ...inputError,
        tnTextBoxError: true,
        tnTextBoxErrorMessage: [
          { message: "Atleast one TN is not formatted correctly" },
        ],
      });
      error = true;
    }

    tns.sort();

    let duplicateExists = false;

    tns.forEach((tn, index, array) => {
      if (index !== array.length - 1 && tn === array[index + 1]) {
        duplicateExists = true;
      }
    });

    if (duplicateExists) {
      setInputError({
        ...inputError,
        tnTextBoxError: true,
        tnTextBoxErrorMessage: [
          {
            message:
              "There are atleast 2 tns that are duplicate of one another",
          },
        ],
      });
      error = true;
    }

    let openTnsCheckErrorMessages: { message: string; caseId?: string }[] = [];
    tns?.forEach((openingTn) => {
      openCases.forEach((singluarCase) => {
        let singularCaseTns = singluarCase.tns.map((tn) => tn.tn);
        if (singularCaseTns.includes(openingTn)) {
          openTnsCheckErrorMessages.push({
            message: `TN \"${openingTn}\" already has an open case associated with it!`,
            caseId: singluarCase.caseId,
          });
        }
      });
    });

    if (openTnsCheckErrorMessages.length > 0) {
      setInputError((inputError) => {
        return {
          ...inputError,
          tnTextBoxError: true,
          tnTextBoxErrorMessage: openTnsCheckErrorMessages,
        };
      });
      error = true;
    }

    if (error) return;

    setInputError((inputError) => {
      return {
        ...inputError,
        tnTextBoxError: false,
        tnTextBoxErrorMessage: [],
      };
    });

    if (tns !== undefined) {
      dispatch(attemptProvisionNewCaseTnsMiddleware(tns));
    }
  };

  const parseCSVFile = async () => {
    try {
      let file;
      if (csvInputRef.current) {
        file = Array.prototype.slice.call(csvInputRef.current.files)[0];
      }
      const fileText = await fileToText(file);

      let tns: string[] = [];
      fileText.split("\n").forEach((line) => {
        line.split(",").forEach((value) => {
          value = value.trim();
          if (isValidTn(value)) {
            tns.push(value);
          }
        });
      });

      setInputError(NO_ERROR_STATE);

      //return tns;

      inputTextAreaRef.current.value = tns.join("\n");
    } catch (e) {
      setInputError({
        ...inputError,
        csvFileError: true,
        csvFileErrorMessage:
          "There was something wrong with your CSV file. Please check the file and upload again.",
      });

      return;
    }
  };

  const parseInputText = (): string[] => {
    let seperatorValue: string = "\n";
    switch (seperator) {
      case "new-line":
        seperatorValue = "\n";
        break;
      case "custom":
        if (customSeperatorRef.current) {
          seperatorValue = customSeperatorRef.current.value.trim();
        }
        break;
    }

    if (uploadFormat === "upload-csv") {
      seperatorValue = "\n";
    }

    let tns = inputTextAreaRef.current.value.split(seperatorValue);
    tns = tns.map((tn) => tn.trim());

    tns = tns.filter((tn) => tn !== "");

    return tns;
  };

  return (
    <Paper sx={{ p: 3 }} elevation={4}>
      <Grid container>
        <Grid className="new-case-upload-grid-item" item xs={4}>
          <span className="bold">NNID to Provision To: </span>
        </Grid>
        <Grid className="new-case-upload-grid-item" item xs={8}>
          {inputError.nnidError && (
            <div className="input-error">{inputError.nnidErrorMessage}</div>
          )}
          <AutoComplete
            placeholder="nnid"
            list={nnidList}
            error={inputError.nnidError}
            onClick={(nnid) => {
              setNnid(nnid);
              setInputError({
                ...inputError,
                nnidError: false,
                nnidErrorMessage: "",
              });
            }}
            initialValue={nnid?.display ? nnid.display : ""}
          />
        </Grid>
        <Grid className="new-case-upload-grid-item" item xs={4}>
          <span className="bold">Upload Option:</span>
        </Grid>
        <Grid className="new-case-upload-grid-item" item xs={8}>
          <RadioButtonGroup
            list={[
              { display: "Input Text", value: "input-text" },
              { display: "Upload CSV", value: "upload-csv" },
            ]}
            onClick={(value: string) => setUploadFormat(value)}
            initialValue={uploadFormat}
          ></RadioButtonGroup>
        </Grid>
        {uploadFormat === "input-text" ? (
          <>
            <Grid className="new-case-upload-grid-item" item xs={4}>
              <label>
                <span className="bold">Separator: </span>
              </label>
            </Grid>
            <Grid className="new-case-upload-grid-item" item xs={8}>
              <RadioButtonGroup
                list={[
                  { display: "New Line", value: "new-line" },
                  {
                    display: "Custom",
                    value: "custom",
                    children: <TextboxInput inputRef={customSeperatorRef} />,
                  },
                ]}
                initialValue={seperator}
                onClick={(value: string) => setSeperator(value)}
              ></RadioButtonGroup>
            </Grid>

            <Grid className="new-case-upload-grid-item" item>
              <div id="text-area-title">
                <span className="bold">Input Numbers: </span>
              </div>
              {inputError.tnTextBoxError && (
                <Box className="new-case-upload-tn-error-box">
                  {inputError.tnTextBoxErrorMessage.map((tnErrorMessage) => (
                    <Box className="input-error new-case-upload-tn-error">
                      {tnErrorMessage.message}{" "}
                      {tnErrorMessage.caseId && (
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() =>
                            navigate(`/case/${tnErrorMessage.caseId}`)
                          }
                        >
                          Case ID: {tnErrorMessage.caseId.substring(0, 6)}
                        </Button>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid className="new-case-upload-grid-item" item xs={4}>
              <label>
                <span className="bold">File Upload: </span>
              </label>
            </Grid>
            <Grid className="new-case-upload-grid-item" item xs={8}>
              <input
                id="csv-upload-cell"
                ref={csvInputRef}
                onChange={parseCSVFile}
                type="file"
              />
            </Grid>

            <Grid className="new-case-upload-grid-item" item xs={12}>
              <div id="text-area-title">
                <span className="bold">Uploaded Numbers: </span>
              </div>
              <div className="input-error" style={{ marginLeft: "1rem" }}>
                {inputError.tnTextBoxErrorMessage.map((tnErrorMessage) => (
                  <div style={{ marginBottom: "0.5rem" }}>
                    {tnErrorMessage.message}{" "}
                    {tnErrorMessage.caseId && (
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() =>
                          navigate(`/case/${tnErrorMessage.caseId}`)
                        }
                      >
                        Case ID: {tnErrorMessage.caseId.substring(0, 6)}
                      </Button>
                    )}
                  </div>
                ))}
              </div>
            </Grid>
          </>
        )}
        {/* <TextField multiline ></TextField> */}
        <Grid className="new-case-upload-grid-item" item xs={12}>
          <TextareaInput
            sx={{ width: "100%", p: 0 }}
            error={inputError.tnTextBoxError}
            disabled={uploadFormat === "upload-csv"}
            inputRef={inputTextAreaRef}
          />
        </Grid>{" "}
        <Grid className="new-case-upload-grid-item" item xs={6}>
          <Button variant="outlined" onClick={showHelpModal}>
            Help Formatting
          </Button>
        </Grid>
        <Grid
          className="new-case-upload-grid-item"
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="contained" onClick={submit}>
            Submit
          </Button>
        </Grid>
      </Grid>
      <div className="bottom-controls"></div>
    </Paper>
  );
};

export default NewCaseUpload;
