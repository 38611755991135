import AddDocumentationInputDTO from "../model/input/addDocumentationInputDTO";
import EditDocumentationInputDTO from "../model/input/editDocumentationInputDTO";
import RemoveDocumentationInputDTO from "../model/input/removeDocumentationInputDTO";
import AddDocumentationOutputDTO from "../model/output/addDocumentationOutputDTO";
import AttemptProvisionOutputDTO from "../model/output/attemptProvisionOutputDTO";
import SuccessFailureOutputDTO from "../model/output/successFailureOutputDTO";
import axios, { getAuthToken } from "./axios";
import { AxiosPromise } from "./axiosPromise";
import {
  ADD_DOCUMENTATION_ENDPOINT_ADMIN,
  GET_DOCUMENTATION_ENDPOINT,
  BASE_URL,
  REMOVE_DOCUMENTATION_ENDPOINT_ADMIN,
  EDIT_DOCUMENTATION_ENDPOINT_ADMIN,
} from "./serviceConfiguration";

export function downloadDocumentationAxios(documentId: string) {
  return axios.get(BASE_URL + GET_DOCUMENTATION_ENDPOINT + `/${documentId}`, {
    responseType: "blob",
    ...getAuthToken(),
  });
}

export function addDocumentationAxios(
  input: AddDocumentationInputDTO
): AxiosPromise<AddDocumentationOutputDTO> {
  return axios.post(
    BASE_URL + ADD_DOCUMENTATION_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function editDocumentationAxios(
  input: EditDocumentationInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + EDIT_DOCUMENTATION_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function removeDocumentationAxios(
  input: RemoveDocumentationInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + REMOVE_DOCUMENTATION_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}
