import { createSlice } from "@reduxjs/toolkit";
import * as ErrorReducers from "../reducer/errorReducers";

export interface ErrorState {
  error: {
    isError: boolean;
    errorMessage: String;
    errorTs: string | null;
    errorDisplayTime: number;
  };
}

function initialErrorState(): ErrorState {
  return {
    error: {
      isError: false,
      errorMessage: "",
      errorTs: null,
      errorDisplayTime: 10000,
    },
  };
}

const errorSlice = createSlice({
  name: "error",
  initialState: initialErrorState(),
  reducers: { ...ErrorReducers },
});

const errorActions = errorSlice.actions;

export { errorActions, errorSlice };
