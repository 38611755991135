import { createSlice } from "@reduxjs/toolkit";
import Documentation from "../model/store/documentation";
import * as DocumentationReducers from "../reducer/documentationReducers";

export interface DocumentationState {
  documentationList: Documentation[];
  isLoading: boolean;
}

function initialDocumentationState(): DocumentationState {
  return {
    documentationList: [],
    isLoading: false,
  };
}

const documentationSlice = createSlice({
  name: "documentation",
  initialState: initialDocumentationState(),
  reducers: { ...DocumentationReducers },
});

const documentationActions = documentationSlice.actions;

export { documentationActions, documentationSlice };
