import { useSelector, useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/hooks";
import { ErrorProvisionerGroup } from "./ErrorProvisionerGroup";

/**
 * @returns Component
 */

const ERROR_TEXT =
  "TN Provisioning has failed, however you may open a case on this portal to provision these numbers. Would you like to open a case against the owning provisioner?";

const ErrorProvisioningPanel = () => {
  const data = useAppSelector(
    (state) => state.conflictCase.newCaseResultsData.error
  );
  const provisioners = Object.keys(data);

  return (
    <>
      <div className="result-group">
        <div className="result-group-text">{ERROR_TEXT}</div>
        <div className="result-group-results">
          <div className="result-group-header results-prov-group">
            <div>Provisioner</div>

            <div>TNs</div>

            <div>Open Case</div>
          </div>
          {provisioners.map((provisioner) => {
            return (
              <ErrorProvisionerGroup
                key={provisioner}
                provisioner={provisioner}
                provisionerTns={data[provisioner].tns}
                opened={data[provisioner].opened}
                caseId={data[provisioner].caseId}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export { ErrorProvisioningPanel };
