import { Tooltip, Typography, IconButton } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";

type Placement =
  | "right"
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "top-end"
  | "top-start"
  | "top"
  | undefined;

interface InfoToolTipInterface {
  placement?: Placement;
  infoCircleSize?: string;
  children: React.ReactNode;
}

const InfoToolTip = ({
  placement = "right",
  infoCircleSize = "2rem",
  children,
}: InfoToolTipInterface) => {
  return (
    <Tooltip placement={placement} title={<>{children}</>}>
      <IconButton>
        <FaInfoCircle size={infoCircleSize} />
      </IconButton>
    </Tooltip>
  );
};

export default InfoToolTip;
