import { PayloadAction } from "@reduxjs/toolkit";
import { AdminState, PortalConfigurations } from "../store/adminSlice";
import { DisplayableValue } from "../model/store/displayableValue";
import Nnid from "../model/store/nnid";
import { NnidType } from "../model/store/nnidType";
import CurrentPidUser from "../model/store/currentPidUser";
import NnidOwner from "../model/store/nnidOwner";
import EmailSetting from "../model/store/emailSetting";

export function setAllProvisionerList(
  state: AdminState,
  action: PayloadAction<DisplayableValue<string>[]>
) {
  state.allProvisionerList = action.payload;
}
export function setEnabledProvisionerList(
  state: AdminState,
  action: PayloadAction<DisplayableValue<string>[]>
) {
  state.enabledProvisionerList = action.payload;
}

export function setCurrentPid(
  state: AdminState,
  action: PayloadAction<DisplayableValue<string>>
) {
  state.currentPid = action.payload;
  localStorage.setItem("currentPid", JSON.stringify(action.payload));
}

export function removeCurrentPidEmail(
  state: AdminState,
  action: PayloadAction<EmailSetting>
) {
  let index = state.currentPidEmails.findIndex(
    (currEmail) => currEmail.emailAddress === action.payload.emailAddress
  );

  if (index > -1 && index < state.currentPidEmails.length) {
    state.currentPidEmails.splice(index, 1);
    state.currentPidEmails = [...state.currentPidEmails];
  }
}

export function addCurrentPidEmail(
  state: AdminState,
  action: PayloadAction<EmailSetting>
) {
  state.currentPidEmails = [...state.currentPidEmails, action.payload];
}

export function editCurrentPidEmail(
  state: AdminState,
  action: PayloadAction<{ oldEmail: EmailSetting; newEmail: EmailSetting }>
) {
  let index = state.currentPidEmails.findIndex(
    (currEmail) =>
      currEmail.emailAddress === action.payload.oldEmail.emailAddress
  );

  if (index > -1 && index < state.currentPidEmails.length) {
    state.currentPidEmails[index] = action.payload.newEmail;
  }
}

export function editCurrentPidUsersDisplayname(
  state: AdminState,
  action: PayloadAction<{ email: string; newDisplayName: string }>
) {
  let index = state.currentPidUsers.findIndex((user) => {
    return user.email === action.payload.email;
  });

  if (index > -1 && index < state.currentPidUsers.length) {
    state.currentPidUsers[index].displayName = action.payload.newDisplayName;
  }
}

export function addCurrentPidNnid(
  state: AdminState,
  action: PayloadAction<Nnid>
) {
  state.currentPidNnids = [...state.currentPidNnids, action.payload];
}

interface EditCurrentPidNnidInterface {
  oldNnidValue: string;
  newNnidDisplay: string;
  newNnidType: NnidType;
}

export function editCurrentPidNnid(
  state: AdminState,
  action: PayloadAction<EditCurrentPidNnidInterface>
) {
  let index = state.currentPidNnids.findIndex((nnid) => {
    return nnid.value === action.payload.oldNnidValue;
  });

  if (index > -1 && index < state.currentPidNnids.length) {
    state.currentPidNnids[index].display = action.payload.newNnidDisplay;
    state.currentPidNnids[index].type = action.payload.newNnidType;
  }
}

export function removeCurrentPidNnid(
  state: AdminState,
  action: PayloadAction<string>
) {
  let index = state.currentPidNnids.findIndex((nnid) => {
    return nnid.value === action.payload;
  });

  if (index > -1 && index < state.currentPidNnids.length) {
    state.currentPidNnids.splice(index, 1);
    state.currentPidNnids = [...state.currentPidNnids];
  }
}

export function enableNewProvisioner(
  state: AdminState,
  action: PayloadAction<DisplayableValue<string>>
) {
  state.enabledProvisionerList = [
    ...state.enabledProvisionerList,
    action.payload,
  ];
}

export function disableProvisioner(
  state: AdminState,
  action: PayloadAction<string>
) {
  state.enabledProvisionerList = state.enabledProvisionerList.filter(
    (prov) => prov.value !== action.payload
  );
}

export function updatePortalConfigurations(
  state: AdminState,
  action: PayloadAction<PortalConfigurations>
) {
  state.portalConfigurations = action.payload;
}

export function setIsLoading(
  state: AdminState,
  action: PayloadAction<boolean>
) {
  state.isLoading = action.payload;
}

export function setProvisionerUserList(
  state: AdminState,
  action: PayloadAction<CurrentPidUser[]>
) {
  state.currentPidUsers = action.payload;
}

export function setProvisionerNnidList(
  state: AdminState,
  action: PayloadAction<Nnid[]>
) {
  state.currentPidNnids = action.payload;
}

export function setProvisionerEmailDistributionList(
  state: AdminState,
  action: PayloadAction<EmailSetting[]>
) {
  state.currentPidEmails = action.payload;
}

export function setNnidOwnerList(
  state: AdminState,
  action: PayloadAction<NnidOwner[]>
) {
  state.nnidOwnerList = action.payload;
}

export function removeNnidOwner(
  state: AdminState,
  action: PayloadAction<NnidOwner>
) {
  let index = state.nnidOwnerList.findIndex(
    (nnidOwner) => nnidOwner.nnid === action.payload.nnid
  );

  if (index > -1) {
    state.nnidOwnerList.splice(index, 1);
  }
}
export function editNnidOwner(
  state: AdminState,
  action: PayloadAction<{
    nnid: string;
    updatedNnidOwner: NnidOwner;
  }>
) {
  let index = state.nnidOwnerList.findIndex(
    (nnidOwner) => nnidOwner.nnid === action.payload.nnid
  );

  if (index > -1) {
    state.nnidOwnerList[index] = action.payload.updatedNnidOwner;
  }
}

export function addNnidOwner(
  state: AdminState,
  action: PayloadAction<NnidOwner>
) {
  state.nnidOwnerList = [...state.nnidOwnerList, action.payload];
}
