import UpdateNotificationReadUnreadInputDTO from "../model/input/updateNotificationReadUnreadInputDTO";
import SuccessFailureOutputDTO from "../model/output/successFailureOutputDTO";
import Notification from "../model/store/notification";
import axios, { getAuthToken } from "./axios";
import { AxiosPromise } from "./axiosPromise";
import GetNotificationsOutputDTO from "../model/output/getNotificationsOutputDTO";

import {
  BASE_URL,
  GET_NOTIFICATIONS_ENDPOINT,
  POST_READ_ALL_NOTIFICATIONS_ENDPOINT,
  POST_READ_UNREAD_NOTIFICATION_ENDPOINT,
} from "./serviceConfiguration";

function fetchNotificationsAxios(): AxiosPromise<GetNotificationsOutputDTO> {
  return axios.get(BASE_URL + GET_NOTIFICATIONS_ENDPOINT, getAuthToken());
}

function postReadUnreadNotificationAxios(
  input: UpdateNotificationReadUnreadInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + POST_READ_UNREAD_NOTIFICATION_ENDPOINT,
    input,
    getAuthToken()
  );
}

function postReadAllNotificationsAxios(): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + POST_READ_ALL_NOTIFICATIONS_ENDPOINT,
    {},
    getAuthToken()
  );
}

export {
  fetchNotificationsAxios,
  postReadUnreadNotificationAxios,
  postReadAllNotificationsAxios,
};
