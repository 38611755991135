import { useSelector } from "react-redux";
import {
  UnableToProvision,
  UnableToProvisionKey,
} from "../../../model/store/newCaseResults";
import { useAppSelector } from "../../../store/hooks";

const UNABLE_TEXT =
  "Number(s) CANNOT be provisioned inside of the nnSR for one reason or another.";

const UNABLE_PROVISIONED_TEXT: { [asdf in keyof UnableToProvision]: string } = {
  NP_LOA: "LOA is required from Number Provider",
  TOLL_FREE: "Your NNID has never been approved to provision Toll Free numbers",
  MOBILE:
    "Your NNID has never been approved to provision mobile numbers to the nnSR",
  OTHER: "You are unable to provision those numbers in the nnSR",
  PROVISIONER_NOT_PORTAL_ENABLED:
    "The following TNS belong to PIDs that are not currently portal enabled",
  PROTECTED_NNID:
    "This TN belongs to a Number Provider NNID that cannot be handled on this portal. Please reach out to the netnumber team to address this issue",
  PROVISIONER_EXCEEDED_HOURLY_LIMIT:
    "Sorry, you've exceeded the hourly limit allowed on the nnSR",
};

/**
 * The panel component for Unable to Provision panel
 *
 * @returns {Component}
 */
const UnableProvisionPanel = () => {
  const data = useAppSelector(
    (state) => state.conflictCase.newCaseResultsData.unable
  );

  return (
    <div className="result-group">
      <div className="result-group-text">{UNABLE_TEXT}</div>
      <div className="result-group-results">
        <div className="unable-group-header unable-prov-group">
          <div className="first-column">Reason</div>
          <div className="second-column">TNs</div>
        </div>
        {(Object.keys(data) as Array<keyof UnableToProvision>).map((reason) => {
          return (
            <div className="unable-prov-group" key={reason}>
              <div className="unable-prov-reason first-column">
                {UNABLE_PROVISIONED_TEXT[reason]}
              </div>
              <div className="tns-list second-column">
                {data[reason].map((tn: string) => {
                  return (
                    <li className="tn" key={tn}>
                      {tn}
                    </li>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { UnableProvisionPanel };
