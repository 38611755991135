import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { submitNewCaseMiddleware } from "../../../middleware/conflictCaseMiddleware";
import { fileToByteArray } from "../../../utils/fileUtils";
import { useModalContext } from "../../../context/ModalContext";
import { conflictCaseActions } from "../../../store/conflictCasesSlice";
import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { NnidType } from "../../../model/store/nnidType";
import LoaDTO from "../../../model/input/loaDTO";
import { truncateDecimals } from "../../../utils/fileUtils";
import { mapNnidType } from "../../../utils/nnidUtils";

interface LoaFile extends LoaDTO {
  fileSizeString: string;
  fileSize: number;
}

const MAX_FILE_UPLOAD_SIZE_ALLOWED = 2.5 * 1048576; // Bytes in 2.5mb

/**
 * This component is the component that will be represented in the modal that is opened when the
 * user selects "OpenCase" in ErrorProvisionerGroup component.
 *
 * @param provisioner String, ProvisionerId of the case to be opened
 * @param tns list of Strings. The list of tns associated with the new case
 * @returns {Component}
 */
interface OpenCaseModalContent {
  provisioner: string;
  tns: string[];
}

const PROHIBBITED_FILE_NAME_CHARACTERS = [
  "[",
  "]",
  "#",
  "$",
  "&",
  "?",
  ";",
  ":",
];

const OpenCaseModalContent = ({ provisioner, tns }: OpenCaseModalContent) => {
  const { closeModal } = useModalContext();
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement>(null!);
  const [loaList, setLoaList] = useState<LoaFile[]>([]);
  const [fileUploadError, setFileUploadError] = useState({
    value: false,
    message: "",
  });
  const nnidType = useAppSelector(
    (state) => state.conflictCase.newCaseNnid?.type
  );

  const submitCaseValidations = () => {
    if (nnidType === "SUBSCRIBER_LOA" && loaList.length === 0) {
      setFileUploadError({ value: true, message: "You did upload any loas!" });
      return;
    }

    dispatch(submitNewCaseMiddleware(provisioner, tns, loaList));
    closeModal();
  };

  const handleFileAdd = async () => {
    const file = Array.prototype.slice.call(inputRef.current.files)[0];
    if (!file) {
      setFileUploadError({ value: true, message: "You did not add a file" });
      return;
    }

    let includesIllegalCharacter = false;

    for (let i = 0; i < file.name.length && !includesIllegalCharacter; i++) {
      if (PROHIBBITED_FILE_NAME_CHARACTERS.includes(file.name.charAt(i))) {
        includesIllegalCharacter = true;
      }
    }

    if (includesIllegalCharacter) {
      setFileUploadError({
        value: true,
        message: `This filename includes a prohibited character: ${PROHIBBITED_FILE_NAME_CHARACTERS.join(
          "  "
        )}`,
      });
      return;
    }

    if (loaList.findIndex((f) => f.fileName === file.name) !== -1) {
      setFileUploadError({
        value: true,
        message: "You've already added this file!",
      });
      return;
    }

    let fileData = await fileToByteArray(file);

    let fileSizeString;

    if (file.size > 1048576) {
      fileSizeString = `${truncateDecimals(file.size / 1048576, 2)} MB`;
    } else if (file.size > 1024) {
      fileSizeString = `${truncateDecimals(file.size / 1024, 2)} KB`;
    } else {
      fileSizeString = `${truncateDecimals(file.size, 2)} B`;
    }

    let currLoaListFileSize = loaList.reduce((prev: number, next) => {
      return prev + next.fileSize;
    }, 0);

    if (currLoaListFileSize + file.size > MAX_FILE_UPLOAD_SIZE_ALLOWED) {
      setFileUploadError({
        value: true,
        message: `The file${
          loaList.length > 0 ? "s" : ""
        } you have added exceed the maximum file size per case! (2.5 MB)`,
      });
      return;
    }

    const mappedLoa = {
      fileName: file.name,
      fileData: fileData,
      fileSizeString: fileSizeString,
      fileSize: file.size,
    };

    setLoaList([...loaList, mappedLoa]);
    setFileUploadError({
      value: false,
      message: "",
    });
  };

  const UploadLoaSection = () => (
    <>
      <p>
        Please submit any supporting documents as an LOA from your customer to
        prove you have the right to provision these numbers
      </p>
      <p className="italic bold">
        Note: All LOAs associated with a case cannot be larger than 2.5 MB in
        combined size
      </p>
      {fileUploadError.value ? (
        <div className="file-upload-error">
          <p className="">{fileUploadError.message}</p>
        </div>
      ) : (
        ""
      )}
      <div className="submit-file-section">
        <input type="file" ref={inputRef} onChange={handleFileAdd} />
      </div>
      {loaList.length > 0 ? (
        <div className="file-upload-list">
          {loaList.map((fileObj) => {
            return (
              <div key={fileObj.fileName}>
                {fileObj.fileName} / {fileObj.fileSizeString}
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </>
  );

  return (
    <>
      <p>
        You have selected to create a case against {provisioner} for{" "}
        {tns.length} different TN{tns.length > 1 ? "s" : ""}.
      </p>
      {nnidType === "SUBSCRIBER_LOA" ? (
        <UploadLoaSection />
      ) : (
        <p>
          Because this is a {mapNnidType(nnidType!)} NNID, you do not need to
          submit an LOA. Please hit "Create Case" to create the case
        </p>
      )}

      <div className="new-case-modal-control-container">
        <Button
          variant="contained"
          className="success"
          onClick={submitCaseValidations}
        >
          Create Case
        </Button>
        <Button variant="outlined" color="warning" onClick={closeModal}>
          Close Modal
        </Button>
      </div>
    </>
  );
};

export { OpenCaseModalContent };
