import { PayloadAction } from "@reduxjs/toolkit";
import { QueryTnState } from "../store/queryTnSlice";

interface SetOsrTnQueryInput {
  inputTn: string;
  outputPid: string;
  outputNnid: string;
}

export function setOsrTnQueryState(
  state: QueryTnState,
  action: PayloadAction<SetOsrTnQueryInput>
) {
  let { inputTn, outputPid, outputNnid } = action.payload;
  state.inputTn = inputTn;
  state.outputPid = outputPid;
  state.outputNnid = outputNnid;
}

export function clearOsrTnQueryState(state: QueryTnState) {
  state.inputTn = "";
  state.outputPid = "";
  state.outputNnid = "";
}
