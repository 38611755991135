import { Column } from "react-table";
import SingularCase from "../../model/store/singularCase";
import { capitalize } from "../../utils/genericUtils";
/**
 * The headers that are represented on the caseList page. If "accessor" is a string, it will access the corresponding value
 * of the case object. If the "accessor" is a method, that method will be invoked before displaying that column.
 *
 * @param {String} pid String of the users current pid. Needed to translate data containing you're pid to
 * the value "You" that will be displayed on the screen
 * @returns {List:Object}
 */
const getHeaders = (pid: string): Column<SingularCase>[] => {
  return [
    {
      Header: "Case Id",
      accessor: (row: SingularCase) => {
        return row.caseId.substring(0, 6);
      }, // accessor is the "key" in the data
    },
    {
      Header: "CaseIdFullValue", //This column is HIDDEN! Used to access the caseId value inside of a row.
      accessor: "caseId", // accessor is the "key" in the data
    },
    {
      Header: "Status",
      accessor: (row: SingularCase) => capitalize(row.status),
    },
    {
      Header: "Opened By",
      accessor: (row: SingularCase) =>
        row.openedBy === pid
          ? `${capitalize(row.openedBy)} / ${row.openedByUser}`
          : capitalize(row.openedBy),
    },
    {
      Header: "Opened Against",
      accessor: (row: SingularCase) => capitalize(row.openedAgainst),
    },
    {
      Header: "# TNs",
      accessor: (row: SingularCase) => row.numberTns,
    },
    {
      Header: "Opened On",
      accessor: (row: SingularCase) => row.openedTs,
    },
    {
      Header: "Expiration Date",
      accessor: (row: SingularCase) => row.expiresTs,
    },
  ];
};

export { getHeaders };
