import useIsAdminAuthenticated from "../hooks/useIsAdminAuthenticated";
import useIsUserAuthenticated from "../hooks/useIsUserAuthenticated";
import { authResultIsAdmin } from "../utils/authUtils";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import {
  EXPIRATION_LEAD_TIME_SECONDS,
  loadUserFromStorage,
  signinRedirect,
} from "./userServices";
import { User } from "oidc-client-ts";
import { EXPIRATION_LEAD_TIME_MS } from "./userServices";

/**
 * This class is responsible for any authentication logic for when a user initially logs into the portal.
 * If the user has a valid token and is on the login page, it'll push them to the correct case list page (admin or user).
 * If the user is on a page you need to be authenticated for without a token, it'll redirect them to saleforce to authenticate
 *
 * @returns Component
 */
const AutoSignin = () => {
  const navigate = useNavigate();

  let urlPath = window.location.href.substring(window.location.origin.length);
  let isLoginScreen = urlPath === "/";
  let isCallbackScreen = urlPath.includes("/callback");
  let isAdmin = useIsAdminAuthenticated();
  let isUser = useIsUserAuthenticated();

  const navigateBasedOnCurrentPath = (user: User) => {
    let prependString = "";
    let userIsAdmin = authResultIsAdmin(user);
    prependString = userIsAdmin ? "/admin" : "";

    if (isLoginScreen) {
      navigate(prependString + "/caselist");
      return;
    }
    // If a user is trying to access a path they don't have access to,
    // send them to the corresponding path they should be on!
    if (
      (userIsAdmin && !urlPath.includes("admin")) ||
      (!userIsAdmin && urlPath.includes("admin"))
    ) {
      navigate(prependString + "/caselist");
      return;
    }
  };

  useEffect(() => {
    const signinAsync = async () => {
      let user = await loadUserFromStorage();

      // User doesn't have any OIDC token in storage
      let currTime = Math.floor(new Date().getTime() / 1000);
      if (
        user !== null &&
        user.profile.exp - EXPIRATION_LEAD_TIME_SECONDS > currTime
      ) {
        navigateBasedOnCurrentPath(user); //The token is valid! It'll now be loaded into the store and we're good.
      } else if (!isLoginScreen && !isCallbackScreen) {
        // If the user doesn't have a valid user object in storage, or that user has expried, redirect them to the sign
        // in page
        signinRedirect();
      }
    };

    if (!isCallbackScreen && !isUser() && !isAdmin()) {
      signinAsync();
    }
  }, []);

  return <></>;
};

export default AutoSignin;
