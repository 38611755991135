import { createSlice } from "@reduxjs/toolkit";
import * as QueryTnReducers from "../reducer/queryTnReducers";
import { User } from "oidc-client-ts";

export interface QueryTnState {
  outputPid: string;
  outputNnid: string;
  inputTn: string;
}

const initQueryTnState = (): QueryTnState => {
  return {
    outputPid: "",
    outputNnid: "",
    inputTn: "",
  };
};

const queryTnSlice = createSlice({
  name: "queryTn",
  initialState: initQueryTnState(),
  reducers: { ...QueryTnReducers },
});

const queryTnActions = queryTnSlice.actions;

export { queryTnSlice, queryTnActions };
