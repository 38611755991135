import { Button } from "@mui/material";
import { useModalContext } from "../../context/ModalContext";
import { submitEmailSettingUpdateMiddleware } from "../../middleware/emailSettingMiddleware";
import EmailSetting from "../../model/store/emailSetting";
import { useAppDispatch } from "../../store/hooks";
import fieldToDisplayMappings from "./fieldToDisplayMappings";

interface ChangeEmailSettingsModalProps {
  initState: EmailSetting;
  currentState: EmailSetting;
  disableEditingMode: () => void;
}

const ChangeEmailSettingsModal = ({
  initState,
  currentState,
  disableEditingMode,
}: ChangeEmailSettingsModalProps) => {
  const { closeModal } = useModalContext();
  const dispatch = useAppDispatch();

  const onClickSubmit = () => {
    dispatch(submitEmailSettingUpdateMiddleware(currentState));
    disableEditingMode();
    closeModal();
  };

  const onClickNevermind = () => {
    closeModal();
  };

  return (
    <>
      <div>
        You have selected to change the settings for the following email:
        <ul>
          <li>{initState.emailAddress}</li>
        </ul>
      </div>
      <div>
        You have opted to change the following settings:
        <ul>
          {Object.keys(initState)
            .map((key) => {
              let keyNew = key as keyof EmailSetting;
              //@ts-ignore
              if (initState[keyNew] !== currentState[keyNew]) {
                return (
                  <li>
                    {fieldToDisplayMappings[keyNew]} --{"> "}
                    <span className="bold">
                      {currentState[keyNew] ? "Enabled" : "Disabled"}
                    </span>
                  </li>
                );
              }
            })
            .filter((exists) => exists)}
        </ul>
      </div>
      <div>
        After selecting confirm, the email will recieve an email notification of
        the following changes.
      </div>
      <div className="modal-action-container">
        <Button onClick={onClickSubmit} variant="contained" color="success">
          Submit Changes
        </Button>
        <Button onClick={onClickNevermind} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

export default ChangeEmailSettingsModal;
