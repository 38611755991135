import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";
import "./Pagination.scss";

import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

// Example items, to simulate fetching from another resources.

interface PaginatedItemsProps {
  itemsPerPage: number;
  items: any[];
  Display: any;
  other: { [key: string]: any };
}

function PaginatedItems({
  itemsPerPage,
  items,
  Display,
  other,
}: PaginatedItemsProps) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (itemOffset > items.length) {
      setItemOffset(Math.floor(items.length / itemsPerPage) * itemsPerPage);
    }
  }, [items]);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {/** @ts-ignore */}
      <Display currentItems={currentItems} {...other} />
      <ReactPaginate
        breakLabel="..."
        nextLabel={<FaAngleRight />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={<FaAngleLeft />}
        breakClassName={"break item"}
        pageClassName={"selectable-shadow page item"}
        pageLinkClassName={"page-link"}
        containerClassName={"pagination-container"}
        previousClassName={"prev-button selectable-shadow item"}
        nextClassName={"next-button selectable-shadow item"}
      />
    </>
  );
}

export default PaginatedItems;
