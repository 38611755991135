import { authResultIsAdmin, authResultGetPid } from "../utils/authUtils";
import { AuthState } from "../store/authSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { User } from "oidc-client-ts";

export function logout(state: AuthState) {
  state.email = "";
  state.pid = "";
}

export function setProvisionerCommonName(
  state: AuthState,
  action: PayloadAction<string>
) {
  state.provisionerCommonName = action.payload;
}

export function storeUser(state: AuthState, action: PayloadAction<User>) {
  let user = action.payload;
  let { exp } = user.profile;
  state.user = user;
  state.displayName = user.profile.nickname || "";
  state.email = user.profile.email || "";
  state.pid = authResultGetPid(user);
  state.isAdmin = authResultIsAdmin(user);
  state.userError = false;
  state.isLoading = false;
  state.expires_at = exp;

  // let newUserState: AuthState = {
  //   user,
  //   displayName: user.profile.nickname || "",
  //   email: user.profile.email || "",
  //   userError: false,
  //   isLoading: false,
  //   pid: authResultGetPid(user),
  //   isAdmin: authResultIsAdmin(user),
  //   expires_at: exp,
  //   provisionerCommonName: state.provisionerCommonName,
  // };

  // state = { ...newUserState };
}

export function storeUserError(state: AuthState) {
  state.user = {} as User;
  state.isLoading = false;
  state.userError = true;
  state.expires_at = 0;
}

export function setIsLoading(state: AuthState, action: PayloadAction<boolean>) {
  state.isLoading = action.payload;
}
