import { Stack, Chip, Typography, Button, Paper } from "@mui/material";
import { useState } from "react";
import logger from "../../utils/logger";
import Emails from "./Emails";
import Nnids from "./Nnids";
import Users from "./Users";

const ProvisionerInfo = () => {
  const [pageIndex, setPageIndex] = useState<number>(0);

  const onClick = (pageIndex: number) => {
    setPageIndex(pageIndex);
    logger.debug("asdf");
  };

  return (
    <>
      <div className="provisioner-info-page">
        <div className="top-page-selectable-tab-buttons">
          <div className="top-page-selectable-tab-buttons-container">
            <Typography className="provisioner-info-menu-option" variant="h4">
              View
            </Typography>
            <Button
              variant={pageIndex === 0 ? "contained" : "outlined"}
              className="provisioner-info-menu-option"
              onClick={() => onClick(0)}
            >
              <Typography variant="h4">Emails</Typography>
            </Button>
            <Button
              variant={pageIndex === 1 ? "contained" : "outlined"}
              className="provisioner-info-menu-option"
              onClick={() => onClick(1)}
            >
              <Typography variant="h4">Users</Typography>
            </Button>
            <Button
              variant={pageIndex === 2 ? "contained" : "outlined"}
              className="provisioner-info-menu-option"
              onClick={() => onClick(2)}
            >
              <Typography variant="h4">Nnids</Typography>
            </Button>
          </div>
        </div>
        {pageIndex === 0 && <Emails />}
        {pageIndex === 1 && <Users />}
        {pageIndex === 2 && <Nnids />}
      </div>
    </>
  );
};

export default ProvisionerInfo;
