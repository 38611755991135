import { Button } from "@mui/material";

interface ActionButtonsInterface {
  removeDisabled?: boolean;
  editDisabled?: boolean;
  newDisabled?: boolean;
  removeEditable?: boolean; //"Editable" --> Even though it's not currently enabled, a state can be reached where it is
  editEditable?: boolean;
  newEditable?: boolean;
  removeOnClick?: () => void;
  editOnClick?: () => void;
  newOnClick?: () => void;
}

const nullOnClick = () => {};

const ActionButtons = ({
  removeDisabled = false,
  editDisabled = false,
  newDisabled = false,
  removeEditable = false,
  editEditable = false,
  newEditable = false,
  removeOnClick = nullOnClick,
  editOnClick = nullOnClick,
  newOnClick = nullOnClick,
}: ActionButtonsInterface) => {
  return (
    <div className="provisioner-info-actions">
      <div className="provisioner-info-actions-container">
        <Button
          disabled={removeDisabled || removeEditable}
          onClick={removeOnClick}
          variant={removeEditable ? "outlined" : "contained"}
        >
          Remove
        </Button>
        <Button
          disabled={editDisabled || editEditable}
          onClick={editOnClick}
          variant={editEditable ? "outlined" : "contained"}
        >
          Edit
        </Button>
        <Button
          disabled={newDisabled || newEditable}
          onClick={newOnClick}
          variant={newEditable ? "outlined" : "contained"}
        >
          New
        </Button>
      </div>
    </div>
  );
};

export default ActionButtons;
