import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "./DropDown.scss";
import logger from "../../utils/logger";
import { DisplayableValue } from "../../model/store/displayableValue";
import { SxProps } from "@mui/material";
import { List } from "reselect/es/types";

/**
 *
 * @param {String} placeholder string placehold for input element before anything is entered
 * @param {List: Objects} list list of items that will be displayed in the dropdown.
 * @param {Object} style any additional styling you'd like to add to the dropdown container
 * @param {Method} onClick method that will be invoked after an option has been chosen. It'll be passed the
 * object from the list that represents that option.
 * @param {Boolean} disabled Control if the UI widget is disabled or not, false by default
 *
 * @returns {Component}
 */

interface DropdownProps {
  placeholder: string;
  list: DisplayableValue<any>[];
  sx?: SxProps;
  initialValue?: any;
  onClick: (value: any) => void;
  disabled?: boolean;
  error?: boolean;
}

const DropDown = ({
  placeholder,
  list,
  sx = {},
  initialValue,
  onClick,
  disabled = false,
  error = false,
}: DropdownProps) => {
  const [value, setValue] = useState<any | undefined>(initialValue);

  const handleChange = (event: any): void => {
    let displayableValue = list.find(
      (dispValue) => dispValue.value === event.target.value
    );

    setValue(event.target.value);
    onClick(displayableValue);
  };

  interface Children {
    children: React.ReactNode;
  }

  const FormControlLocal = ({ children }: Children) => {
    if (disabled && error) {
      return (
        <FormControl fullWidth disabled error>
          {children}
        </FormControl>
      );
    }
    if (disabled) {
      return (
        <FormControl fullWidth disabled>
          {children}
        </FormControl>
      );
    }
    if (error) {
      return (
        <FormControl fullWidth error>
          {children}
        </FormControl>
      );
    }
    return <FormControl fullWidth>{children}</FormControl>;
  };

  return (
    <>
      <Box sx={{ mt: 2, width: "100%", ...sx }}>
        <FormControlLocal>
          <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label={placeholder}
            onChange={handleChange}
          >
            {list.map((listItem: DisplayableValue<any>) => {
              // I want to pass up the entire "DisplayableValue" to the parent component,
              // So we're going to ignore this TS error
              //@ts-ignore
              return (
                <MenuItem value={listItem.value}>{listItem.display}</MenuItem>
              );
            })}
          </Select>
        </FormControlLocal>
      </Box>
    </>
  );
};

export default DropDown;
