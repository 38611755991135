import CasesList from "./pages/CasesList";
import NewCase from "./pages/NewCase";
import Notifications from "./pages/Notifications";
import SingularCase from "./pages/SingularCase";
import Error from "./pages/Error";
import { SidebarContextProvider } from "./context/SidebarContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import { ModalContextProvider } from "./context/ModalContext";
import { Provider } from "react-redux";
import store from "./store";
import Login from "./pages/Login";
import Callback from "./pages/Callback";
import InitialSync from "./auth/InitialSync";
import InitialAdminSync from "./auth/InitialSyncAdmin";
import Logout from "./pages/Logout/Logout";
import { Helmet } from "react-helmet";
import SilentRefresh from "./pages/SilentRefresh";
import SharedLayout from "./SharedLayout";
import logger from "./utils/logger";
import { useEffect } from "react";
import ProvisionerInfo from "./pages/ProvisionerInfo";
import AdminConfigurations from "./pages/AdminConfigurations";
import QueryTn from "./pages/QueryTn";
import Documentation from "./pages/Documentation";
import EmailSettings from "./pages/EmailSettings";

function App() {
  const HTML_TITLE = process.env.REACT_APP_HTML_TITLE;

  return (
    <>
      <Helmet>
        <title>{HTML_TITLE}</title>
      </Helmet>
      <Provider store={store}>
        <SidebarContextProvider>
          <ModalContextProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<SharedLayout />}>
                  <Route index element={<Login />} />
                  <Route path="logout" element={<Logout />} />
                  <Route path="callback" element={<Callback />} />
                  <Route
                    path="silent"
                    element={<SilentRefresh store={store} />}
                  />
                  <Route element={<InitialSync></InitialSync>}>
                    <Route path="caselist" element={<CasesList />} />
                    <Route path="case/:caseId" element={<SingularCase />} />
                    <Route path="newcase" element={<NewCase />} />
                    <Route path="notifications" element={<Notifications />} />
                    <Route path="querytn" element={<QueryTn />} />
                    <Route path="documentation" element={<Documentation />} />
                    <Route path="email-settings" element={<EmailSettings />} />
                    <Route
                      path="*"
                      element={
                        <Error
                          stateProp={{
                            statusCode: "404",
                            reason: "That page doesn't seem to exist",
                          }}
                        />
                      }
                    />
                    {/* <Route path="querytn" element={<CasesList />} /> */}
                  </Route>
                  <Route path="/admin">
                    <Route element={<InitialAdminSync></InitialAdminSync>}>
                      <Route path="caselist" element={<CasesList />} />
                      <Route path="case/:caseId" element={<SingularCase />} />
                      <Route path="notifications" element={<Notifications />} />
                      <Route
                        path="provisioner-info"
                        element={<ProvisionerInfo />}
                      />
                      <Route
                        path="configurations"
                        element={<AdminConfigurations />}
                      />

                      <Route path="documentation" element={<Documentation />} />
                      <Route path="querytn" element={<QueryTn />} />
                      <Route path="error" element={<Error />} />
                      <Route
                        path="*"
                        element={
                          <Error
                            stateProp={{
                              statusCode: "404",
                              reason: "That page doesn't seem to exist",
                            }}
                          />
                        }
                      />
                      <Route path="querytn" element={<QueryTn />} />
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </ModalContextProvider>
        </SidebarContextProvider>
      </Provider>
    </>
  );
}

export default App;
