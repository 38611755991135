import axios from "axios";
import { User } from "oidc-client-ts";

const getLocalStorageKey = (): string => {
  return `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY_URL}:${process.env.REACT_APP_OIDC_AUTHORITY_CLIENT_ID}`;
};

export const getAuthToken = () => {
  let localStorageString = localStorage.getItem(getLocalStorageKey());
  let localStorageObject: User | null = null;
  if (localStorageString) {
    localStorageObject = JSON.parse(localStorageString);
  }

  return {
    headers: {
      Authorization: `Bearer ${
        localStorageObject !== null ? localStorageObject.id_token : ""
      }`,
    },
  };
};

export default axios;
