import React from "react";
import { FaRegWindowClose } from "react-icons/fa";
import "./Modal.scss";

const ModalSkeleton = ({
  children,
  closeModal,
  header,
  style,
  allowExitFromWhitespace,
}) => {
  return (
    <div
      className="modal-background"
      onClick={() => {
        if (allowExitFromWhitespace) {
          closeModal();
        }
      }}
    >
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={style}
      >
        <div className="modal-header">
          <h2>{header}</h2>
          <div className="modal-close-button-container" onClick={closeModal}>
            <FaRegWindowClose fontSize="1.5rem" />
          </div>
        </div>
        <div className="straight-line" />
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default ModalSkeleton;
