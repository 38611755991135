import { useState } from "react";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import "./Accordian.scss";
import AccordionMui from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/*
 *  Items should be inputted in the following format:
 *  panels = [
 *      {
 *       header: [text]
 *       component: [React Component]
 *      }
 *  ]
 */

/**
 * Returns an accordian Component which different panels are controled by + or - icons
 *
 * @param {List} panels list of objects, each containing header key (String) and component key (Component)
 * @returns {Component}
 */
interface AccordianProps {
  panels: PanelProps[];
}
const Accordian = ({ panels }: AccordianProps) => {
  return (
    <div className="accordian-container">
      {panels.map((panel, index) => {
        return <Panel {...panel} key={index} />;
      })}
    </div>
  );
};

/**
 * An individual panel of the Accordian.
 *
 * @param {String} header String to be displayed on top of the panel
 * @param {Component} component Component to be rendered when that panel is expanded
 * @returns {Component}
 */
interface PanelProps {
  header: string;
  component: React.ReactNode;
  expanded?: boolean;
}

const Panel = ({ header, component, expanded }: PanelProps) => {
  return (
    <AccordionMui defaultExpanded={expanded} className="panel">
      <AccordionSummary
        className="panel-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="h6">{header}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={"panel-body"}>{component}</div>
      </AccordionDetails>
    </AccordionMui>
  );
};

export default Accordian;
