import {
  fetchNotificationsAxios,
  postReadAllNotificationsAxios,
  postReadUnreadNotificationAxios,
} from "../service/notificationsService";
import { handleRequestError } from "./middlewareUtils";
import { notificationActions } from "../store/notificationsSlice";
import logger from "../utils/logger";
import { MiddlewareType } from "./middlewareType";

function fetchNotificationsMiddleware(): MiddlewareType {
  return (dispatch, getState) => {
    if (!getState().notification.isLoading && getState().auth.pid) {
      logger.log("fetchNotificationsMiddleware invoked");
      dispatch(notificationActions.setIsLoading(true));
      fetchNotificationsAxios()
        .then((res) => {
          dispatch(
            notificationActions.setNotificationList(res.data.notifications)
          );
          dispatch(notificationActions.setIsLoading(false));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
          dispatch(notificationActions.setIsLoading(false));
        });
    }
  };
}

function markAllNotificationsAsReadMiddleware(): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("markAllNotificationsAsReadMiddleware invoked");
    let originalState = getState().notification.notificationList;

    dispatch(notificationActions.markAllAsRead());

    postReadAllNotificationsAxios()
      .then((res) => {
        logger.log(res.data);
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(notificationActions.setNotificationList(originalState)); //Rollback Example
      });
  };
}

function markNotificationAsReadUnreadMiddleware(
  createdTs: number,
  readUnread: boolean
): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("markNotificationAsReadUnreadMiddleware invoked");
    const originalState = getState().notification.notificationList;
    const pid = getState().auth.pid;

    dispatch(
      notificationActions.markAsReadUnread({
        notificationId: pid + "|" + createdTs,
        readUnread,
      })
    );

    postReadUnreadNotificationAxios({ createdTs, readUnread })
      .then((res) => {
        logger.log(res.data);
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(notificationActions.setNotificationList(originalState));
      });
  };
}

export {
  fetchNotificationsMiddleware,
  markAllNotificationsAsReadMiddleware,
  markNotificationAsReadUnreadMiddleware,
};
