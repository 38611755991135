import { filterOutDuplicates } from "../../utils/listUtils";
import { capitalize } from "../../utils/genericUtils";
import SingularCase from "../../model/store/singularCase";
import { DisplayableValue } from "../../model/store/displayableValue";

/**
 * Generates the different dropdown options for the filter modal. Will only return unique values
 * when necessary.
 *
 * @param {List: Objects} data list of case objects.
 * @returns
 */
const parseDropdownDataOptions = (
  data: SingularCase[]
): { [field: string]: DisplayableValue<string>[] } => {
  if (!data) {
    data = [];
  }

  //If user is an admin, their Pid will be empty. If so, we want to remove the "You option"
  let provisionersToOpenAgainst = [
    ...filterOutDuplicates(
      data,
      (singularCase) => {
        return singularCase.openedAgainst;
      },
      (setObj) => {
        return {
          value: setObj,
          display: capitalize(setObj),
        };
      }
    ),
  ];

  let provisionersToOpenBy = [
    ...filterOutDuplicates(
      data,
      (singularCase) => {
        return singularCase.openedBy;
      },
      (setObj) => {
        return {
          value: setObj,
          display: capitalize(setObj),
        };
      }
    ),
  ];

  // IF there are duplicates in the options, the fitlering won't work as expected in the MUI Autocomplete!
  let caseId = [
    ...new Set(data.map((singularCase) => singularCase.caseId)),
  ].map((caseId) => {
    return {
      display: caseId.substring(0, 6),
      value: caseId,
    };
  });

  let dataOptions = {
    caseId: caseId,
    status: [
      {
        value: "OPENED",
        display: "Opened",
      },
      {
        value: "IN_PROGRESS",
        display: "In Progress",
      },
      {
        value: "CLOSED",
        display: "Closed",
      },
    ],
    openedBy: provisionersToOpenBy,
    openedAgainst: provisionersToOpenAgainst,
    specificTn: [
      ...new Set(
        data
          .map((singularCase) => singularCase.tns)
          .flat()
          .map((tn) => tn.tn)
      ),
    ].map((tn) => {
      return {
        value: tn,
        display: tn,
      };
    }),
  };

  return dataOptions;
};

export { parseDropdownDataOptions };
