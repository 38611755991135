import {
  FIELD_VALUES,
  OPERATOR_VALUES,
  TypeDisplay,
  IFieldValue,
} from "./filterStaticValues";
import { useEffect, useState } from "react";
import DropDown from "../../components/forms/DropDown";
import DateInput from "../../components/forms/DateInput";
import TextboxInput from "../../components/forms/TextboxInput";
import { parseDropdownDataOptions } from "./parseDropdownDataOptions";
import { useMemo } from "react";
import { conflictCaseActions } from "../../store/conflictCasesSlice";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import Filter from "../../model/store/filter";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { DisplayableValue } from "../../model/store/displayableValue";
import AutoComplete from "../../components/forms/AutoComplete";
import DatePickerLocal from "../../components/forms/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { FaInfoCircle } from "react-icons/fa";
import { isValidTn } from "../../utils/genericUtils";

/**
 * Component that corresponds to the create new filter modal. Will create filter objects
 * and pass them back up to the parent component, where the logic should occur there.
 *
 * @param {Method} closeFilterDropdown method to close the filter modal
 * @returns {Component}
 */

interface FilterModalProps {
  closeFilterDropdown: () => void;
  filter: Filter | null;
}

interface IErrorState {
  fieldError: boolean;
  fieldErrorMessage: string;
  operatorError: boolean;
  operatorErrorMessage: string;
  valueError: boolean;
  valueErrorMessage: string;
}

const FilterModal = ({ closeFilterDropdown, filter }: FilterModalProps) => {
  const NO_ERROR_STATE: IErrorState = {
    fieldError: false,
    fieldErrorMessage: "",
    operatorError: false,
    operatorErrorMessage: "",
    valueError: false,
    valueErrorMessage: "",
  };

  const allCasesForUser = useAppSelector(
    (state) => state.conflictCase.caseList
  );
  const pid = useAppSelector((state) => state.auth.pid);
  const isEdit = filter !== null;

  const [fieldSelect, setFieldSelect] = useState<IFieldValue>(
    isEdit
      ? FIELD_VALUES.find((fieldValue) => {
          return fieldValue.value === filter.field;
        })!
      : ({} as IFieldValue)
  );
  const [operatorSelect, setOperatorSelect] = useState<
    DisplayableValue<string>
  >(
    isEdit
      ? ({
          display: filter.operatorDisplay,
          value: filter.operator,
        } as DisplayableValue<string>)
      : ({} as DisplayableValue<string>)
  );
  const [valueSelect, setValueSelect] = useState<DisplayableValue<string>>(
    isEdit
      ? ({
          display: filter.valueDisplay,
          value: filter.value,
        } as DisplayableValue<string>)
      : ({} as DisplayableValue<string>)
  );
  const [error, setError] = useState<IErrorState>(NO_ERROR_STATE);

  const dispatch = useAppDispatch();

  const dropdownValueOptions = useMemo(() => {
    return parseDropdownDataOptions(allCasesForUser);
  }, [allCasesForUser]);

  const addOrEditFilter = (filterObj: Filter) => {
    dispatch(conflictCaseActions.addOrEditFilter(filterObj));
  };

  const validateInputsAndApply = () => {
    let isError = false;
    let newErrorState: IErrorState = {} as IErrorState;
    if (!fieldSelect.value) {
      newErrorState.fieldError = true;
      newErrorState.fieldErrorMessage = "You did not choose a field!";
      isError = true;
    }
    if (!operatorSelect.value) {
      newErrorState.operatorError = true;
      newErrorState.operatorErrorMessage = "You did not choose a field!";
      isError = true;
    }
    if (!valueSelect.value) {
      newErrorState.valueError = true;
      newErrorState.valueErrorMessage = "You did not choose a field!";
      isError = true;
    }
    if (
      fieldSelect.valueValidations !== undefined &&
      fieldSelect.valueValidations.length > 0
    ) {
      let returnedErrorMessage = "";

      fieldSelect.valueValidations.forEach((validation) => {
        returnedErrorMessage += validation(valueSelect.value);
      });

      if (returnedErrorMessage !== "") {
        newErrorState.valueError = true;
        newErrorState.valueErrorMessage = returnedErrorMessage;
        isError = true;
      }
    }
    if (isError) {
      setError({
        ...NO_ERROR_STATE,
        ...newErrorState,
      });
    } else {
      setError(NO_ERROR_STATE);

      addOrEditFilter({
        field: fieldSelect!.value,
        fieldDisplay: fieldSelect!.display,
        operator: operatorSelect!.value,
        operatorDisplay: operatorSelect!.display,
        value: valueSelect!.value,
        valueDisplay: valueSelect!.display,
        id: isEdit ? filter.id : new Date().getTime(),
      } as Filter);

      closeFilterDropdown();
    }
  };

  return (
    <div className="filters-modal">
      <h2>Filters:</h2>
      <div className="line"></div>
      <div className="field-operator-container">
        <div className="field-container">
          <h3>Field</h3>
          {error.fieldError && (
            <div className="input-error">{error.fieldErrorMessage}</div>
          )}
          <AutoComplete
            placeholder="Select a field"
            initialValue={isEdit ? filter.fieldDisplay : ""}
            list={FIELD_VALUES}
            onClick={(value: IFieldValue) => setFieldSelect(value)}
            error={error.fieldError}
          />
        </div>
        <div className="operator-container">
          <h3>Operator</h3>
          {error.operatorError && (
            <div className="input-error">{error.operatorErrorMessage}</div>
          )}
          <AutoComplete
            placeholder="Select an operator"
            initialValue={isEdit ? filter.operatorDisplay : ""}
            disabled={!fieldSelect.value}
            list={fieldSelect.value ? OPERATOR_VALUES[fieldSelect.value] : []}
            onClick={(operator: DisplayableValue<string>) =>
              setOperatorSelect(operator)
            }
            error={error.operatorError}
          />
        </div>
      </div>

      <div className="line" />
      <div className="value-select">
        <h3>Value</h3>
        {error.valueError && (
          <>
            <div className="input-error">{error.valueErrorMessage}</div>
          </>
        )}
        {fieldSelect && fieldSelect.infoText && (
          <>
            <div
              className="informational"
              style={{
                padding: "0.25rem",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            >
              {fieldSelect.toolTip && (
                <Tooltip
                  title={
                    <>
                      <Typography variant="body1">
                        {fieldSelect.toolTip}
                      </Typography>
                    </>
                  }
                >
                  <IconButton size="small">
                    <FaInfoCircle size={"1.5rem"} />
                  </IconButton>
                </Tooltip>
              )}
              {fieldSelect.infoText}
            </div>
          </>
        )}
        {fieldSelect && fieldSelect.typeDisplay === TypeDisplay.DROPDOWN && (
          <AutoComplete
            placeholder="Select a value"
            initialValue={isEdit ? filter.valueDisplay : ""}
            disabled={!fieldSelect || !operatorSelect}
            error={error.valueError}
            list={fieldSelect ? dropdownValueOptions[fieldSelect.value] : []}
            onClick={(value: DisplayableValue<string>) => setValueSelect(value)}
          />
        )}
        {fieldSelect &&
          fieldSelect.typeDisplay === TypeDisplay.TEXT_BOX_NUMBER && (
            <TextboxInput
              initialValue={valueSelect.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValueSelect({
                  value: e.target.value,
                  display: e.target.value,
                })
              }
              type="number"
              label={"Select a Value"}
            />
          )}
        {fieldSelect &&
          fieldSelect.typeDisplay === TypeDisplay.TEXT_BOX_STRING && (
            <TextboxInput
              initialValue={valueSelect.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValueSelect({
                  value: e.target.value,
                  display: e.target.value,
                })
              }
              label={"Select a Value"}
            />
          )}
        {fieldSelect && fieldSelect.typeDisplay === TypeDisplay.DATE && (
          <DatePickerLocal
            onChange={(e: Dayjs) =>
              setValueSelect({
                display: `${e.month() + 1}/${e.date()}/${e.year()}`,
                value: (e.unix() * 1000).toString(),
              })
            }
            sx={{ mt: 2 }}
            initialValue={
              valueSelect.value !== undefined
                ? Number.parseInt(valueSelect.value)
                : new Date().getTime()
            }
            error={error.valueError}
          />
        )}
        {fieldSelect.typeDisplay === undefined && (
          <AutoComplete
            placeholder="Select a value"
            disabled={true}
            error={error.valueError}
            list={[]}
            onClick={() => null}
          />
        )}
      </div>
      <div className="filter-controls">
        <Button variant="contained" onClick={() => validateInputsAndApply()}>
          Apply
        </Button>
        <Button variant="outlined" onClick={closeFilterDropdown}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default FilterModal;
