import EmailSetting from "../../model/store/emailSetting";

const currentStateHasChanged = (
  initState: EmailSetting,
  currentState: EmailSetting
): boolean => {
  let returned = true;

  Object.keys(initState).forEach((key) => {
    //@ts-ignore
    returned = initState[key] === currentState[key] && returned;
  });

  return returned;
};

export default currentStateHasChanged;
