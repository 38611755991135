import { ErrorState } from "../store/errorSlice";
import { PayloadAction } from "@reduxjs/toolkit";

interface ErrorStateInput {
  errorMessage: string;
  errorTs?: string;
}

export function setError(
  state: ErrorState,
  action: PayloadAction<ErrorStateInput>
) {
  state.error = { ...state.error, ...action.payload };
  state.error.isError = true;
}
export function hideError(state: ErrorState) {
  state.error.isError = false;
}
export function clearError(state: ErrorState) {
  state.error = {
    isError: false,
    errorMessage: "",
    errorTs: "",
    errorDisplayTime: 10000,
  };
}
