import { TextField } from "@mui/material";
import { SxProps, TextFieldVariants } from "@mui/material";
import { RefObject } from "react";

/**
 * Simple text input component. Calls onChange when the textbox is entered.
 *
 * @param {Method} onChange
 * @returns {Component}
 */

interface TextboxInputProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: RefObject<any> | undefined;
  variant?: TextFieldVariants | undefined;
  label?: string;
  sx?: SxProps;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  error?: boolean;
  type?: React.HTMLInputTypeAttribute | undefined;
  value?: string | number;
  initialValue?: string | number;
}

const TextboxInput = ({
  onChange = () => {},
  inputRef,
  variant = "outlined",
  label = "",
  sx = {},
  disabled,
  size,
  type,
  error,
  value,
  initialValue = "",
}: TextboxInputProps) => {
  return (
    <TextField
      type={type}
      sx={sx}
      size={size}
      label={label}
      error={error}
      variant={variant}
      disabled={disabled}
      defaultValue={initialValue}
      inputRef={inputRef}
      onChange={onChange}
      value={value}
    ></TextField>
  );
};

export default TextboxInput;
