import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { useAppSelector } from "../../store/hooks";
import AccordionMui from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import EmailSetting from "../../model/store/emailSetting";
import EmailPanelBody from "./EmailPanelBody";

const EmailSettings = () => {
  const emailSettings = useAppSelector(
    (state) => state.emailSetting.emailSettings
  );

  return (
    <div className="page-container">
      <div className="page-title-container">
        <Typography variant="h3">Email Notification Settings</Typography>
      </div>
      <div className="documentation-info-data-container">
        {emailSettings.map((emailSetting) => {
          return <EmailPanel emailSetting={emailSetting} />;
        })}
      </div>
    </div>
  );
};

interface PanelProps {
  emailSetting: EmailSetting;
}

const EmailPanel = ({ emailSetting }: PanelProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isExapanded, setIsExapanded] = useState<boolean>(false);
  const onClickEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (isEditing) {
      setIsEditing(false);
      setIsExapanded(true);
    } else {
      setIsEditing(true);
      setIsExapanded(true);
    }
  };

  const disableEditingMode = () => {
    setIsEditing(false);
    setIsExapanded(true);
  };

  return (
    <AccordionMui
      className="panel"
      expanded={isEditing || isExapanded}
      onClick={() => setIsExapanded(!isExapanded)}
    >
      <AccordionSummary className="panel-header">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{emailSetting.emailAddress}</Typography>
          <Button
            onClick={onClickEdit}
            variant={isEditing ? "outlined" : "contained"}
          >
            {!isEditing ? "Edit" : "Cancel Edit"}
          </Button>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={"panel-body"}>
          <EmailPanelBody
            emailSetting={emailSetting}
            isEditing={isEditing}
            disableEditingMode={disableEditingMode}
          />
        </div>
      </AccordionDetails>
    </AccordionMui>
  );
};

export default EmailSettings;
