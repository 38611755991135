import { createSlice, current } from "@reduxjs/toolkit";
import { DisplayableValue } from "../model/store/displayableValue";
import Nnid from "../model/store/nnid";
import { NnidType } from "../model/store/nnidType";
import * as AdminReducers from "../reducer/adminReducers";
import CurrentPidUser from "../model/store/currentPidUser";
import NnidOwner from "../model/store/nnidOwner";
import EmailSetting from "../model/store/emailSetting";

const adminSlice = createSlice({
  name: "admin",
  initialState: initAdminState(),
  reducers: {
    ...AdminReducers,
  },
});

export interface PortalConfigurations {
  NEW_CASE_EXPIRES_DAYS: number;
  NUM_TNS_ALLOWED: number;
  CLOSE_EXPIRED_CASES: boolean;
  HEALTHCHECK_ALARM_THRESHOLD: number;
  OSR_CONNECTION_BOS_OR_CHI: string;
}

export interface AdminState {
  allProvisionerList: any[];
  enabledProvisionerList: any[];
  currentPid: DisplayableValue<string>;
  currentPidEmails: EmailSetting[];
  currentPidUsers: CurrentPidUser[];
  currentPidNnids: Nnid[];
  portalConfigurations: PortalConfigurations;
  nnidOwnerList: NnidOwner[];
  isLoading: boolean;
}

function initAdminState(): AdminState {
  let currentPid = localStorage.getItem("currentPid");
  let currentPidObject: DisplayableValue<string> =
    {} as DisplayableValue<string>;

  if (currentPid !== null) {
    currentPidObject = JSON.parse(currentPid);
  }
  return {
    allProvisionerList: [],
    enabledProvisionerList: [],
    currentPid: currentPidObject,
    isLoading: false,
    currentPidEmails: [],
    currentPidUsers: [],
    currentPidNnids: [],
    portalConfigurations: {
      NEW_CASE_EXPIRES_DAYS: 0,
      NUM_TNS_ALLOWED: 0,
      HEALTHCHECK_ALARM_THRESHOLD: 4,
      CLOSE_EXPIRED_CASES: true,
      OSR_CONNECTION_BOS_OR_CHI: "",
    },
    nnidOwnerList: [],
  };
}
const adminActions = adminSlice.actions;

export { adminActions, adminSlice };
