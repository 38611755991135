import { Button } from "@mui/material";
import { useState, useRef } from "react";
import TextboxInput from "../../components/forms/TextboxInput";
import { useModalContext } from "../../context/ModalContext";
import {
  editDocumentationMiddleware,
  addDocumentationMiddleware,
} from "../../middleware/documentationMiddleware";
import Documentation from "../../model/store/documentation";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fileToByteArray, truncateDecimals } from "../../utils/fileUtils";

const NO_ERROR_STATE = {
  isDisplayNameError: false,
  displayNameErrorMessage: "",
  isFileError: false,
  fileErrorMessage: "",
  isDescriptionError: false,
  descriptionErrorMessage: "",
};

const INPUT_INITIAL_STATE = {
  description: "",
  displayName: "",
  fileName: "",
  fileData: [] as number[],
};

interface NewDocumentationModalInterface {
  clearSelectedDocumentation: () => void;
}

const NewDocumentationModalContent = ({
  clearSelectedDocumentation,
}: NewDocumentationModalInterface) => {
  const documents = useAppSelector(
    (state) => state.documentation.documentationList
  );
  const [inputState, setInputState] = useState(INPUT_INITIAL_STATE);
  const [errorState, setErrorState] = useState(NO_ERROR_STATE);
  const inputRef = useRef<HTMLInputElement>(null!);
  const dispatch = useAppDispatch();

  const { closeModal } = useModalContext();

  const onClickAddButton = () => {
    let errorSubmiting = false;

    if (inputState.displayName === "") {
      setErrorState((errorState) => {
        return {
          ...errorState,
          isDisplayNameError: true,
          displayNameErrorMessage: "You did not enter a new display name!",
        };
      });
      errorSubmiting = true;
    }
    if (inputState.description === "") {
      setErrorState((errorState) => {
        return {
          ...errorState,
          isDescriptionError: true,
          descriptionErrorMessage: "You did not enter a new display name!",
        };
      });
      errorSubmiting = true;
    }

    let displayNameUnique = documents.reduce((value: boolean, document) => {
      return document.displayName !== inputState.displayName && value;
    }, true);

    if (!displayNameUnique) {
      setErrorState((errorState) => {
        return {
          ...errorState,
          isDisplayNameError: true,
          displayNameErrorMessage:
            "There already exists a document with that display name!",
        };
      });
      errorSubmiting = true;
    }

    if (inputState.fileData.length === 0 || inputState.fileName === "") {
      setErrorState((errorState) => {
        return {
          ...errorState,
          isFileError: true,
          fileErrorMessage: "You did not attach a file!",
        };
      });
      errorSubmiting = true;
    }

    if (!errorSubmiting) {
      dispatch(
        addDocumentationMiddleware(
          inputState.displayName,
          inputState.description,
          inputState.fileName,
          inputState.fileData
        )
      );
      clearSelectedDocumentation();
      closeModal();
    }
  };

  const onClickNevermindButton = () => {
    clearSelectedDocumentation();
    closeModal();
  };

  const handleFileAdd = async () => {
    const file = Array.prototype.slice.call(inputRef.current.files)[0];
    if (!file) {
      setErrorState({
        ...errorState,
        isFileError: true,
        fileErrorMessage: "You did not add a file",
      });
      return;
    }

    let fileData = await fileToByteArray(file);

    setInputState({
      ...inputState,
      fileData: fileData,
      fileName: file.name,
    });
  };

  return (
    <>
      <p>
        You have selected to <span className="bold">ADD</span> a new piece of
        nnSR documentation.
      </p>
      <div className="line" />
      <p>Please provide the following information</p>
      <div
        style={{ width: "100%" }}
        className="prov-info-nnid-edit-modal-inputs"
      >
        {errorState.isDisplayNameError && (
          <p className="input-error">{errorState.displayNameErrorMessage}</p>
        )}
        <TextboxInput
          sx={{ width: "100%" }}
          label="Display Name"
          variant="outlined"
          error={errorState.isDisplayNameError}
          value={inputState.displayName}
          onChange={(e) => {
            setInputState({
              ...inputState,
              displayName: e.target.value,
            });
          }}
        />
        {errorState.isFileError && (
          <p className="input-error">{errorState.fileErrorMessage}</p>
        )}

        <input type="file" ref={inputRef} onChange={handleFileAdd} />

        {errorState.isDescriptionError && (
          <p className="input-error">{errorState.descriptionErrorMessage}</p>
        )}

        <TextboxInput
          sx={{ width: "100%", mt: 4 }}
          label="Description"
          variant="outlined"
          error={errorState.isDescriptionError}
          value={inputState.description}
          onChange={(e) => {
            setInputState({
              ...inputState,
              description: e.target.value,
            });
          }}
        />
      </div>
      <div className="prov-info-modal-action-container">
        <Button onClick={onClickAddButton} variant="contained" color="success">
          Add
        </Button>
        <Button
          onClick={onClickNevermindButton}
          variant="outlined"
          color="warning"
        >
          Nevermind
        </Button>
      </div>
    </>
  );
};

export default NewDocumentationModalContent;
