import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface DatePickerLocalProps {
  onChange: (newValue: Dayjs) => void;
  initialValue: number | null;
  error?: boolean;
  sx?: {};
}

const DatePickerLocal = ({
  onChange,
  initialValue,
  error,
  sx,
}: DatePickerLocalProps) => {
  const [value, setValue] = useState<Dayjs | null>();

  useEffect(() => {
    if (initialValue !== null && initialValue > 100000) {
      onChangeLocal(dayjs(initialValue));
    } else {
      onChangeLocal(dayjs());
    }
  }, []);

  const onChangeLocal = (newValue: Dayjs | null) => {
    if (newValue !== null) {
      setValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={sx}
        label="Select a Value"
        value={value}
        onChange={onChangeLocal}
      />
    </LocalizationProvider>
  );
};

export default DatePickerLocal;
