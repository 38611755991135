import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import "./DropDown.scss";
import { DisplayableValue } from "../../model/store/displayableValue";
import { Autocomplete, SxProps, TextField } from "@mui/material";

/**
 *
 * @param {String} placeholder string placehold for input element before anything is entered
 * @param {List: Objects} list list of items that will be displayed in the dropdown.
 * @param {Object} style any additional styling you'd like to add to the dropdown container
 * @param {Method} onClick method that will be invoked after an option has been chosen. It'll be passed the
 * object from the list that represents that option.
 * @param {Boolean} disabled Control if the UI widget is disabled or not, false by default
 *
 * @returns {Component}
 */

interface AutoCompleteProps {
  placeholder: string;
  list: DisplayableValue<any>[];
  sx?: SxProps;
  initialValue?: string;
  onClick: (value: any) => void;
  disabled?: boolean;
  error?: boolean;
}

const AutoComplete = ({
  placeholder,
  list,
  sx = {},
  initialValue,
  onClick,
  disabled = false,
  error = false,
}: AutoCompleteProps) => {
  const [currentDisplay, setCurrentDisplay] = useState<string>(
    initialValue !== undefined ? initialValue : ""
  );

  const listLocal = useMemo(() => {
    return [
      ...list.map((li) => {
        return li.display;
      }),
    ];
  }, [list]);

  /* Complicated logic regarding how we want the initial state to be displayed
   * As the underlying list changes.
   *    1) IF there is only one viable selection the list, select that one
   *    2) IF there are other values in the list, and initial value is selected
   *      a) IF the initial value is inside the new List --> Click on that one
          b) IF NOT, clear the selection
   *    2) IF NOT, clear the selection
   */
  useEffect(() => {
    if (listLocal.length === 1) {
      onClickLocal(null, list[0].display);
    } else {
      if (!listLocal.includes(currentDisplay!)) {
        onClickLocal(null, "");
      }
    }
  }, [listLocal]);

  const onClickLocal = (event: any, display: string): void => {
    let newSelectedItem = list.find((asdf) => {
      return asdf.display === display;
    });

    if (newSelectedItem) {
      onClick(newSelectedItem);
      setCurrentDisplay(display);
    } else {
      onClick({ display: "", value: "" });
      setCurrentDisplay("");
    }
  };

  return (
    <Box sx={{ width: "100%", mt: 2, ...sx }}>
      <Autocomplete
        disabled={disabled}
        disableClearable
        value={currentDisplay}
        onChange={onClickLocal}
        id="combo-box-demo"
        options={listLocal}
        renderInput={(params) => (
          <TextField error={error} {...params} label={placeholder} />
        )}
      />
    </Box>
  );

  // return (
  //   <>
  //     <Box sx={{ mt: 2, width: "100%", ...sx }}>
  //       <FormControlLocal>
  //         <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
  //         <Select
  //           labelId="demo-simple-select-label"
  //           id="demo-simple-select"
  //           value={value}
  //           label={placeholder}
  //           onChange={handleChange}
  //         >
  //           {list.map((listItem: DisplayableValue<any>) => {
  //             // I want to pass up the entire "DisplayableValue" to the parent component,
  //             // So we're going to ignore this TS error
  //             //@ts-ignore
  //             return (
  //               <MenuItem value={listItem.value}>{listItem.display}</MenuItem>
  //             );
  //           })}
  //         </Select>
  //       </FormControlLocal>
  //     </Box>
  //   </>
  // );
};

export default AutoComplete;
