import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { authActions } from "./store/authSlice";
import { conflictCaseActions } from "./store/conflictCasesSlice";
//@ts-ignore
import { ReactComponent as LoadingSVG } from "./loading.svg";
import { notificationActions } from "./store/notificationsSlice";
import { useAppSelector } from "./store/hooks";
import { adminActions } from "./store/adminSlice";
import { documentationActions } from "./store/documentationSlice";

const LOADING_BAR_MAX_TIME = 30000;

const LoadingDisplay = () => {
  const caseListLoading = useAppSelector(
    (state) => state.conflictCase.isLoading
  );
  const notificationListLoading = useAppSelector(
    (state) => state.notification.isLoading
  );
  const authLoading = useAppSelector((state) => state.auth.isLoading);
  const adminLoading = useAppSelector((state) => state.admin.isLoading);
  const documentationLoading = useAppSelector(
    (state) => state.documentation.isLoading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(authActions.setIsLoading(false));
      dispatch(conflictCaseActions.setIsLoading(false));
      dispatch(notificationActions.setIsLoading(false));
      dispatch(adminActions.setIsLoading(false));
      dispatch(documentationActions.setIsLoading(false));
    }, LOADING_BAR_MAX_TIME);

    return () => {
      clearTimeout(timeout);
    };
  }, [caseListLoading, notificationListLoading, authLoading, adminActions]);

  return (
    <>
      {(caseListLoading ||
        notificationListLoading ||
        authLoading ||
        documentationLoading ||
        adminLoading) && <LoadingSVG className="loading-icon-svg" />}
    </>
  );
};

export default LoadingDisplay;
