/**
 * Takes a list of objects, filters them down to a single array, keeps only unique values of that array,
 * and then allows you to preform an additional transformation of that information with the return function.
 *
 * @param {List:Object} list List of objects to filter out duplicates.
 * @param {Method} uniqueFunction The function which defines uniquness of the object. Must return a single primative type
 * for the filtering to work correctly.
 * @param {Method} returnFunction Allows you to expand the now array back into an object.
 * @returns {List:Object}
 */

const filterOutDuplicates = (
  list: any[],
  uniqueFunction: (input: any) => any | undefined,
  returnFunction: (input: any) => { [key: string | number]: any }
): any[] => {
  return [...new Set(list.map(uniqueFunction))]
    .filter((obj) => obj)
    .map(returnFunction);
};

export { filterOutDuplicates };
