import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { getTimeStringShort } from "../../utils/timeUtils";
import { adminActions } from "../../store/adminSlice";
import ActionButtons from "../../components/ui/ActionButtons";
import { Button, Paper, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useModalContext } from "../../context/ModalContext";
import TextboxInput from "../../components/forms/TextboxInput";
import { editCurrentPidUserUsernameMiddleware } from "../../middleware/adminMiddleware";
import CurrentPidUser from "../../model/store/currentPidUser";
import InfoToolTip from "../../components/ui/InfoToolTip";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Email",
    minWidth: 200,
    valueGetter: (params: GridValueGetterParams) => params.row.email,
  },
  { field: "displayName", headerName: "displayName", minWidth: 200 },
  {
    field: "firstLoggedIn",
    headerName: "First Logged In",
    valueGetter: (params: GridValueGetterParams) => {
      return `${getTimeStringShort(params.row.firstLoggedIn)}`;
    },
    minWidth: 200,
  },
  {
    field: "lastLoggedIn",
    headerName: "Last Logged In",
    valueGetter: (params: GridValueGetterParams) => {
      return `${getTimeStringShort(params.row.lastLoggedIn)}`;
    },
    minWidth: 200,
  },
];

const Users = () => {
  const users = useAppSelector((state) => state.admin.currentPidUsers);
  const [selectedUser, setSelectedUser] = useState<GridRowSelectionModel>([]);
  const { displayModal } = useModalContext();

  const noSelectedUser = selectedUser.length < 1;

  const userRows = useMemo(() => {
    return users.map((user, index) => {
      return {
        id: index,
        ...user,
      };
    });
  }, [users]);

  const onRowClick = (event: GridRowSelectionModel) => {
    let newSelectedId = [] as GridRowSelectionModel;
    if (event.length > 0) {
      newSelectedId = [event.pop()] as GridRowSelectionModel;
    } else {
      newSelectedId = [];
    }
    setSelectedUser(newSelectedId);
  };

  const editOnClick = () => {
    let user = userRows.find((user) => {
      return user.id === selectedUser[0];
    });

    displayModal(
      <EditUserModalContent user={user!} />,
      "Edit User",
      { width: "50%" },
      true
    );
  };

  return (
    <>
      <Typography variant="h3" sx={{ mt: "2rem", mb: "2rem" }}>
        Users
        <InfoToolTip>
          <Typography>
            Displays the different Users who have ever signed in under this PID
            from salesforce. Editing the DisplayName will controle what their
            comments are displayed as.
          </Typography>
        </InfoToolTip>
      </Typography>
      <ActionButtons
        removeDisabled={true}
        editEditable={noSelectedUser}
        newDisabled={true}
        editOnClick={editOnClick}
      />
      <Paper className="provisioner-info-data-container">
        <DataGrid
          rows={userRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          onRowSelectionModelChange={onRowClick}
          rowSelectionModel={selectedUser}
        />
      </Paper>
    </>
  );
};

interface EditUserModalContent {
  user: CurrentPidUser;
}

const EditUserModalContent = ({ user }: EditUserModalContent) => {
  const NO_ERROR_STATE = {
    isUsernameError: false,
    usernameErrorMessage: "",
  };

  const [errorState, setErrorState] = useState(NO_ERROR_STATE);
  const [username, setUsername] = useState(user.displayName);
  const { closeModal } = useModalContext();
  const dispatch = useAppDispatch();

  const onClickAddButton = () => {
    if (username === "") {
      setErrorState({
        isUsernameError: true,
        usernameErrorMessage: "The username cannot be empty!",
      });
      return;
    }
    if (username === user.displayName) {
      setErrorState({
        isUsernameError: true,
        usernameErrorMessage: "You didn't change the username!",
      });
      return;
    }
    setErrorState(NO_ERROR_STATE);
    dispatch(editCurrentPidUserUsernameMiddleware(user.email, username));

    closeModal();
  };

  const onClickNoButton = () => {
    closeModal();
  };

  return (
    <>
      <p>
        You have selected to edit the given user{" "}
        <span className="bold">{user.email}</span>
      </p>
      <p>
        Currently, you can only edit their "Username", which will dictate what
        the name will be displayed under{" "}
        <span className="bold">newly created cases and comments</span>
      </p>
      <div
        className="prov-info-user-edit-modal-inputs"
        style={{ width: "100%" }}
      >
        <TextboxInput
          sx={{ width: "100%" }}
          label="Email"
          disabled={true}
          value={user.email}
        />
        {errorState.isUsernameError && (
          <p className="input-error">{errorState.usernameErrorMessage}</p>
        )}
        <TextboxInput
          sx={{ width: "100%" }}
          label="User Name"
          variant="outlined"
          error={errorState.isUsernameError}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextboxInput
          sx={{ width: "100%" }}
          label="First Logged In"
          disabled={true}
          value={getTimeStringShort(user.firstLoggedIn)}
        />
        <TextboxInput
          sx={{ width: "100%" }}
          label="Last Logged In"
          disabled={true}
          value={getTimeStringShort(user.lastLoggedIn)}
        />
      </div>
      <div className="prov-info-modal-action-container">
        <Button onClick={onClickAddButton} variant="contained" color="warning">
          Edit
        </Button>
        <Button onClick={onClickNoButton} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

export default Users;
