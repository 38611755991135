import { useSelector } from "react-redux";
import { capitalize } from "../../utils/genericUtils";
import { getTimeString } from "../../utils/timeUtils";
import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { ReactElement } from "react";
import { AppState } from "../../store";
import SingularCase from "../../model/store/singularCase";
import { NnidType } from "../../model/store/nnidType";
import { useAppSelector } from "../../store/hooks";

const mapNnidType = (nnidType: NnidType) => {
  let returned: string;

  switch (nnidType) {
    case "NP_LOA":
      returned = "Number Provider LOA";
      break;
    case "SUBSCRIBER_LOA":
      returned = "Subscriber LOA";
      break;
    case "TOLLFREE_LOA":
      returned = "Toll Free LOA";
      break;
  }
  return returned;
};

/**
 * Static Header information of a case.
 *
 * @param {object} currentCase currentCase object with associated fields
 * @returns
 */
interface CaseHeaderProps {
  currentCase: SingularCase;
}

const CaseHeader = ({ currentCase }: CaseHeaderProps) => {
  const pid = useAppSelector((state: AppState) => state.auth.pid);
  const isLoading = useAppSelector(
    (state: AppState) => state.conflictCase.isLoading
  );

  if (isLoading) {
    return <CaseHeaderLoading></CaseHeaderLoading>;
  }

  return (
    <div className="section case-header">
      <div className="header-item">
        <span className="bold"> Opened By: </span>
        {currentCase.openedBy === pid
          ? "You"
          : capitalize(currentCase.openedBy)}
      </div>
      <div className="header-item">
        <span className="bold"> Opened Against: </span>
        <span>
          {currentCase.openedAgainst === pid
            ? "You"
            : capitalize(currentCase.openedAgainst)}
        </span>
      </div>
      <div className="header-item">
        <span className="bold">Status: </span>
        {capitalize(currentCase.status)}
      </div>
      <div className="header-item">
        <span className="bold">Number of TNs:</span> {currentCase.numberTns}
      </div>
      <div className="header-item">
        <span className="bold">Opened On: </span>
        {getTimeString(currentCase.openedTs)}
      </div>
      <div className="header-item">
        <span className="bold">Expires On: </span>
        {getTimeString(currentCase.expiresTs)}
      </div>
      <div className="header-item">
        <span className="bold">Requesting NNID: </span>
        {currentCase.nnidToProvisionTo}
      </div>
      <div className="header-item">
        <span className="bold">Requesting NNID Type: </span>
        {mapNnidType(currentCase.nnidType)}
      </div>
    </div>
  );
};

const CaseHeaderLoading = () => {
  return (
    <div className="section case-header">
      <Box className="header-item">
        <Skeleton variant="rectangular" width="100%" font-size={"1.5rem"} />
      </Box>
      <Box className="header-item">
        <Skeleton variant="rectangular" width="100%" font-size={"1.5rem"} />
      </Box>
      <Box className="header-item">
        <Skeleton variant="rectangular" width="100%" font-size={"1.5rem"} />
      </Box>
      <Box className="header-item">
        <Skeleton variant="rectangular" width="100%" font-size={"1.5rem"} />
      </Box>
      <Box className="header-item">
        <Skeleton variant="rectangular" width="100%" font-size={"1.5rem"} />
      </Box>
      <Box className="header-item">
        <Skeleton variant="rectangular" width="100%" font-size={"1.5rem"} />
      </Box>
      <Box className="header-item">
        <Skeleton variant="rectangular" width="100%" font-size={"1.5rem"} />
      </Box>
      <Box className="header-item">
        <Skeleton variant="rectangular" width="100%" font-size={"1.5rem"} />
      </Box>
    </div>
  );
};

export { CaseHeader };
