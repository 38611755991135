import { createSlice } from "@reduxjs/toolkit";
import * as AuthReducers from "../reducer/authReducers";
import { User } from "oidc-client-ts";

export interface AuthState {
  pid: string;
  email: string;
  displayName: string;
  provisionerCommonName: string;
  userError: boolean;
  isLoading: boolean;
  isAdmin: boolean;
  user: User;
  expires_at: number;
}

const initAuthState = (): AuthState => {
  return {
    pid: "",
    email: "",
    displayName: "",
    provisionerCommonName: "",
    userError: false,
    isLoading: false,
    isAdmin: false,
    expires_at: 0,
    user: {} as User, //all OIDC information
  };
};

const authSlice = createSlice({
  name: "auth",
  initialState: initAuthState(),
  reducers: { ...AuthReducers },
});

const authActions = authSlice.actions;

export { authSlice, authActions };
