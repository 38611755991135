import { EXPIRATION_LEAD_TIME_SECONDS } from "../auth/userServices";
import { useAppSelector } from "../store/hooks";
import logger from "../utils/logger";

const useIsUserAuthenticated = () => {
  const pid = useAppSelector((state) => state.auth.pid);
  const email = useAppSelector((state) => state.auth.email);
  const expires_at = useAppSelector((state) => state.auth.expires_at);
  const currTime = Math.floor(new Date().getTime() / 1000);

  return (): boolean => {
    return (
      pid !== null &&
      pid.trim() !== "" &&
      email !== null &&
      email.trim() !== "" &&
      expires_at - EXPIRATION_LEAD_TIME_SECONDS > currTime
    );
  };
};

export default useIsUserAuthenticated;
