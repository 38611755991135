import { useSidebarContext } from "../../context/SidebarContext";
import { useNavigate } from "react-router-dom";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";
import { useSelector, useDispatch } from "react-redux";
import { adminActions } from "../../store/adminSlice";
import { conflictCaseActions } from "../../store/conflictCasesSlice";
import { notificationActions } from "../../store/notificationsSlice";
import { signoutRedirect } from "../../auth/userServices";
import { initialSyncAsProvisionerAdminMiddleware } from "../../middleware/adminMiddleware";
import DropDown from "../../components/forms/DropDown";
import { BiLogOut } from "react-icons/bi";
import { Box } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { DisplayableValue } from "../../model/store/displayableValue";
import { authActions } from "../../store/authSlice";

/**
 * Component of the sidebar which comes in and out when sidebar is enabled.
 *
 * @returns Component
 */
const SideBar = () => {
  const { showSidebar, setShowSidebar } = useSidebarContext();
  const navigate = useNavigate();
  const isAdminAuthenticated = useIsAdminAuthenticated();
  const provisionerList = useAppSelector(
    (state) => state.admin.enabledProvisionerList
  );
  const currentPid = useAppSelector((state) => state.admin.currentPid);
  const dispatch = useAppDispatch();

  const selectProvisioner = (provisioner: DisplayableValue<string>) => {
    dispatch(adminActions.setCurrentPid(provisioner));
    dispatch(initialSyncAsProvisionerAdminMiddleware());
    setShowSidebar(false);
  };

  const redirectToPage = (page: string) => {
    if (isAdminAuthenticated()) {
      navigate(`/admin/${page}`);
    } else {
      navigate(`/${page}`);
    }
    setShowSidebar(false);
  };

  return (
    <>
      <div className={"sidebar " + (!showSidebar ? "hidden" : "visible")}>
        <div className="sidebar-section">
          {isAdminAuthenticated() && (
            <div className="sidebar-option provisioner-select">
              <Box sx={{ backgroundColor: "white", p: 1, borderRadius: 2 }}>
                <h2>Select a Provisioner</h2>
                <DropDown
                  placeholder="Please select an Provisioner..."
                  list={provisionerList === null ? [] : provisionerList}
                  onClick={(provisioner: DisplayableValue<string>) => {
                    if (provisioner) {
                      selectProvisioner(provisioner);
                    }
                  }}
                  initialValue={currentPid ? currentPid.value : undefined}
                />
              </Box>
            </div>
          )}
          <button
            className="sidebar-option"
            onClick={() => {
              redirectToPage("caselist");
            }}
          >
            Cases
          </button>
          {!isAdminAuthenticated() && (
            <>
              <button
                className="sidebar-option"
                onClick={() => redirectToPage("newcase")}
              >
                New
              </button>
            </>
          )}
          {isAdminAuthenticated() && (
            <>
              <button
                className="sidebar-option"
                onClick={() => redirectToPage("provisioner-info")}
              >
                Provisioner Info
              </button>
              <button
                className="sidebar-option"
                onClick={() => redirectToPage("configurations")}
              >
                Portal Configurations
              </button>
            </>
          )}
          <button
            className="sidebar-option"
            onClick={() => redirectToPage("querytn")}
          >
            Query TN
          </button>
          <button
            className="sidebar-option"
            onClick={() => redirectToPage("documentation")}
          >
            nnSR Documentation
          </button>
          {!isAdminAuthenticated() && (
            <>
              <button
                className="sidebar-option"
                onClick={() => redirectToPage("email-settings")}
              >
                Email Notifications Settings
              </button>
            </>
          )}
          <button
            className="sidebar-option"
            onClick={() => {
              signoutRedirect();
            }}
          >
            <BiLogOut /> Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default SideBar;
