import FrontEndLogInputDTO from "../model/input/frontEndLogInputDTO";
import SendLogsInputDTO from "../model/input/sendLogsInputDTO";
import SuccessFailureOutputDTO from "../model/output/successFailureOutputDTO";
import axios, { getAuthToken } from "./axios";
import { AxiosPromise } from "./axiosPromise";
import { LOGGING_ENDPOINT, BASE_URL } from "./serviceConfiguration";

export function sendLog(
  input: SendLogsInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(BASE_URL + LOGGING_ENDPOINT, input, getAuthToken());
}
