import EmailSetting from "../model/store/emailSetting";
import { updateEmailSettingAxios } from "../service/emailSettingsService";
import { conflictCaseActions } from "../store/conflictCasesSlice";
import { emailSettingActions } from "../store/emailSettingsSlice";
import { MiddlewareType } from "./middlewareType";
import { handleRequestError } from "./middlewareUtils";

export const submitEmailSettingUpdateMiddleware = (
  currentEmailState: EmailSetting
): MiddlewareType => {
  return (dispatch, getState) => {
    dispatch(conflictCaseActions.setIsLoading(true));
    updateEmailSettingAxios({ updatedEmailSetting: currentEmailState })
      .then(() => {
        dispatch(emailSettingActions.updateEmailSetting(currentEmailState));
        dispatch(conflictCaseActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(conflictCaseActions.setIsLoading(false));
      });
  };
};
