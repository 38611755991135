import {
  Box,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import ActionButtons from "../../components/ui/ActionButtons";
import { useModalContext } from "../../context/ModalContext";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getTimeStringOnlyDateAndTime } from "../../utils/timeUtils";
import { FaDownload } from "react-icons/fa";
import Documentation from "../../model/store/documentation";
import RemoveDocumentationModalContent from "./RemoveDocumentationModalContent";
import EditDocumentationModalContent from "./EditDocumentationModalContent";
import NewDocumentationModalContent from "./AddDocumentationModalContent";
import { downloadDocumentationAxios } from "../../service/documentationService";
import { documentationActions } from "../../store/documentationSlice";

const DocumentationPage = () => {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "File Name",
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <div className="bold"> {params.row.displayName}</div>;
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      disableColumnMenu: true,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return getTimeStringOnlyDateAndTime(params.row.lastUpdated);
      },
    },
    {
      field: "",
      headerName: "Download",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton>
            <FaDownload
              onClick={(e) => {
                e.stopPropagation();
                downloadFile(params.row.fileId, params.row.displayName);
              }}
            />
          </IconButton>
        );
      },
    },
  ];

  const isAdmin = useIsAdminAuthenticated();
  const { displayModal } = useModalContext();
  const documents = useAppSelector(
    (state) => state.documentation.documentationList
  );
  const [selectedDocument, setSelectedDocument] =
    useState<GridRowSelectionModel>([]);
  const dispatch = useAppDispatch();

  const downloadFile = (fileId: string, fileName: string) => {
    let fileDownloadPromise;
    dispatch(documentationActions.setIsLoading(true));
    fileDownloadPromise = downloadDocumentationAxios(fileId);

    fileDownloadPromise.then((res) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      let fileEnding = "";
      if (!fileName.includes(".")) {
        fileEnding = fileId.substring(fileId.lastIndexOf("."));
      }

      link.setAttribute("download", fileName + fileEnding);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
      dispatch(documentationActions.setIsLoading(false));
    });
  };

  const documentsRows = useMemo(() => {
    return documents.map((document) => {
      return {
        ...document,
        id: document.displayName,
      };
    });
  }, [documents]);

  const clearSelectedDocumentation = () => {
    setSelectedDocument([]);
  };

  const removeOnClick = () => {
    let document = documents.find(
      (document) => document.displayName === selectedDocument[0]
    );
    displayModal(
      <RemoveDocumentationModalContent
        document={document!}
        clearSelectedDocumentation={clearSelectedDocumentation}
      />,
      "Remove Document",
      { width: "50%" },
      true
    );
  };

  const editOnClick = () => {
    let document = documents.find(
      (document) => document.displayName === selectedDocument[0]
    );
    displayModal(
      <EditDocumentationModalContent
        document={document!}
        clearSelectedDocumentation={clearSelectedDocumentation}
      />,
      "Edit Document",
      { width: "50%" },
      true
    );
  };

  const newOnClick = () => {
    displayModal(
      <NewDocumentationModalContent
        clearSelectedDocumentation={clearSelectedDocumentation}
      />,
      "New Documentation",
      { width: "50%" },
      true
    );
  };

  const documentHasBeenSelected = selectedDocument.length > 0;

  // event is the new state, will all selected rows,
  // however we want to enforce only 1 being selected at a time.
  // So, we pop the last "id" or disEmail, and then set it as selectedEmail
  const onRowClick = (event: GridRowSelectionModel) => {
    let newSelectedId = [] as GridRowSelectionModel;
    if (event.length > 0) {
      newSelectedId = [event.pop()] as GridRowSelectionModel;
    } else {
      newSelectedId = [];
    }
    setSelectedDocument(newSelectedId);
  };

  return (
    <div className="documentation-page-container">
      <div className="documentation-page-title-container">
        <Typography variant="h3">nnSR Documentation</Typography>
      </div>
      {isAdmin() && (
        <ActionButtons
          removeEditable={!documentHasBeenSelected}
          editEditable={!documentHasBeenSelected}
          removeOnClick={removeOnClick}
          editOnClick={editOnClick}
          newOnClick={newOnClick}
        />
      )}
      <Paper className="documentation-info-data-container">
        <DataGrid
          className="documentation-info-data"
          rows={documentsRows}
          columns={columns}
          rowHeight={100}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          checkboxSelection={isAdmin()}
          onRowSelectionModelChange={onRowClick}
          isRowSelectable={() => isAdmin()}
          rowSelectionModel={selectedDocument}
        />
      </Paper>
    </div>
  );
};

export default DocumentationPage;
