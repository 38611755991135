import { UserManager } from "oidc-client-ts";
import { authActions } from "../store/authSlice";
import { OIDC_CONFIG } from "./oidcConfig";
import logger from "../utils/logger";
import { Store } from "redux";
import { User } from "oidc-client-ts";
import { conflictCaseActions } from "../store/conflictCasesSlice";
import { notificationActions } from "../store/notificationsSlice";
import store from "../store";

/**
 * todo:
 * For some obnoxious reason, the env variables aren't working here...
 */

const userManager = new UserManager(OIDC_CONFIG);

export const EXPIRATION_LEAD_TIME_SECONDS = 60;
export const EXPIRATION_LEAD_TIME_MS = EXPIRATION_LEAD_TIME_SECONDS * 1000;

export async function loadUserFromStorage() {
  logger.log("loadUserFromStorage called");
  let user = await userManager.getUser();
  if (!user) {
    store.dispatch(authActions.storeUserError());
    return null;
  }

  user = JSON.parse(JSON.stringify(user));
  store.dispatch(authActions.storeUser(user!));
  return user;
}

export async function getUserFromStorage() {
  return await userManager.getUser();
}

export function signinRedirect() {
  userManager.clearStaleState();
  userManager.removeUser();
  store.dispatch(authActions.logout());
  store.dispatch(conflictCaseActions.setCaseList([]));
  store.dispatch(notificationActions.setNotificationList([]));
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function signoutRedirect() {
  userManager.clearStaleState();
  userManager.removeUser();
  store.dispatch(authActions.logout());
  store.dispatch(conflictCaseActions.setCaseList([]));
  store.dispatch(notificationActions.setNotificationList([]));
  return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}

export function signinSilent() {
  return userManager.signinSilent();
}

export function signinSilentCallback() {
  return userManager.signinSilentCallback();
}

export default userManager;
