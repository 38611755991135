import { createSlice } from "@reduxjs/toolkit";
import SingularCase from "../model/store/singularCase";
import { PayloadAction } from "@reduxjs/toolkit";

export interface CaseFilterState {
  caseList: SingularCase[];
  caseListCurrentPageIndex: number;
  caseListRowsPerPage: number;
}

const initialState: CaseFilterState = {
  caseList: [],
  caseListCurrentPageIndex: 1,
  caseListRowsPerPage: 10,
};

const caseFilterSlice = createSlice({
  name: "case-filter",
  initialState,
  reducers: {
    save(state, action: PayloadAction<SingularCase[]>) {
      return {
        ...state,
        caseList: action.payload,
      };
    },
    setCaseListCurrentPageIndex(state, action: PayloadAction<number>) {
      return {
        ...state,
        caseListCurrentPageIndex: action.payload,
      };
    },
    setCaseListRowsPerPage(state, action: PayloadAction<number>) {
      return {
        ...state,
        caseListRowsPerPage: action.payload,
      };
    },
  },
});

export const caseFilterActions = caseFilterSlice.actions;
export default caseFilterSlice;
