import { signinRedirect } from "../../auth/userServices";
import { FaSalesforce } from "react-icons/fa";
import "./Login.scss";
import logger from "../../utils/logger";

const Login = () => {
  console.log("Test Deployment Change!");

  return (
    <>
      <div className="login-container">
        <div className="login-header">
          <h2>netnumber nnSR Portal</h2>
          <p>
            Welcome to the nnSR Conflict Resolution Portal! Please login through
            your netnumber account
          </p>
        </div>
        <div className="line"></div>
        <button
          className="login-button"
          onClick={() => {
            signinRedirect()
              .then((res: any) => {
                logger.log(res);
              })
              .catch((err: any) => {
                logger.error(err);
              });
          }}
        >
          <FaSalesforce size={"4rem"} color="white" />
          <h1 style={{ color: "white" }}>Login</h1>
        </button>
      </div>
    </>
  );
};

export default Login;
