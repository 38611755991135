import { useSelector, useDispatch } from "react-redux";
import { errorActions } from "./store/errorSlice";
import SideBar from "./pages/SideBar";
import TopBar from "./pages/TopBar";
import AlertModal from "./components/ui/AlertModal";
import LoadingDisplay from "./LoadingDisplay";
import { Outlet } from "react-router";
import AutoSignin from "./auth/AutoSignin";
import SendLogs from "./auth/SendLogs";
import SessionExpirationListener from "./auth/SessionExpirationListener";

const SharedLayout = () => {
  return (
    <>
      <TopBar />
      <SideBar />
      <LoadingDisplay />
      <AutoSignin />
      <SessionExpirationListener />
      <AlertModal />
      <SendLogs />
      <div className="main-content-container">
        <Outlet />
      </div>
    </>
  );
};

export default SharedLayout;
