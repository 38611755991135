import { PayloadAction } from "@reduxjs/toolkit";
import Documentation from "../model/store/documentation";
import { DocumentationState } from "../store/documentationSlice";

export function setIsLoading(
  state: DocumentationState,
  action: PayloadAction<boolean>
) {
  state.isLoading = action.payload;
}

export function setDocumentationList(
  state: DocumentationState,
  action: PayloadAction<Documentation[]>
) {
  state.documentationList = action.payload;
}

export function addNewDocumentation(
  state: DocumentationState,
  action: PayloadAction<Documentation>
) {
  state.documentationList = [...state.documentationList, action.payload];
}

interface EditDocumentationInterface {
  fileId: string;
  timeStamp: number;
  description?: string;
  displayName?: string;
}
export function editDocumentation(
  state: DocumentationState,
  action: PayloadAction<EditDocumentationInterface>
) {
  let documentationIndex = state.documentationList.findIndex(
    (documentation) => documentation.fileId === action.payload.fileId
  );

  if (documentationIndex !== -1) {
    if (action.payload.description) {
      state.documentationList[documentationIndex].description =
        action.payload.description;
      state.documentationList[documentationIndex].lastUpdated =
        action.payload.timeStamp;
    }
    if (action.payload.displayName) {
      state.documentationList[documentationIndex].displayName =
        action.payload.displayName;

      state.documentationList[documentationIndex].lastUpdated =
        action.payload.timeStamp;
    }
  }
}

export function removeDocumentation(
  state: DocumentationState,
  action: PayloadAction<string>
) {
  state.documentationList = state.documentationList.filter(
    (documentation) => documentation.fileId !== action.payload
  );
}
