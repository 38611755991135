import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";

/*Takes priority for looking at the stateProp. If no
stateProp is passed in, it uses useLocation's state.
If neither, it reverts to generic error message.*/

/**
 * Error Page. Can either display information based on data being passed in as props, or
 * via being pushed her via React Router.
 *
 * @param {Object} stateProp Object containing values for "statusCode" and "reason"
 * @returns Component
 */

interface ErrorProps {
  stateProp?: {
    statusCode: string;
    reason: string;
  };
}

const Error = ({ stateProp }: ErrorProps) => {
  const GENERIC_ERROR_STATUS = "We are sorry :/";
  const GENERIC_ERROR_REASON = "An error has occured in the application.";
  const isAdminUser = useIsAdminAuthenticated();

  const stateLocation = useLocation().state;

  //let state;
  let state = stateProp ? stateProp : stateLocation;
  // if (!stateProp) {
  //  state = stateLocation;
  // } else {
  //   state = stateProp;
  //  }

  let statusCode, reason;
  if (state) {
    statusCode = `Status Code: ${state.statusCode}`;
    reason = state.reason;
  } else {
    statusCode = GENERIC_ERROR_STATUS;
    reason = GENERIC_ERROR_REASON;
  }

  return (
    <div className="error-page-container">
      <h1 className="status-code">{statusCode}</h1>
      <h2 className="reason">{reason}</h2>
      {!isAdminUser() && (
        <Link className="back-button btn" to="/caselist">
          Back to cases
        </Link>
      )}
      {isAdminUser() && (
        <Link className="back-button btn" to="/admin/caselist">
          Back to cases
        </Link>
      )}
    </div>
  );
};

export default Error;
