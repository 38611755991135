import { useEffect } from "react";
import logger from "../utils/logger";
import { sendLog } from "../service/loggingService";
import SendLogsInputDTO from "../model/input/sendLogsInputDTO";
import useIsAdminAuthenticated from "../hooks/useIsAdminAuthenticated";
import useIsUserAuthenticated from "../hooks/useIsUserAuthenticated";

const RETRY_SEND_LOG_QUANTITY = 5;
const LOG_BULK_QUANTITY = 10;

const SendLogs = () => {
  const isAdminAuthenticated = useIsAdminAuthenticated();
  const isUserAuthenticated = useIsUserAuthenticated();

  const isAuthenticated = () => {
    return isAdminAuthenticated() || isUserAuthenticated();
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      let logs = logger.popLogs(LOG_BULK_QUANTITY);
      if (isAuthenticated() && logs.length > 0) {
        let input: SendLogsInputDTO = { logs: [...logs] };
        sendLog(input)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            logs.forEach((log) => {
              log.countRetries = log.countRetries + 1;
              if (log.countRetries < RETRY_SEND_LOG_QUANTITY) {
                logger.pushLog(log);
              }
            });
          });
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isAuthenticated]);

  return <></>;
};

export default SendLogs;
