import React, { createContext, useState, useContext } from "react";
import ModalSkeleton from "../components/ui/ModalSkeleton";

interface ModalContextInterface {
  showModal: boolean;
  displayModal: (
    prop: React.ReactNode,
    header: string,
    style: React.CSSProperties,
    allowExitFromWhitespace: boolean
  ) => void;
  closeModal: () => void;
}

interface ModalContextProviderProps {
  children: React.ReactNode;
}

const ModalContext = createContext<ModalContextInterface>(
  {} as ModalContextInterface
);

const ModalContextProvider = ({ children }: ModalContextProviderProps) => {
  //The fields that control the generic modal that appears up on the screen
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentModalProp, setCurrentModalProp] =
    useState<React.ReactNode>(null);

  const closeModal = (): void => {
    setShowModal(false);
    setCurrentModalProp(null);
  };

  const displayModal = (
    prop: React.ReactNode,
    header: string,
    style: React.CSSProperties,
    allowExitFromWhitespace: boolean
  ): void => {
    let modal = (
      <ModalSkeleton
        closeModal={closeModal}
        header={header}
        style={style}
        allowExitFromWhitespace={allowExitFromWhitespace}
      >
        {prop}
      </ModalSkeleton>
    );

    setCurrentModalProp(modal);
    setShowModal(true);
  };

  const modalData: ModalContextInterface = {
    showModal,
    displayModal,
    closeModal,
  };

  return (
    <ModalContext.Provider value={modalData}>
      {children}
      {showModal && currentModalProp}
    </ModalContext.Provider>
  );
};

/**
 * Returns global state values, including:
 * - showModal (boolean) -> current value of if the modal is being shown currently
 * - displayModal (Function) -> Displays a modal of content you pass in
 * - closeModal (Function) -> Closes the modal
 * @returns {Object}
 */

// export interface ModalContextInterface {}

const useModalContext = () => {
  let data = useContext(ModalContext);
  return data;
};

export { useModalContext, ModalContextProvider };
