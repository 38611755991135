import { SxProps, TextField, TextFieldVariants } from "@mui/material";
import { RefObject } from "react";

/**
 * Simple text input component. Calls onChange when the textbox is entered.
 *
 * @param {Method} onChange
 * @returns {Component}
 */

interface TextareaInputProps {
  onChange?: () => void;
  inputRef?: RefObject<any> | undefined;
  variant?: TextFieldVariants | undefined;
  label?: string;
  sx?: SxProps;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  error?: boolean;
  value?: string;
}

const TextareaInput = ({
  onChange = () => {},
  inputRef,
  variant,
  label = "",
  sx = {},
  size,
  disabled = false,
  error = false,
  value,
}: TextareaInputProps) => {
  return (
    <TextField
      multiline
      value={value}
      error={error}
      disabled={disabled}
      sx={sx}
      size={size}
      label={label}
      variant={variant}
      onChange={onChange}
      inputRef={inputRef}
    ></TextField>
  );
};

export default TextareaInput;
