import { useEffect } from "react";
import {
  initialSyncAdminMiddleware,
  initialSyncAsProvisionerAdminMiddleware,
} from "../middleware/adminMiddleware";
import { Outlet } from "react-router";
import { useAppDispatch, useAppSelector } from "../store/hooks";

const InitialAdminSync = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector((state) => state.auth.isAdmin);
  const currentPid = useAppSelector((state) => state.admin.currentPid);
  const alreadySelectedPid = currentPid
    ? currentPid.value
      ? true
      : false
    : false;

  useEffect(() => {
    dispatch(initialSyncAdminMiddleware());
  }, [isAdmin]);

  useEffect(() => {
    if (alreadySelectedPid === true) {
      dispatch(initialSyncAsProvisionerAdminMiddleware());
    }
  }, [alreadySelectedPid, isAdmin]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default InitialAdminSync;
