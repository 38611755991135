import { CaseStatus } from "../model/store/caseStatus";
import SingularCase from "../model/store/singularCase";

export const caseOpened = (singularCase: SingularCase): boolean => {
  let open = false;

  if (singularCase.status === CaseStatus.CLOSED) {
    return false;
  }

  for (let i = 0; i < singularCase.tns.length && !open; i++) {
    open = !singularCase.tns[i].responded || open;
  }

  return open;
};

export const capitalize = (word: string): string => {
  if (word) {
    return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
  } else {
    return "";
  }
};

export const isValidTn = (stringTn: string): boolean => {
  const regex = /^1\d{10}$/;

  if (stringTn.length !== 11) return false;
  if (!stringTn.match(regex)) return false;

  return true;
};

//used if the number is valid and you want to conver to E164
//Will strip out: +, (, ), " "
export const normalizeTn = (stringTn: string): string | undefined => {
  stringTn = stringTn.replaceAll("+", "");
  stringTn = stringTn.replaceAll("(", "");
  stringTn = stringTn.replaceAll(")", "");
  stringTn = stringTn.replaceAll(" ", "");
  if (isValidTn(stringTn)) {
    return stringTn;
  }
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return email.match(emailRegex) !== null;
};

export const isValidNnid = (nnid: string) => {
  const nnidRegex = /^\d{6}$/;
  return nnid.match(nnidRegex) !== null;
};
