import { useSelector } from "react-redux";
import { downloadFileAxios } from "../../service/conflictCaseService";
import { Button, Skeleton, Box } from "@mui/material";
import Loa from "../../model/store/loa";
import { useAppSelector } from "../../store/hooks";
import { NnidType } from "../../model/store/nnidType";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";
import { downloadFileAxiosAdmin } from "../../service/adminService";
import logger from "../../utils/logger";
import { mapNnidType } from "../../utils/nnidUtils";

/**
 * Component for Supporting LOAs section of a Singular Case
 *
 * @param {list} loas List of LOA objects to be displayed associated with the case.
 * @param {String} nnidType The Authorization Policy of the requesting NNID of the case
 *
 * @returns {Component}
 */
interface LoasProps {
  loas: Loa[];
  caseId: string;
  nnidType: NnidType;
}

const Loas = ({ loas, caseId, nnidType }: LoasProps) => {
  const isLoading = useAppSelector((state) => state.conflictCase.isLoading);
  const isAdmin = useIsAdminAuthenticated();
  const currentProvisioner = useAppSelector(
    (state) => state.admin.currentPid.value
  );

  /**
   * Downloads file a static endpoint.
   *
   * @param {String} fileId fileId to download
   * @param {String} displayName the name the file will be downloaded as.
   */
  const downloadFile = (
    fileName: string,
    displayName: string,
    view: boolean
  ) => {
    let fileDownloadPromise;

    if (isAdmin()) {
      fileDownloadPromise = downloadFileAxiosAdmin(
        currentProvisioner,
        caseId,
        fileName
      );
    } else {
      fileDownloadPromise = downloadFileAxios(caseId, fileName);
    }

    fileDownloadPromise
      .then((res) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", displayName);

        if (view) {
          link.setAttribute("target", "_blank");
        }

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  // const displayFile = (fileName, displayName) => {
  //   setShowAttachment(true);
  // };

  if (nnidType !== "SUBSCRIBER_LOA") {
    return (
      <div style={{ padding: "1rem" }} className="informational">
        <h2 className="bold">
          There will <span className="underline">NEVER</span> be a LOA required
          with a {mapNnidType(nnidType)} case.
        </h2>
        <p className="bold">
          Provisioning to Number Provider LOA based NNIDs uses rights granted by
          the Number Provider to a provisioner, which the requesting provisioner
          already has.
        </p>
        <p className="italic">
          For further information, reach out to helpdesk@netnumber.com
        </p>
      </div>
    );
  }
  if (!loas || loas.length === 0) {
    return <div>There are no LOAs associated with this case...</div>;
  }

  if (isLoading) {
    return <LoasIsLoading />;
  }
  return (
    <div className="loas-container">
      {loas.map((loa) => {
        return (
          <div className="loa" key={loa.fileName}>
            <div className="loa-title"> {loa.fileName}</div>
            <div className="loa-controls">
              <Button
                variant="outlined"
                onClick={() => downloadFile(loa.fileName, loa.fileName, false)}
              >
                Download
              </Button>
              {/* <button
                  onClick={() => downloadFile(loa.fileName, loa.fileName, true)}
                >
                  View
                </button> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const LoasIsLoading = () => {
  return (
    <div className="loas-container">
      {Array(3)
        .fill(null)
        .map((loa) => {
          return (
            <div className="loa">
              <Box width="50%">
                <Skeleton
                  variant="rectangular"
                  sx={{
                    fontSize: "1rem",
                  }}
                />
              </Box>
              <div className="loa-controls">
                <Button variant="outlined" disabled={true}>
                  Download
                </Button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export { Loas };
