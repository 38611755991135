import { useEffect } from "react";
import { UNAUTHORIZED_ERROR_MESSAGE } from "../middleware/middlewareUtils";
import { errorActions } from "../store/errorSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import logger from "../utils/logger";
import { signinRedirect } from "./userServices";
import { EXPIRATION_LEAD_TIME_MS } from "./userServices";

/**
 * This component handles redirecting the user back to Salesforce when their token is expiring.
 * The token expiration "lead time" is set inside of the userServices file.
 *
 * This class will set a timer ahead the exact amount of time to the token expiration, minus the
 * EXPIRATION_LEAD_TIME variable. So, if their token expires in 30 minutes, it'll redirect them to salesforce
 * 29 minutes away from now.
 *
 * This should never be triggered when the user first tries to reach the site. That logic should all be handled in
 * the AutoSignin component.
 *
 * @returns Component
 */
const SessionExpirationListener = () => {
  const expires_at = useAppSelector((state) => state.auth.expires_at);
  const dispatch = useAppDispatch();
  const urlPath = window.location.href.substring(window.location.origin.length);
  const isLoginScreen = urlPath === "/";
  const isCallbackScreen = urlPath.includes("/callback");

  function expireSession() {
    logger.warn("Session expiring soon, redirectiong user to Salesforce");
    dispatch(
      errorActions.setError({
        errorMessage: UNAUTHORIZED_ERROR_MESSAGE,
        errorTs: "",
      })
    );
    setTimeout(() => {
      signinRedirect();
    }, 3000);
  }

  useEffect(() => {
    if (expires_at && !isLoginScreen && !isCallbackScreen) {
      const now = new Date().getTime();
      const expirationTimeAheadMs =
        expires_at * 1000 - now - EXPIRATION_LEAD_TIME_MS;
      if (expirationTimeAheadMs > 0) {
        let checkSilentSigninInterval = setTimeout(
          expireSession,
          expirationTimeAheadMs
        );
        return () => {
          clearInterval(checkSilentSigninInterval);
        };
      }
    }
  }, [expires_at, isLoginScreen, isCallbackScreen]);

  return <></>;
};

export default SessionExpirationListener;
