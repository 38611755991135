import NewCaseUpload from "./NewCaseUpload";
import NewCaseResults from "./NewCaseResults";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../store/hooks";

/**
 * Top level component of NewCase flow. Only contains the top bar displaying which step you are on,
 * and controls which other component is being displayed based on that.
 *
 * @returns Component
 */
const NewCase = () => {
  const currentPage = useAppSelector(
    (state) => state.conflictCase.newCaseCurrentPage
  );

  return (
    <div className="new-case-page-container">
      <div className="new-case-page-topbar-container">
        <div className={"topbar-step " + (currentPage === 0 ? "active" : "")}>
          Step 1: Submit Information
        </div>
        <div className={"topbar-step " + (currentPage === 1 ? "active" : "")}>
          Step 2: Review Attempt
        </div>
      </div>
      {currentPage === 0 ? <NewCaseUpload /> : <NewCaseResults />}
    </div>
  );
};

export default NewCase;
