/**
 * Takes a list of notifications and will organize them based on the date they occured.
 *
 * @param {list} notifications List of notificaiton Objects, including timestamp
 * @returns {Object: Lists} Object of lists. Each object's key is the date, and value is a list of notification
 * objects that occured on that date.
 */
import Notification from "../model/store/notification";
import { NotificationType } from "../model/store/notificationType";

function partitionNotificationsByDate(notifications: Notification[]): {
  [dateString: string]: Notification[];
} {
  const tempSorting: { [dateString: string]: number[] } = {};
  const finalSorting: { [dateString: string]: Notification[] } = {};

  notifications.forEach((notification, index) => {
    let dateString = new Date(notification.timestamp).toDateString();
    if (!(dateString in tempSorting)) {
      tempSorting[dateString] = [];
    }
    tempSorting[dateString].push(index);
  });

  Object.keys(tempSorting).forEach((dateString) => {
    finalSorting[dateString] = [];

    tempSorting[dateString].forEach((ind) => {
      finalSorting[dateString].push(notifications[ind]);
    });
  });

  return finalSorting;
}

export function getNotificationStringFromEnum(
  header: NotificationType
): string {
  let returned: string = "";
  switch (header) {
    case NotificationType.CLOSED:
      returned = "Closed";
      break;
    case NotificationType.NEW_CASE:
      returned = "New Case";
      break;
    case NotificationType.NEW_COMMENT:
      returned = "New Comment";
      break;
    case NotificationType.PARTIAL_ACCEPT:
      returned = "TN(s) Accepted";
      break;
    case NotificationType.PARTIAL_REJECT:
      returned = "TN(s) Rejected";
      break;
  }

  return returned;
}

export { partitionNotificationsByDate };
