import { Button } from "@mui/material";
import { useState, useRef } from "react";
import TextboxInput from "../../components/forms/TextboxInput";
import { useModalContext } from "../../context/ModalContext";
import {
  editDocumentationMiddleware,
  addDocumentationMiddleware,
} from "../../middleware/documentationMiddleware";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fileToByteArray, truncateDecimals } from "../../utils/fileUtils";
import Documentation from "../../model/store/documentation";

const NO_ERROR_STATE = {
  isDisplayNameError: false,
  displayNameErrorMessage: "",
  isDescriptionError: false,
  descriptionErrorMessage: "",
};

interface EditDocumentationModalInterface {
  document: Documentation;
  clearSelectedDocumentation: () => void;
}

const EditDocumentationModalContent = ({
  document,
  clearSelectedDocumentation,
}: EditDocumentationModalInterface) => {
  const documents = useAppSelector(
    (state) => state.documentation.documentationList
  );
  const [inputState, setInputState] = useState(document);
  const [errorState, setErrorState] = useState(NO_ERROR_STATE);
  const dispatch = useAppDispatch();

  const { closeModal } = useModalContext();

  const onClickEditButton = () => {
    let errorSubmiting = false;

    if (inputState.displayName === "") {
      setErrorState({
        ...errorState,
        isDisplayNameError: true,
        displayNameErrorMessage: "You did not enter a new display name!",
      });
      errorSubmiting = true;
    }
    if (inputState.description === "") {
      setErrorState({
        ...errorState,
        isDescriptionError: true,
        descriptionErrorMessage: "You did not enter a new display name!",
      });
      errorSubmiting = true;
    }

    let fileId, description, displayName;

    fileId = document.fileId;
    description =
      inputState.description !== document.description
        ? inputState.description
        : undefined;
    displayName =
      inputState.displayName !== document.displayName
        ? inputState.displayName
        : undefined;

    if (displayName === undefined && description === undefined) {
      if (displayName === undefined) {
        setErrorState((errorState) => {
          return {
            ...errorState,
            isDisplayNameError: true,
            displayNameErrorMessage: "You did not enter a new display name!",
          };
        });
        errorSubmiting = true;
      }

      if (description === undefined) {
        setErrorState((errorState) => {
          return {
            ...errorState,
            isDescriptionError: true,
            descriptionErrorMessage: "You did not enter a new description!",
          };
        });
        errorSubmiting = true;
      }
    }

    let displayNameUnique = documents.reduce((value: boolean, currDocument) => {
      return (
        (currDocument.displayName !== inputState.displayName ||
          document.displayName === inputState.displayName) &&
        value
      );
    }, true);

    if (!displayNameUnique) {
      setErrorState((errorState) => {
        return {
          ...errorState,
          isDisplayNameError: true,
          displayNameErrorMessage:
            "There already exists a document with that display name!",
        };
      });
      errorSubmiting = true;
    }

    if (!errorSubmiting) {
      dispatch(
        editDocumentationMiddleware({ fileId, description, displayName })
      );
      clearSelectedDocumentation();
      closeModal();
    }
  };

  const onClickNevermindButton = () => {
    clearSelectedDocumentation();
    closeModal();
  };

  return (
    <>
      <p>
        You have selected to <span className="bold">EDIT</span> an existing"
        piece of nnSR documentation.
      </p>
      <p>
        <ol>
          <span className="bold">{document.displayName}</span>
        </ol>
      </p>
      <div className="line"></div>
      <p>Please edit the following information</p>
      <div
        style={{ width: "100%" }}
        className="prov-info-nnid-edit-modal-inputs"
      >
        {errorState.isDisplayNameError && (
          <p className="input-error">{errorState.displayNameErrorMessage}</p>
        )}
        <TextboxInput
          sx={{ width: "100%" }}
          label="Display Name"
          variant="outlined"
          error={errorState.isDisplayNameError}
          value={inputState.displayName}
          onChange={(e) => {
            setInputState({
              ...inputState,
              displayName: e.target.value,
            });
          }}
        />

        <input className="disabled-file-input" type="file" disabled />
        <span>
          You cannot edit the file of an existing upload, you must delete it and
          create a new one
        </span>

        {errorState.isDescriptionError && (
          <p className="input-error">{errorState.descriptionErrorMessage}</p>
        )}
        <TextboxInput
          sx={{ width: "100%", mt: 4 }}
          label="Description"
          variant="outlined"
          error={errorState.isDescriptionError}
          value={inputState.description}
          onChange={(e) => {
            setInputState({
              ...inputState,
              description: e.target.value,
            });
          }}
        />
      </div>
      <div className="prov-info-modal-action-container">
        <Button onClick={onClickEditButton} variant="contained" color="warning">
          Edit
        </Button>
        <Button
          onClick={onClickNevermindButton}
          variant="outlined"
          color="warning"
        >
          Nevermind
        </Button>
      </div>
    </>
  );
};

export default EditDocumentationModalContent;
