/**
 * Base url
 * TODO: Implement as ENV Variable
 */

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;

/**
 * Logging Endpoints
 */

export const LOGGING_ENDPOINT = "logging/send-logs";

/**
 * Notification Endpoints
 */

export const GET_NOTIFICATIONS_ENDPOINT = "get-notifications";
export const POST_READ_UNREAD_NOTIFICATION_ENDPOINT =
  "read-unread-notification";
export const POST_READ_ALL_NOTIFICATIONS_ENDPOINT = "read-all-notifications";

/**
 * Case Endpoints
 */

export const GET_CASES_ENDPOINT = "get-cases";
export const GET_CASE_ENDPOINT = "get-case";
export const POST_CREATE_CASE_ENDPOINT = "create-case";
export const POST_SUBMIT_COMMENT_ENDPOINT = "submit-comment";
export const POST_ACCEPT_TNS_ENDPOINT = "respond-tns-accept";
export const POST_REJECT_TNS_ENDPOINT = "respond-tns-reject";
export const ATTEMPT_PROVISION_TNS_ENDPOINT = "attempt-provision";
export const GET_NNID_LIST_ENDPOINT = "get-nnid-list";
export const INITIAL_SYNC_ENDPOINT = "initial-sync";
export const CLOSE_UNFINISHED_CASE_ENDPOINT = "close-unfinished-case";
export const OSR_GET_QUERY_FOR_TN_ENDPOINT = "query-tn";

/**
 * Auth Endpoints (Potentially will be deprecated later)
 */

export const LOGIN_ENDPOINT = "initial-sync";
export const CREATE_PROVISIONER_ENDPOINT = "create-provisioner";
export const CREATE_USER_ENDPOINT = "create-user";

const ADMIN_URL_PREFIX = "admin/";

export const GET_CASES_ENDPOINT_ADMIN = ADMIN_URL_PREFIX + "get-cases";
export const GET_CASE_ENDPOINT_ADMIN = ADMIN_URL_PREFIX + "get-case";
export const GET_NOTIFICATIONS_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "get-notifications";
export const INITIAL_SYNC_ENDPOINT_ADMIN = ADMIN_URL_PREFIX + "initial-sync";
export const INITIAL_SYNC_AS_PROVISIONER_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "login-as";
export const DOWNLOAD_LOA_ENDPOINT_ADMIN = ADMIN_URL_PREFIX;

export const ADD_CURRENT_PID_DIST_EMAIL_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "add-prov-dist-email";
export const EDIT_CURRENT_PID_DIST_EMAIL_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "edit-prov-dist-email";
export const REMOVE_CURRENT_PID_DIST_EMAIL_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "remove-prov-dist-email";

export const EDIT_CURRENT_PID_USER_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "edit-prov-user";

export const ADD_CURRENT_PID_NNID_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "add-prov-nnid";
export const EDIT_CURRENT_PID_NNID_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "edit-prov-nnid";
export const REMOVE_CURRENT_PID_NNID_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "remove-prov-nnid";

export const ENABLE_PROVISIONER_PORTAL_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "enable-prov-portal";
export const DISABLE_PROVISIONER_PORTAL_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "disable-prov-portal";

export const UPDATE_CONFIGURATIONS_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "update-portal-config";

export const OSR_GET_QUERY_FOR_TN_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "query-tn";

export const ADD_NNID_OWNER_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "add-nnid-owner";

export const EDIT_NNID_OWNER_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "edit-nnid-owner";
export const REMOVE_NNID_OWNER_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "remove-nnid-owner";
/*
 * Documentation Endpoints
 */
export const GET_DOCUMENTATION_ENDPOINT = "get-documentation";
export const ADD_DOCUMENTATION_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "add-documentation";
export const EDIT_DOCUMENTATION_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "edit-documentation";
export const REMOVE_DOCUMENTATION_ENDPOINT_ADMIN =
  ADMIN_URL_PREFIX + "remove-documentation";

/**
 * Email Settings Endpoints
 */

export const UPDATE_EMAIL_SETTING_ENDPOINT = "update-email-setting";
