import { errorActions } from "../store/errorSlice";
import { signinRedirect, signoutRedirect } from "../auth/userServices";
import logger from "../utils/logger";
import { AppState } from "../store";
import { AxiosError } from "axios";
import ErrorDTO from "../model/output/errorDTO";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { authActions } from "../store/authSlice";

const UNAUTHORIZED_REDIRECT_PAUSE_TS = 3000;
const NOT_ENABLED_REDIRECT_PAUSE_TS = 5000;
const NETWORK_ERROR_CODE = "ERR_NETWORK";
const NETWORK_ERROR_ERROR_MESSAGE =
  "Sorry, you are currently unable to reach our servers. Please try again later";
export const UNAUTHORIZED_ERROR_MESSAGE =
  "Sorry, your session has expired. Redirecting to SalesForce...";

export function handleRequestError(
  dispatch: ThunkDispatch<AppState, unknown, AnyAction>,
  err: AxiosError
): void {
  logger.error(err);

  if (err.code && err.code === NETWORK_ERROR_CODE) {
    dispatch(
      errorActions.setError({
        errorMessage: NETWORK_ERROR_ERROR_MESSAGE,
        errorTs: new Date().getTime().toString(),
      })
    );
    return;
  }

  let errorData = err.response?.data as ErrorDTO;
  let errorStatusCode = err.response?.status;
  switch (errorStatusCode) {
    case 500: //INTERNAL_SERVER_ERROR
      dispatch(
        errorActions.setError({
          errorMessage: errorData.errorMessage,
          errorTs: errorData.errorTs,
        })
      );
      break;
    case 400: //BAD_REQUEST
      dispatch(
        errorActions.setError({
          errorMessage: errorData.errorMessage,
          errorTs: errorData.errorTs,
        })
      );
      break;
    case 401: //UNAUTHORIZED
      dispatch(
        errorActions.setError({
          errorMessage: UNAUTHORIZED_ERROR_MESSAGE,
          errorTs: "",
        })
      );
      //Redirect them in 3 seconds
      setTimeout(() => {
        signinRedirect();
      }, UNAUTHORIZED_REDIRECT_PAUSE_TS);
      break;
    case 403: //FORBIDDEN
      dispatch(
        errorActions.setError({
          errorMessage: errorData.errorMessage,
          errorTs: "",
        })
      );
      //Redirect them in 3 seconds
      setTimeout(() => {
        dispatch(authActions.logout());
        signoutRedirect();
      }, NOT_ENABLED_REDIRECT_PAUSE_TS);
  }
}
