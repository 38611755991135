import { useRef, useState } from "react";
import { useModalContext } from "../../context/ModalContext";
import { useDispatch } from "react-redux";
import { submitRejectTnsMiddleware } from "../../middleware/conflictCaseMiddleware";
import { Button } from "@mui/material";
import { useAppDispatch } from "../../store/hooks";
import { NnidType } from "../../model/store/nnidType";
import { mapNnidType } from "../../utils/nnidUtils";

/**
 * Content of the modal displayed after user selects numbers and selects the reject button.
 *
 * @param {list} tns List of strings of TNs about to be rejected.
 * @param {String} provisioner String of provisioner name to be displayed on this modal
 * @param {function} closeModal function to close the modal
 * @param {function} Accept function to occur after the user has rejected the Tns
 * @returns {Component}
 */
interface RejectTnsModalContent {
  tns: string[];
  provisioner: string;
  caseId: string;
  caseNnidType: NnidType;
  setSelectedTns: (tns: string[]) => void;
}

const DEFAULT_ERROR_STATE = {
  hasInputError: false,
  inputErrorMessage: "",
};

const RejectTnsModalContent = ({
  tns,
  provisioner,
  caseId,
  caseNnidType,
  setSelectedTns,
}: RejectTnsModalContent) => {
  const { closeModal } = useModalContext();
  const dropDownRef = useRef<HTMLSelectElement>(null!);
  const textAreaRef = useRef<HTMLTextAreaElement>(null!);
  const dispatch = useAppDispatch();
  const [errorState, setErrorState] = useState(DEFAULT_ERROR_STATE);

  /**
   *
   * @param {List: String} tns String list of TNs which the approve/reject modal was affecting
   * @param {String} reason The reason from the dropdown the user selected.
   * @param {String} additionalText any additional text you wish to be added to the system comment that states this action occured.
   */
  const respondTnsRejectSubmit = (
    tns: string[],
    reason: string,
    additionalContext: string | undefined
  ) => {
    // If it's not a Subscriber LOA, they're required to add additional Context
    if (caseNnidType !== "SUBSCRIBER_LOA" && !additionalContext) {
      setErrorState((errorState) => {
        return {
          ...errorState,
          hasInputError: true,
          inputErrorMessage: `You MUST add more context with ${mapNnidType(
            caseNnidType
          )} cases`,
        };
      });
      return;
    }

    if (
      caseNnidType !== "SUBSCRIBER_LOA" &&
      additionalContext &&
      additionalContext.length < 7
    ) {
      setErrorState((errorState) => {
        return {
          ...errorState,
          hasInputError: true,
          inputErrorMessage: `Please provide more additional context...`,
        };
      });
      return;
    }

    dispatch(
      submitRejectTnsMiddleware(
        provisioner,
        caseId,
        tns,
        reason,
        additionalContext
      )
    );

    setSelectedTns([]);
    closeModal();
  };

  const getDropdownOptions = (): string[] => {
    let returned = [
      "Unwilling to release numbers at this time",
      "Other (Please describe below)",
    ];

    if (caseNnidType === "SUBSCRIBER_LOA") {
      returned = ["Unsatisfactory LOA", "LOA not included", ...returned];
    }

    return returned;
  };

  if (tns.length === 0) {
    return (
      <p>
        You did not selected any TNs! Please select one or more and try again.
      </p>
    );
  }
  return (
    <>
      <p>
        You have selected to REJECT the following numbers requested by{" "}
        {provisioner}:
      </p>
      <div className="tn-accept-reject-container">
        {tns.map((tn) => {
          return <li className="tn">{tn}</li>;
        })}
      </div>
      <p>Please select a reason why:</p>
      {caseNnidType !== "SUBSCRIBER_LOA" && (
        <div
          style={{ padding: "1rem", marginBottom: "1rem" }}
          className="bold informational"
        >
          <h3>
            Note: You may not reject because there is no LOA, as there will{" "}
            <span className="underline">NEVER</span> be an LOA required for a 
            {mapNnidType(caseNnidType)} Case
          </h3>
        </div>
      )}

      <select ref={dropDownRef}>
        {getDropdownOptions().map((option) => {
          return <option>{option}</option>;
        })}
      </select>
      {caseNnidType !== "SUBSCRIBER_LOA" ? (
        <p
          className="informational"
          style={{ padding: "1rem", marginBottom: "1rem" }}
        >
          <span className="bold">Required: </span> Please give more context for
          your decision
        </p>
      ) : (
        <p>(Opt) Please give more context for your decision</p>
      )}
      {errorState.hasInputError && (
        <p className="input-error">{errorState.inputErrorMessage}</p>
      )}
      <textarea ref={textAreaRef} />
      <div className="accept-tns-control-container">
        <Button
          className="danger"
          variant="contained"
          color="error"
          onClick={() =>
            respondTnsRejectSubmit(
              tns,
              dropDownRef.current.value,
              textAreaRef.current.value.length > 1
                ? textAreaRef.current.value
                : undefined
            )
          }
        >
          Reject
        </Button>
        <Button
          variant="contained"
          color="warning"
          className="warning"
          onClick={closeModal}
        >
          Close
        </Button>
      </div>
    </>
  );
};

export { RejectTnsModalContent };
