import { EXPIRATION_LEAD_TIME_SECONDS } from "../auth/userServices";
import { useAppSelector } from "../store/hooks";

const useIsAdminAuthenticated = () => {
  const { isAdmin } = useAppSelector((state) => state.auth);
  const expires_at = useAppSelector((state) => state.auth.expires_at);
  const currTime = Math.floor(new Date().getTime() / 1000);

  return (): boolean => {
    return isAdmin && expires_at - EXPIRATION_LEAD_TIME_SECONDS > currTime;
  };
};

export default useIsAdminAuthenticated;
