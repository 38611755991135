import { PayloadAction } from "@reduxjs/toolkit";
import EmailSetting from "../model/store/emailSetting";
import { EmailSettingState } from "../store/emailSettingsSlice";

export const updateEmailSetting = (
  state: EmailSettingState,
  action: PayloadAction<EmailSetting>
) => {
  let index = state.emailSettings.findIndex(
    (emailSetting) => emailSetting.emailAddress === action.payload.emailAddress
  );

  if (index > -1) {
    state.emailSettings[index] = action.payload;
  }
};

export const setEmailSettingList = (
  state: EmailSettingState,
  action: PayloadAction<EmailSetting[]>
) => {
  state.emailSettings = action.payload;
};

export const adminUpdateEmailSettings = (
  state: EmailSettingState,
  action: PayloadAction<{
    oldEmailAddress: string;
    newEmailSettings: EmailSetting;
  }>
) => {
  let index = state.emailSettings.findIndex(
    (emailSetting) =>
      emailSetting.emailAddress === action.payload.oldEmailAddress
  );

  if (index > -1) {
    state.emailSettings[index] = action.payload.newEmailSettings;
  }
};

export const adminAddEmailSettings = (
  state: EmailSettingState,
  action: PayloadAction<EmailSetting>
) => {
  state.emailSettings.push(action.payload);
};

export const adminRemoveEmailSettings = (
  state: EmailSettingState,
  action: PayloadAction<string>
) => {
  state.emailSettings = state.emailSettings.filter(
    (emailSetting) => emailSetting.emailAddress !== action.payload
  );
};
