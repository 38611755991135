import { useState, useMemo, useRef } from "react";
import ActionButtons from "../../components/ui/ActionButtons";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useModalContext } from "../../context/ModalContext";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { Paper, Button, Typography, Tooltip, IconButton } from "@mui/material";
import DropDown from "../../components/forms/DropDown";
import { DisplayableValue } from "../../model/store/displayableValue";
import {
  addNnidOwnerMiddleware,
  editNnidOwnerMiddleware,
  enableNewProvisionerMiddleware,
  removeNnidOwnerMiddleware,
  removeProvisionerMiddleware,
} from "../../middleware/adminMiddleware";
import { FaInfoCircle } from "react-icons/fa";
import NnidOwner from "../../model/store/nnidOwner";
import TextboxInput from "../../components/forms/TextboxInput";
import { isValidNnid } from "../../utils/genericUtils";
const columns: GridColDef[] = [
  { field: "id", headerName: "Nnid", minWidth: 200 },
  { field: "pidDisplayName", headerName: "Pid Common Name", minWidth: 600 },
];

const NnidOwnerComponent = () => {
  const nnidOwners = useAppSelector((state) => state.admin.nnidOwnerList);
  const [selectedNnidOwner, setSelectedNnidOwner] =
    useState<GridRowSelectionModel>([]);
  const { displayModal } = useModalContext();

  const nnidOwnerList = useMemo(() => {
    return nnidOwners.map((nnidOwner) => {
      return {
        id: nnidOwner.nnid,
        ...nnidOwner,
      };
    });
  }, [nnidOwners]);

  const removeOnClick = () => {
    let nnidValue = selectedNnidOwner[0];
    let nnidOwner = nnidOwners.find(
      (nnidOwner) => nnidOwner.nnid === nnidValue
    );

    displayModal(
      <RemoveNnidOwnerModalContent nnidOwner={nnidOwner!} />,
      "Remove Nnid Owner",
      { width: "50%" },
      true
    );
  };
  const editOnClick = () => {
    let nnidValue = selectedNnidOwner[0];
    let nnidOwner = nnidOwners.find(
      (nnidOwner) => nnidOwner.nnid === nnidValue
    );

    displayModal(
      <EditNnidOwnerModalContent nnidOwner={nnidOwner!} />,
      "Edit Nnid Owner",
      { width: "50%" },
      true
    );
  };
  const newOnClick = () => {
    displayModal(
      <AddNnidOwnerModalContent />,
      "Add Nnid Owner",
      { width: "50%" },
      true
    );
  };

  const onRowClick = (event: GridRowSelectionModel) => {
    let newSelectedId = [] as GridRowSelectionModel;
    if (event.length > 0) {
      newSelectedId = [event.pop()] as GridRowSelectionModel;
    } else {
      newSelectedId = [];
    }
    setSelectedNnidOwner(newSelectedId);
  };

  return (
    <>
      <Typography sx={{ mt: "2rem" }} variant="h3">
        Nnid Owners
        <Tooltip
          placement="right"
          title={
            <>
              <Typography>
                A "Nnid Owner" is a provisioner who is always responsible for
                all cases opened against any TNs currently provisioned for a
                given NNID. This is necessary when 2 provisioners both provision
                TNs for an NNID, but only one should be recieving cases
                regarding that NNID.
                <br />
                <br />
                NOTE: These changes can take up to 5 minutes to take affect due
                to caching
              </Typography>
            </>
          }
        >
          <IconButton>
            <FaInfoCircle size={"2rem"} />
          </IconButton>
        </Tooltip>
      </Typography>
      <ActionButtons
        removeEditable={selectedNnidOwner.length < 1}
        editEditable={selectedNnidOwner.length < 1}
        removeOnClick={removeOnClick}
        newOnClick={newOnClick}
        editOnClick={editOnClick}
      />
      <Paper className="provisioner-info-data-container">
        <DataGrid
          rows={nnidOwnerList}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          onRowSelectionModelChange={onRowClick}
          rowSelectionModel={selectedNnidOwner}
        />
      </Paper>
    </>
  );
};

interface RemoveNnidOwnerModalInterface {
  nnidOwner: NnidOwner;
}

const RemoveNnidOwnerModalContent = ({
  nnidOwner,
}: RemoveNnidOwnerModalInterface) => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModalContext();

  const onClickRemoveButton = () => {
    dispatch(removeNnidOwnerMiddleware(nnidOwner));
    closeModal();
  };

  const onClickNoButton = () => {
    closeModal();
  };

  return (
    <>
      <p>You have selected to the following Nnid Owner Relationship:</p>
      <ul>
        <li className="bold">
          {nnidOwner.nnid} --{">"} {nnidOwner.pid} / {nnidOwner.pidDisplayName}
        </li>
      </ul>
      <p>
        Now, cases opened against tns provisioned to {nnidOwner.nnid} will be
        opened against whatever pid provisioned them last...
      </p>
      <div className="line" />
      <p className="bold">Are you sure you'd like to continue? </p>
      <div className="admin-config-modal-action-container">
        <Button onClick={onClickRemoveButton} variant="contained" color="error">
          Remove
        </Button>
        <Button onClick={onClickNoButton} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

const NO_ERROR_STATE = {
  nnidError: false,
  nnidErrorMessage: "",
  provisionerError: false,
  provisionerErrorMessage: "",
};

interface AddNnidOwnerModalInterface {}

const AddNnidOwnerModalContent = ({}: AddNnidOwnerModalInterface) => {
  const nnidInputRef = useRef<HTMLInputElement>(null!);
  const [selectedProvisioner, setSelectedProvisioner] = useState<
    DisplayableValue<string>
  >({} as DisplayableValue<string>);

  const { closeModal } = useModalContext();
  const dispatch = useAppDispatch();
  const [errorState, setErrorState] = useState(NO_ERROR_STATE);

  const provisionerList = useAppSelector(
    (state) => state.admin.enabledProvisionerList
  );

  const onClickAddButton = () => {
    let hasError = false;

    if (!isValidNnid(nnidInputRef.current.value)) {
      hasError = true;
      setErrorState((currErrorState) => {
        return {
          ...currErrorState,
          nnidError: true,
          nnidErrorMessage: "You did not input a valid Nnid!",
        };
      });
    }

    if (!selectedProvisioner.value) {
      hasError = true;
      setErrorState((currErrorState) => {
        return {
          ...currErrorState,
          provisionerError: true,
          provisionerErrorMessage: "You did not select a provisioner!",
        };
      });
    }

    if (hasError) {
      return;
    } else {
      setErrorState(NO_ERROR_STATE);
    }

    let newNnidOwner: NnidOwner = {
      nnid: nnidInputRef.current.value,
      pid: selectedProvisioner.value,
      pidDisplayName: selectedProvisioner.display,
    };

    dispatch(addNnidOwnerMiddleware(newNnidOwner));
    closeModal();
  };

  const onClickNoButton = () => {
    closeModal();
  };

  return (
    <>
      <p>You have selected to create a NEW Nnid Owner relationship</p>
      <p>Please enter in the Nnid: </p>
      {errorState.nnidError && (
        <p className="input-error">{errorState.nnidErrorMessage}</p>
      )}
      <TextboxInput
        label="Nnid"
        sx={{ width: "100%" }}
        inputRef={nnidInputRef}
      />
      <p>
        Please select the enabled provisioner you'd like to associate them with
      </p>
      {errorState.provisionerError && (
        <p className="input-error">{errorState.provisionerErrorMessage}</p>
      )}
      <DropDown
        placeholder="Provisioner..."
        list={provisionerList === null ? [] : provisionerList}
        onClick={(provisioner: DisplayableValue<string>) => {
          setSelectedProvisioner(provisioner);
        }}
      />
      <div className="admin-config-modal-action-container">
        <Button onClick={onClickAddButton} variant="contained" color="success">
          Add
        </Button>
        <Button onClick={onClickNoButton} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

interface EditNnidOwnerModalInterface {
  nnidOwner: NnidOwner;
}

const EditNnidOwnerModalContent = ({
  nnidOwner,
}: EditNnidOwnerModalInterface) => {
  const [selectedProvisioner, setSelectedProvisioner] = useState<
    DisplayableValue<string>
  >({
    display: nnidOwner.pidDisplayName,
    value: nnidOwner.pid,
  } as DisplayableValue<string>);

  const { closeModal } = useModalContext();
  const dispatch = useAppDispatch();

  const provisionerList = useAppSelector(
    (state) => state.admin.enabledProvisionerList
  );

  const onClickEditButton = () => {
    let newNnidOwner: NnidOwner = {
      nnid: nnidOwner.nnid,
      pid: selectedProvisioner.value,
      pidDisplayName: selectedProvisioner.display,
    };

    dispatch(editNnidOwnerMiddleware(nnidOwner.nnid, newNnidOwner));
    closeModal();
  };

  const onClickNoButton = () => {
    closeModal();
  };

  return (
    <>
      <p>You have selected to EDIT the following Nnid Owner relationship</p>
      <TextboxInput
        label="Nnid"
        sx={{ width: "100%" }}
        initialValue={nnidOwner.nnid}
        disabled={true}
      />
      <p>
        Please select the enabled provisioner you'd like to associate them with
      </p>
      <DropDown
        placeholder="Provisioner..."
        list={provisionerList === null ? [] : provisionerList}
        initialValue={nnidOwner.pid}
        onClick={(provisioner: DisplayableValue<string>) => {
          setSelectedProvisioner(provisioner);
        }}
      />
      <div className="admin-config-modal-action-container">
        <Button onClick={onClickEditButton} variant="contained" color="warning">
          Edit
        </Button>
        <Button onClick={onClickNoButton} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

export default NnidOwnerComponent;
