import { NnidType } from "../model/store/nnidType";

export const mapNnidType = (nnidType: NnidType) => {
  let returned: string;

  switch (nnidType) {
    case "NP_LOA":
      returned = "Number Provider LOA";
      break;
    case "SUBSCRIBER_LOA":
      returned = "Subscriber LOA";
      break;
    case "TOLLFREE_LOA":
      returned = "Toll Free LOA";
      break;
  }
  return returned;
};
