import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DropDown from "../../components/forms/DropDown";
import TextboxInput from "../../components/forms/TextboxInput";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { FaInfoCircle } from "react-icons/fa";
import { useModalContext } from "../../context/ModalContext";
import { PortalConfigurations } from "../../store/adminSlice";
import { updatePortalConfigurationsMiddleware } from "../../middleware/adminMiddleware";

const Configurations = () => {
  const NO_ERROR_STATE = {
    maxLoaFileSizeError: false,
    maxLoaFileSizeErrorMessage: "",
    tnExpirationDaysError: false,
    tnExpirationDaysErrorMessage: "",
    maxTnsPerCaseError: false,
    maxTnsPerCaseErrorMessage: "",
    closeExpiringCasesError: false,
    closeExpiringCasesErrorMessage: "",
    osrConnectionBosOrChiError: false,
    osrConnectionBosOrChiErrorMessage: "",
  };

  const INIT_CONFIG_STATE = useAppSelector(
    (state) => state.admin.portalConfigurations
  );
  const [configState, setConfigState] = useState(INIT_CONFIG_STATE);
  const [errorState, setErrorState] = useState(NO_ERROR_STATE);
  const { displayModal } = useModalContext();

  const onClickSubmit = () => {
    setErrorState(NO_ERROR_STATE);
    let hasError = false;

    if (configState.NEW_CASE_EXPIRES_DAYS === 0) {
      hasError = true;
      setErrorState((currState) => {
        return {
          ...currState,
          tnExpirationDaysError: true,
          tnExpirationDaysErrorMessage: "Value cannot be 0!",
        };
      });
    }

    if (configState.NUM_TNS_ALLOWED === 0) {
      hasError = true;
      setErrorState((currState) => {
        return {
          ...currState,
          maxTnsPerCaseErrorError: true,
          maxTnsPerCaseErrorMessage: "Value cannot be 0!",
        };
      });
    }

    if (!hasError) {
      displayModal(
        <SubmitPortalConfigModalContent newConfigState={configState} />,
        "Change Portal Config",
        { width: "50%" },
        false
      );
    }
  };

  return (
    <>
      <Typography variant="h3" sx={{ mt: "2rem", mb: "2rem" }}>
        Portal Configurations
      </Typography>
      <Paper>
        <Grid container className="admin-config-grid-container">
          {errorState.tnExpirationDaysError && (
            <Grid className="input-error" item xs={12}>
              <Typography variant="h5">
                {errorState.tnExpirationDaysErrorMessage}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6} className="admin-config-grid-item">
            <Typography variant="h5">
              Tns Expiration Days
              <Tooltip
                title={
                  <>
                    <Typography>
                      Controls the amount of time given to a new case before it
                      expires, in days
                    </Typography>
                  </>
                }
              >
                <IconButton>
                  <FaInfoCircle />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ width: "100%" }}
            className="admin-config-grid-item"
          >
            <TextboxInput
              sx={{ width: "100%" }}
              variant="outlined"
              value={configState.NEW_CASE_EXPIRES_DAYS}
              onChange={(e) => {
                let int = Number.parseInt(e.target.value);

                if (Number.isNaN(int)) {
                  int = 0;
                }

                if (typeof int === "number") {
                  setConfigState({
                    ...configState,
                    NEW_CASE_EXPIRES_DAYS: int,
                  });
                }
              }}
            />
          </Grid>
          {errorState.maxTnsPerCaseError && (
            <Grid className="input-error" item xs={12}>
              <Typography variant="h5">
                {errorState.maxTnsPerCaseErrorMessage}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6} className="admin-config-grid-item">
            <Typography variant="h5">
              Max TNs Per Case
              <Tooltip
                title={
                  <>
                    <Typography>
                      The maximum allowed cases to be submitted at once during
                      new case flow
                    </Typography>
                  </>
                }
              >
                <IconButton>
                  <FaInfoCircle />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ width: "100%" }}
            className="admin-config-grid-item"
          >
            <TextboxInput
              sx={{ width: "100%" }}
              variant="outlined"
              value={configState.NUM_TNS_ALLOWED}
              onChange={(e) => {
                let int = Number.parseInt(e.target.value);

                if (Number.isNaN(int)) {
                  int = 0;
                }

                if (typeof int === "number") {
                  setConfigState({
                    ...configState,
                    NUM_TNS_ALLOWED: int,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={6} className="admin-config-grid-item">
            <Typography variant="h5">
              OSR Request Alarm Threshold
              <Tooltip
                title={
                  <>
                    <Typography>
                      The quantity of OSR requests that must fail in a half hour
                      to send an alarm to ops
                    </Typography>
                  </>
                }
              >
                <IconButton>
                  <FaInfoCircle />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ width: "100%" }}
            className="admin-config-grid-item"
          >
            <TextboxInput
              sx={{ width: "100%" }}
              variant="outlined"
              value={configState.HEALTHCHECK_ALARM_THRESHOLD}
              onChange={(e) => {
                let int = Number.parseInt(e.target.value);

                if (Number.isNaN(int)) {
                  int = 0;
                }

                if (typeof int === "number") {
                  setConfigState({
                    ...configState,
                    HEALTHCHECK_ALARM_THRESHOLD: int,
                  });
                }
              }}
            />
          </Grid>
          <Paper className="admin-config-grid-container admin-config-danger">
            <Typography variant="h3">Danger section!</Typography>
            <Grid container>
              {errorState.closeExpiringCasesError && (
                <Grid className="input-error" item xs={12}>
                  <Typography variant="h5">
                    {errorState.closeExpiringCasesErrorMessage}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6} className="admin-config-grid-item">
                <Typography variant="h5">
                  Close Expiring Cases
                  <Tooltip
                    title={
                      <>
                        <Typography>
                          If false, cases past their expiration date will NOT be
                          released in the nnSR
                        </Typography>
                      </>
                    }
                  >
                    <IconButton>
                      <FaInfoCircle />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ width: "100%" }}
                className="admin-config-grid-item"
              >
                <DropDown
                  sx={{ width: "100%", mt: 0 }}
                  placeholder=""
                  initialValue={configState.CLOSE_EXPIRED_CASES}
                  list={[
                    {
                      display: "Enabled",
                      value: true,
                    },
                    {
                      display: "Disabled",
                      value: false,
                    },
                  ]}
                  onClick={(e) => {
                    setConfigState({
                      ...configState,
                      CLOSE_EXPIRED_CASES: e.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              {errorState.closeExpiringCasesError && (
                <Grid className="input-error" item xs={12}>
                  <Typography variant="h5">
                    {errorState.closeExpiringCasesErrorMessage}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6} className="admin-config-grid-item">
                <Typography variant="h5">
                  OSR IP Address
                  <Tooltip
                    title={
                      <>
                        <Typography>
                          Allows you to control which address the NNSR Portal
                          will use in order to reach the NNSR, either via the
                          BOS Data Center or the CHI Data Center
                        </Typography>
                      </>
                    }
                  >
                    <IconButton>
                      <FaInfoCircle />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ width: "100%" }}
                className="admin-config-grid-item"
              >
                <DropDown
                  sx={{ width: "100%", mt: 0 }}
                  placeholder=""
                  initialValue={configState.OSR_CONNECTION_BOS_OR_CHI}
                  list={[
                    {
                      display: "Boston IP Address",
                      value: "BOS",
                    },
                    {
                      display: "Chicago IP Address",
                      value: "CHI",
                    },
                  ]}
                  onClick={(e) => {
                    setConfigState({
                      ...configState,
                      OSR_CONNECTION_BOS_OR_CHI: e.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
          <Grid item xs={12}>
            <div className="admin-config-submit-container">
              <Button
                onClick={onClickSubmit}
                variant="contained"
                color="warning"
              >
                Submit
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

interface SubmitPortalConfigInterface {
  newConfigState: PortalConfigurations;
}

const SubmitPortalConfigModalContent = ({
  newConfigState,
}: SubmitPortalConfigInterface) => {
  const oldConfigState = useAppSelector(
    (state) => state.admin.portalConfigurations
  );
  const dispatch = useAppDispatch();
  const { closeModal } = useModalContext();

  const boolToString = (bool: boolean) => {
    return bool ? "Enabled" : "Disabled";
  };

  const checkHasChanged = (key: keyof PortalConfigurations): boolean => {
    return oldConfigState[key] !== newConfigState[key];
  };

  const onClickSubmitButton = () => {
    dispatch(updatePortalConfigurationsMiddleware(newConfigState));
    closeModal();
  };

  const onClickNoButton = () => {
    closeModal();
  };

  return (
    <>
      <p>You have selected to change the following portal elements:</p>
      <ol>
        {checkHasChanged("NEW_CASE_EXPIRES_DAYS") && (
          <li>
            NEW_CASE_EXPIRES_DAYS:{" "}
            <span className="bold">
              {oldConfigState.NEW_CASE_EXPIRES_DAYS} {" --> "}
              {newConfigState.NEW_CASE_EXPIRES_DAYS}
            </span>
          </li>
        )}
        {checkHasChanged("NUM_TNS_ALLOWED") && (
          <li>
            Number TNs Allowed:{" "}
            <span className="bold">
              {oldConfigState.NUM_TNS_ALLOWED} {" --> "}
              {newConfigState.NUM_TNS_ALLOWED}
            </span>
          </li>
        )}
        {checkHasChanged("CLOSE_EXPIRED_CASES") && (
          <li>
            Close Expired Cases:{" "}
            <span className="bold">
              {boolToString(oldConfigState.CLOSE_EXPIRED_CASES)} {" --> "}
              {boolToString(newConfigState.CLOSE_EXPIRED_CASES)}
            </span>
          </li>
        )}
        {checkHasChanged("HEALTHCHECK_ALARM_THRESHOLD") && (
          <li>
            OSR Request Alarm Threshold:{" "}
            <span className="bold">
              {oldConfigState.HEALTHCHECK_ALARM_THRESHOLD}
              {" --> "}
              {newConfigState.HEALTHCHECK_ALARM_THRESHOLD}
            </span>
          </li>
        )}

        {checkHasChanged("OSR_CONNECTION_BOS_OR_CHI") && (
          <li>
            OSR Request Alarm Threshold:{" "}
            <span className="bold">
              {oldConfigState.OSR_CONNECTION_BOS_OR_CHI}
              {" --> "}
              {newConfigState.OSR_CONNECTION_BOS_OR_CHI}
            </span>
          </li>
        )}
      </ol>
      <p>
        These configurations will take up to 60 seconds to update on the backend
      </p>
      <div className="admin-config-modal-action-container">
        <Button onClick={onClickSubmitButton} variant="contained" color="error">
          Submit
        </Button>
        <Button onClick={onClickNoButton} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

export default Configurations;
