import { useModalContext } from "../../../context/ModalContext";
import { OpenCaseModalContent } from "./OpenCaseModalContent";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

/**
 * @param provisioner String. Provisioner ID
 * @param provisionerTns List of Strings. TNs associated with this provisioner
 * @param opened Boolean. Whether or not the user has already opened a case against the given provisioner
 * for these numbers on the page already.
 * @returns {Component}
 */
interface ErrorProvisionerGroupProps {
  provisioner: string;
  provisionerTns: string[];
  opened: boolean;
  caseId?: string;
}

const ErrorProvisionerGroup = ({
  provisioner,
  provisionerTns,
  opened,
  caseId,
}: ErrorProvisionerGroupProps) => {
  const { displayModal } = useModalContext();
  const navigate = useNavigate();

  const openCaseModal = (provisioner: string, tns: string[]): void => {
    const OpenNewCaseModal = (
      <OpenCaseModalContent provisioner={provisioner} tns={tns} />
    );
    displayModal(OpenNewCaseModal, "Open a New Case", { width: "50%" }, false);
  };

  return (
    <>
      <div className="results-prov-group">
        <div className="prov-name">{provisioner}</div>
        <div className="prov-tn-list">
          {provisionerTns.map((tn) => {
            return (
              <li className="tn" key={tn}>
                {tn}
              </li>
            );
          })}
        </div>
        <div className="open-case-button">
          {!opened ? (
            <Button
              variant="outlined"
              onClick={() => {
                if (!opened) {
                  openCaseModal(provisioner, provisionerTns);
                }
              }}
            >
              Open Case
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/case/${caseId}`);
              }}
            >
              Go to Case
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export { ErrorProvisionerGroup };
