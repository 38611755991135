import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useMemo, useRef } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import ActionButtons from "../../components/ui/ActionButtons";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { useState } from "react";
import logger from "../../utils/logger";
import { useModalContext } from "../../context/ModalContext";
import {
  addCurrentPidEmailMiddleware,
  editCurrentPidEmailMiddleware,
  removeCurrentPidEmailMiddleware,
} from "../../middleware/adminMiddleware";
import TextboxInput from "../../components/forms/TextboxInput";
import { isValidEmail } from "../../utils/genericUtils";
import { FaEdit, FaEye } from "react-icons/fa";
import EmailSetting, {
  EMAIL_SETTINGS_DEFAULTS,
} from "../../model/store/emailSetting";
import fieldToDisplayMappings from "../EmailSettings/fieldToDisplayMappings";
import currentStateHasChanged from "../EmailSettings/currentStateHasChanged";
import InfoToolTip from "../../components/ui/InfoToolTip";

const Emails = () => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "Email", flex: 5 },
    {
      field: "isHidden",
      headerName: "Is Hidden",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <FormControlLabel
            disabled
            control={<Checkbox checked={params.row.isHidden} size="small" />}
            label=""
          />
        );
      },
    },
    {
      field: "view",
      headerName: "View Settings",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton>
            <FaEye
              size="1.5rem"
              onClick={(e) => {
                e.stopPropagation();
                viewSettingsOnClick(params.row.id);
              }}
            />
          </IconButton>
        );
      },
    },
  ];

  const [selectedEmail, setSelectedEmail] = useState<GridRowSelectionModel>([]);
  const distributionEmails = useAppSelector(
    (state) => state.admin.currentPidEmails
  );
  const { displayModal } = useModalContext();
  const emailHasBeenSelected = selectedEmail.length > 0;

  const viewSettingsOnClick = (emailAddress: string) => {
    let emailSetting = distributionEmails.find(
      (emailSetting) => emailSetting.emailAddress === emailAddress
    );

    displayModal(
      <ViewEmailSettingsModal emailSetting={emailSetting!} />,
      "View Email Settings",
      { width: "50%" },
      true
    );
  };

  const removeOnClick = () => {
    let emailSetting = distributionEmails.find(
      (emailSetting) => emailSetting.emailAddress === selectedEmail[0]
    );

    if (emailSetting) {
      displayModal(
        <RemoveEmailModalContent emailSetting={emailSetting} />,
        "Remove Distribution Email",
        {},
        true
      );
    }
  };

  const editOnClick = () => {
    let emailSetting = distributionEmails.find(
      (emailSetting) => emailSetting.emailAddress === selectedEmail[0]
    );
    displayModal(
      <NewOrEditEmailModalContent
        emailSetting={emailSetting!}
        isEdit={true}
        originalEditedEmailAddress={
          selectedEmail.length > 0 ? (selectedEmail[0] as string) : undefined
        }
      />,
      "Edit Distribution Email",
      {},
      true
    );
  };

  const newOnClick = () => {
    displayModal(
      <NewOrEditEmailModalContent />,
      "New Distribution Email",
      {},
      true
    );
  };

  const distributionEmailsRows = useMemo(() => {
    return distributionEmails.map((disEmail, ind) => {
      return {
        id: disEmail.emailAddress,
        isHidden: disEmail.hiddenFromProvisioner,
        view: null,
      };
    });
  }, [distributionEmails]);

  // event is the new state, will all selected rows,
  // however we want to enforce only 1 being selected at a time.
  // So, we pop the last "id" or disEmail, and then set it as selectedEmail
  const onRowClick = (event: GridRowSelectionModel) => {
    let newSelectedId = [] as GridRowSelectionModel;
    if (event.length > 0) {
      newSelectedId = [event.pop()] as GridRowSelectionModel;
    } else {
      newSelectedId = [];
    }
    setSelectedEmail(newSelectedId);
  };

  return (
    <>
      <Typography variant="h3" sx={{ mt: "2rem", mb: "2rem" }}>
        Emails
        <InfoToolTip>
          <Typography>
            The different Distribution emails associated with this provisioner.
            These email addresses will recieve any email notification they have
            enabled in their settings.
            <br />
            <br />A Hidden email is an email that the provisioner does NOT see
            or have access to, but will still receive emails, mostly used for
            Admin emails
          </Typography>
        </InfoToolTip>
      </Typography>
      <ActionButtons
        removeEditable={!emailHasBeenSelected}
        editEditable={!emailHasBeenSelected}
        removeOnClick={removeOnClick}
        editOnClick={editOnClick}
        newOnClick={newOnClick}
      />
      <Paper className="provisioner-info-data-container">
        <DataGrid
          rows={distributionEmailsRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          onRowSelectionModelChange={onRowClick}
          rowSelectionModel={selectedEmail}
        />
      </Paper>
    </>
  );
};

interface RemoveEmailModalContentInterface {
  emailSetting: EmailSetting;
}

const RemoveEmailModalContent = ({
  emailSetting,
}: RemoveEmailModalContentInterface) => {
  let provisionerCommonName = useAppSelector(
    (state) => state.admin.currentPid.display
  );
  let { closeModal } = useModalContext();
  const dispatch = useAppDispatch();

  const onClickNoButton = () => {
    closeModal();
  };

  const onClickRemoveButton = () => {
    dispatch(removeCurrentPidEmailMiddleware(emailSetting));
    closeModal();
  };

  return (
    <>
      <p>
        Are you sure you'd like to remove the following email from{" "}
        {provisionerCommonName}'s email distribution list?
      </p>
      <p>
        <ul>
          <li>
            <span className="bold">{emailSetting.emailAddress}</span>
          </li>
        </ul>
      </p>
      <div className="prov-info-modal-action-container">
        <Button onClick={onClickRemoveButton} variant="contained" color="error">
          Remove
        </Button>
        <Button onClick={onClickNoButton} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

interface NewOrEditEmailModalContentInterface {
  emailSetting?: EmailSetting;
  isEdit?: boolean;
  originalEditedEmailAddress?: string;
}

const NewOrEditEmailModalContent = ({
  emailSetting = EMAIL_SETTINGS_DEFAULTS,
  isEdit = false,
  originalEditedEmailAddress = "",
}: NewOrEditEmailModalContentInterface) => {
  const NO_ERROR_STATE = {
    isEmailError: false,
    emailErrorMessage: "",
  };

  let provisionerCommonName = useAppSelector(
    (state) => state.admin.currentPid.display
  );
  let otherProvisionerEmails = useAppSelector(
    (state) => state.admin.currentPidEmails
  );

  let { closeModal } = useModalContext();
  const dispatch = useAppDispatch();
  const [errorState, setErrorState] =
    useState<typeof NO_ERROR_STATE>(NO_ERROR_STATE);
  const [panelState, setPanelState] = useState(emailSetting);

  const onClickNoButton = () => {
    closeModal();
  };

  const onClickAddButton = () => {
    let isError = false;

    let index = otherProvisionerEmails.findIndex(
      (currEmail) => currEmail.emailAddress === panelState.emailAddress
    );

    if (
      index > -1 &&
      (!isEdit || panelState.emailAddress !== originalEditedEmailAddress)
    ) {
      isError = true;
      setErrorState({
        isEmailError: true,
        emailErrorMessage:
          "That email has already been included on their Distribution list!",
      });
    }

    if (isEdit && currentStateHasChanged(emailSetting, panelState)) {
      isError = true;
      setErrorState({
        isEmailError: true,
        emailErrorMessage: "You have not edited the email!",
      });
    }
    if (!isValidEmail(panelState.emailAddress)) {
      isError = true;
      setErrorState({
        isEmailError: true,
        emailErrorMessage: "The string you entered is not a valid email!",
      });
    }

    if (isError) {
      return;
    }

    setErrorState(NO_ERROR_STATE);
    if (isEdit) {
      dispatch(editCurrentPidEmailMiddleware(emailSetting, panelState));
    } else {
      dispatch(addCurrentPidEmailMiddleware(panelState));
    }
    closeModal();
  };

  const onClickCheckBox = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPanelState({
      ...panelState,
      //@ts-ignore
      [event.target.id!]: event.target.checked,
    });
  };

  return (
    <>
      <p>
        Please enter in a new valid email to be added to {provisionerCommonName}
        's email distribution list
      </p>
      <div style={{ width: "100%" }}>
        {errorState.isEmailError && (
          <p className="input-error">{errorState.emailErrorMessage}</p>
        )}
        <TextboxInput
          sx={{ width: "100%", mt: "1rem" }}
          value={panelState.emailAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPanelState({ ...panelState, emailAddress: e.target.value })
          }
          error={errorState.isEmailError}
          variant="outlined"
          label="New Email"
        />
        <FormControlLabel
          sx={{ mt: 2 }}
          control={<Switch checked={panelState.hiddenFromProvisioner} />}
          label="Is hidden from Provisioner?"
          onClick={() =>
            setPanelState({
              ...panelState,
              hiddenFromProvisioner: !panelState.hiddenFromProvisioner,
            })
          }
        />
        <ViewableEmailSetting
          emailSetting={panelState}
          isDisabled={false}
          onClickCheckBox={onClickCheckBox}
        />
      </div>
      <div className="prov-info-modal-action-container">
        <Button
          onClick={onClickAddButton}
          variant="contained"
          color={isEdit ? "warning" : "success"}
        >
          {isEdit ? "Edit" : "Add"}
        </Button>
        <Button onClick={onClickNoButton} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

interface ViewEmailSettingsModalProps {
  emailSetting: EmailSetting;
}

const ViewEmailSettingsModal = ({
  emailSetting,
}: ViewEmailSettingsModalProps) => {
  let provisionerCommonName = useAppSelector(
    (state) => state.admin.currentPid.display
  );

  return (
    <>
      <p>
        Below are the email settings set by the {provisionerCommonName} for the
        email address:{" "}
        <ol>
          <li className="bold">{emailSetting.emailAddress}</li>
        </ol>
      </p>
      <div className="dashed-line"></div>
      <p className="bold">Email Settings:</p>
      <ViewableEmailSetting emailSetting={emailSetting} />
    </>
  );
};

interface ViewableEmailSettingProps {
  emailSetting: EmailSetting;
  isDisabled?: boolean;
  onClickCheckBox?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ViewableEmailSetting = ({
  emailSetting,
  isDisabled = true,
  onClickCheckBox,
}: ViewableEmailSettingProps) => {
  return (
    <div className="email-settings-panel-body-container">
      <div className="email-settings-option-container">
        <Checkbox
          size="small"
          id={"receiveCaseOpenedEmails"}
          disabled={isDisabled}
          checked={emailSetting.receiveCaseOpenedEmails}
          onClick={onClickCheckBox}
        />
        <span>{fieldToDisplayMappings.receiveCaseOpenedEmails}</span>
      </div>
      <div className="email-settings-option-container">
        <Checkbox
          size="small"
          id={"receiveCaseClosedEmails"}
          disabled={isDisabled}
          checked={emailSetting.receiveCaseClosedEmails}
          onClick={onClickCheckBox}
        />
        <span>{fieldToDisplayMappings.receiveCaseClosedEmails}</span>
      </div>
      <div className="email-settings-option-container">
        <Checkbox
          size="small"
          id={"receiveOneDayReminderEmails"}
          disabled={isDisabled}
          checked={emailSetting.receiveOneDayReminderEmails}
          onClick={onClickCheckBox}
        />
        <span>{fieldToDisplayMappings.receiveOneDayReminderEmails}</span>
      </div>
      <div className="email-settings-option-container">
        <Checkbox
          size="small"
          id={"receiveOneHourReminderEmails"}
          disabled={isDisabled}
          checked={emailSetting.receiveOneHourReminderEmails}
          onClick={onClickCheckBox}
        />
        <span>{fieldToDisplayMappings.receiveOneHourReminderEmails}</span>
      </div>
      <div className="email-settings-option-container">
        <Checkbox
          size="small"
          id={"receiveTnsAcceptedEmails"}
          disabled={isDisabled}
          checked={emailSetting.receiveTnsAcceptedEmails}
          onClick={onClickCheckBox}
        />
        <span>{fieldToDisplayMappings.receiveTnsAcceptedEmails}</span>
      </div>
      <div className="email-settings-option-container">
        <Checkbox
          size="small"
          id={"receiveTnsRejectedEmails"}
          disabled={isDisabled}
          checked={emailSetting.receiveTnsRejectedEmails}
          onClick={onClickCheckBox}
        />
        <span>{fieldToDisplayMappings.receiveTnsRejectedEmails}</span>
      </div>
      <div className="email-settings-option-container">
        <Checkbox
          size="small"
          id={"receiveCommentEmails"}
          disabled={isDisabled}
          checked={emailSetting.receiveCommentEmails}
          onClick={onClickCheckBox}
        />
        <span>{fieldToDisplayMappings.receiveCommentEmails}</span>
      </div>
      <div className="email-settings-option-container">
        <Checkbox
          size="small"
          id={"receiveCaseExpiredInitiatingPartyEmails"}
          disabled={isDisabled}
          checked={emailSetting.receiveCaseExpiredInitiatingPartyEmails}
          onClick={onClickCheckBox}
        />
        <span>
          {fieldToDisplayMappings.receiveCaseExpiredInitiatingPartyEmails}
        </span>
      </div>
      <div className="email-settings-option-container">
        <Checkbox
          size="small"
          id={"receiveCaseExpiredReceivingPartyEmails"}
          disabled={isDisabled}
          checked={emailSetting.receiveCaseExpiredReceivingPartyEmails}
          onClick={onClickCheckBox}
        />
        <span>
          {fieldToDisplayMappings.receiveCaseExpiredReceivingPartyEmails}
        </span>
      </div>
    </div>
  );
};

export default Emails;
