import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { DisplayableValue } from "../../model/store/displayableValue";

interface RadioButtonGroupProps {
  list: {
    display: string;
    value: any;
    children?: React.ReactNode;
  }[];
  title?: string;
  onClick: (value: any) => void;
  initialValue: any;
}

export default function RadioButtonGroup({
  list,
  title,
  onClick,
  initialValue,
}: RadioButtonGroupProps) {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onClick(e.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      {title && title !== "" && (
        <FormLabel id="demo-radio-buttons-group-label">{title}</FormLabel>
      )}
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={list[0].value}
        name="radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {list.map((listItem) => {
          return (
            <>
              <FormControlLabel
                value={listItem.value}
                control={<Radio />}
                label={listItem.display}
              ></FormControlLabel>
              {listItem.children}
            </>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
