import { AxiosResponse } from "axios";
import axios, { getAuthToken } from "./axios";
import {
  BASE_URL,
  GET_CASES_ENDPOINT_ADMIN,
  GET_CASE_ENDPOINT_ADMIN,
  INITIAL_SYNC_ENDPOINT_ADMIN,
  GET_NOTIFICATIONS_ENDPOINT_ADMIN,
  INITIAL_SYNC_AS_PROVISIONER_ENDPOINT_ADMIN,
  DOWNLOAD_LOA_ENDPOINT_ADMIN,
  ADD_CURRENT_PID_DIST_EMAIL_ENDPOINT_ADMIN,
  EDIT_CURRENT_PID_DIST_EMAIL_ENDPOINT_ADMIN,
  REMOVE_CURRENT_PID_DIST_EMAIL_ENDPOINT_ADMIN,
  EDIT_CURRENT_PID_USER_ENDPOINT_ADMIN,
  ADD_CURRENT_PID_NNID_ENDPOINT_ADMIN,
  EDIT_CURRENT_PID_NNID_ENDPOINT_ADMIN,
  REMOVE_CURRENT_PID_NNID_ENDPOINT_ADMIN,
  ENABLE_PROVISIONER_PORTAL_ENDPOINT_ADMIN,
  DISABLE_PROVISIONER_PORTAL_ENDPOINT_ADMIN,
  UPDATE_CONFIGURATIONS_ENDPOINT_ADMIN,
  OSR_GET_QUERY_FOR_TN_ENDPOINT_ADMIN,
  ADD_NNID_OWNER_ENDPOINT_ADMIN,
  REMOVE_NNID_OWNER_ENDPOINT_ADMIN,
  EDIT_NNID_OWNER_ENDPOINT_ADMIN,
} from "./serviceConfiguration";
import AdminLoginAsProvisionerOutputDTO from "../model/output/adminLoginAsProvisionerOutputDTO";
import { AxiosPromise } from "./axiosPromise";
import SingularCase from "../model/store/singularCase";
import AdminInitialSyncOutputDTO from "../model/output/adminInitialSyncOutputDTO";
import Notification from "../model/store/notification";
import GetNotificationsOutputDTO from "../model/output/getNotificationsOutputDTO";
import SuccessFailureOutputDTO from "../model/output/successFailureOutputDTO";
import addCurrentPidEmailInputDTO from "../model/input/addCurrentPidEmailInputDTO";
import editCurrentPidEmailInputDTO from "../model/input/editCurrentPidEmailInputDTO";
import removeCurrentPidEmailInputDTO from "../model/input/removeCurrentPidEmailInputDTO";
import EditCurrentPidUserInputDTO from "../model/input/editCurrentPidUserInputDTO";
import AddCurrentPidNnidInputDTO from "../model/input/addCurrentPidNnidInputDTO";
import EditCurrentPidNnidInputDTO from "../model/input/editCurrentPidNnidInputDTO";
import RemoveCurrentPidNnidInputDTO from "../model/input/removeCurrentPidNnidInputDTO";
import UpdateConfigurationsInputDTO from "../model/input/UpdateConfigurationsInputDTO";
import EnableProvisionerPortalInputDTO from "../model/input/EnableProvisionerPortalInputDTO";
import DisableProvisionerPortalInputDTO from "../model/input/DisableProvisionerPortalInput";
import QueryTnOutputDTO from "../model/output/queryTnOutputDTO";
import RemoveNnidOwnerInputDTO from "../model/input/removeNnidOwnerInputDTO";
import AddNnidOwnerInputDTO from "../model/input/addNnidOwnerInputDTO";
import EditNnidOwnerInputDTO from "../model/input/editNnidOwnerInputDTO";

export function fetchCasesAxiosAdmin(
  pid: string
): AxiosPromise<AdminLoginAsProvisionerOutputDTO> {
  return axios.get(
    BASE_URL + GET_CASES_ENDPOINT_ADMIN + `/${pid}`,
    getAuthToken()
  );
}

export function fetchCaseAxiosAdmin(
  pid: string,
  caseId: string
): AxiosPromise<SingularCase> {
  return axios.get(
    BASE_URL + GET_CASE_ENDPOINT_ADMIN + `/${pid}` + `/${caseId}`
  );
}

export function initialSyncAxiosAdmin(): AxiosPromise<AdminInitialSyncOutputDTO> {
  return axios.get(BASE_URL + INITIAL_SYNC_ENDPOINT_ADMIN, getAuthToken());
}

export function initialSyncAsProvisionerAdmin(
  pid: string
): AxiosPromise<AdminLoginAsProvisionerOutputDTO> {
  return axios.get(
    BASE_URL + INITIAL_SYNC_AS_PROVISIONER_ENDPOINT_ADMIN + `/${pid}`,
    getAuthToken()
  );
}

export function getNotificationsAdmin(
  pid: string
): AxiosPromise<GetNotificationsOutputDTO> {
  return axios.get(
    BASE_URL + GET_NOTIFICATIONS_ENDPOINT_ADMIN + `/${pid}`,
    getAuthToken()
  );
}

export function downloadFileAxiosAdmin(
  pid: string,
  caseId: string,
  fileName: string
) {
  return axios.get(
    BASE_URL + DOWNLOAD_LOA_ENDPOINT_ADMIN + `${pid}/${caseId}/${fileName}`,
    {
      responseType: "blob",
      ...getAuthToken(),
    }
  );
}

export function addCurrentPidEmailAxios(
  input: addCurrentPidEmailInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + ADD_CURRENT_PID_DIST_EMAIL_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function editCurrentPidEmailAxios(
  input: editCurrentPidEmailInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + EDIT_CURRENT_PID_DIST_EMAIL_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function removeCurrentPidEmailAxios(
  input: removeCurrentPidEmailInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + REMOVE_CURRENT_PID_DIST_EMAIL_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function editCurrentPidUserAxios(
  input: EditCurrentPidUserInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + EDIT_CURRENT_PID_USER_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function addCurrentPidNnidAxios(
  input: AddCurrentPidNnidInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + ADD_CURRENT_PID_NNID_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function editCurrentPidNnidAxios(
  input: EditCurrentPidNnidInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + EDIT_CURRENT_PID_NNID_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function removeCurrentPidNnidAxios(
  input: RemoveCurrentPidNnidInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + REMOVE_CURRENT_PID_NNID_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function enableProvisionerPortalAxios(
  input: EnableProvisionerPortalInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + ENABLE_PROVISIONER_PORTAL_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function disableProvisionerPortalAxios(
  input: DisableProvisionerPortalInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + DISABLE_PROVISIONER_PORTAL_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function updatePortalConfigurationsAxios(
  input: UpdateConfigurationsInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + UPDATE_CONFIGURATIONS_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function osrGetQueryForTnAdminAxios(
  tn: string
): AxiosPromise<QueryTnOutputDTO> {
  return axios.get(
    BASE_URL + OSR_GET_QUERY_FOR_TN_ENDPOINT_ADMIN + `/${tn}`,
    getAuthToken()
  );
}

export function removeNnidOwnerAxios(
  input: RemoveNnidOwnerInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + REMOVE_NNID_OWNER_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function editNnidOwnerAxios(
  input: EditNnidOwnerInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + EDIT_NNID_OWNER_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}

export function addNnidOwnerAxios(
  input: AddNnidOwnerInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + ADD_NNID_OWNER_ENDPOINT_ADMIN,
    input,
    getAuthToken()
  );
}
