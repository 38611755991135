import { PayloadAction } from "@reduxjs/toolkit";
import { CaseStatus } from "../model/store/caseStatus";
import Activity from "../model/store/comment";
import Loa from "../model/store/loa";
import Tn from "../model/store/tn";
import { TnResult } from "../model/store/tnResult";
import SingularCase from "../model/store/singularCase";
import { ConflictCaseState } from "../store/conflictCasesSlice";
import Filter from "../model/store/filter";
import NewCaseResults from "../model/store/newCaseResults";
import Nnid from "../model/store/nnid";

/**
 *
 * @param {Object} action.payload
 * {
 *    caseId: [str] caseId UUID
 *    tns: [list str] string list of tns being updated
 *    approvedOrRejected: [bool] true if tns are being accepted, false if rejected
 * }
 */

function findCase(
  caseId: string,
  caseList: SingularCase[]
): SingularCase | undefined {
  let index = caseList.findIndex((singularCase) => {
    return singularCase.caseId === caseId;
  });

  if (index !== -1) {
    return caseList[index];
  }
}

interface RespondTnsSubmitInput {
  caseId: string;
  tns: string[];
  acceptOrReject: boolean;
  newSystemCommentText: string;
  newSystemCommentTs: number;
}
export function respondTns(
  state: ConflictCaseState,
  action: PayloadAction<RespondTnsSubmitInput>
) {
  let {
    caseId,
    tns,
    acceptOrReject,
    newSystemCommentText,
    newSystemCommentTs,
  } = action.payload;

  const currentCase = findCase(caseId, state.caseList);

  if (currentCase !== undefined) {
    type TnIndexMapping = { [key: string]: number };

    let numberIndexMapping: TnIndexMapping = {};

    tns.forEach((updatedTn) => {
      let index = currentCase.tns.findIndex(
        (tnObj: Tn) => tnObj.tn === updatedTn
      );
      if (index !== -1) {
        numberIndexMapping[updatedTn] = index;
      }
    });

    (Object.keys(numberIndexMapping) as Array<keyof TnIndexMapping>).forEach(
      (tn) => {
        currentCase.tns[numberIndexMapping[tn]] = {
          //Have to ignore because of this: https://www.typescriptlang.org/docs/handbook/2/keyof-types.html
          //@ts-ignore
          tn: tn,
          responded: true,
          result: acceptOrReject ? "ACCEPTED" : "REJECTED",
        };
      }
    );

    let countResponded = 0;
    currentCase.tns.forEach((tnObj: Tn) => {
      if (tnObj.responded) {
        countResponded++;
      }
    });

    currentCase.activity.push({
      commentBy: "Case Information",
      commentPid: "system",
      commentTs: newSystemCommentTs,
      commentText: newSystemCommentText,
      systemComment: true,
    } as Activity);

    if (countResponded === currentCase.tns.length) {
      currentCase.status = CaseStatus.CLOSED;
    } else {
      currentCase.status = CaseStatus.IN_PROGRESS;
    }
  }
}

/**
 *
 * @param {Object} action.payload
 * {
 *    caseId: [str] caseId UUID
 *    comment: [obj] the comment object in the following form:
 *    {
 *        commentBy: string (displayName),
          commentPid: string (pid),
          commentTs: int,
          systemComment: false,
          commentText: string (comment text),
 *    } 
 *
 * }
 */

interface SubmitCommentInput {
  caseId: string;
  comment: Activity;
}
export function submitComment(
  state: ConflictCaseState,
  action: PayloadAction<SubmitCommentInput>
) {
  let { caseId, comment } = action.payload;

  let currentCase = findCase(caseId, state.caseList);

  if (currentCase !== undefined) {
    if (!currentCase.activity) {
      currentCase.activity = [];
    }
    currentCase.activity.push(comment);
  }
}

export function closeUnfinishedCase(
  state: ConflictCaseState,
  action: PayloadAction<string>
) {
  let caseId = action.payload;

  let currentCase = findCase(caseId, state.caseList);

  if (currentCase !== undefined) {
    currentCase.status = CaseStatus.CLOSED;
  }
}

/**
 *
 * @param {Object} action.payload
 * {
 *    openedBy: [str] your pid
 *    openedAgainst: [str] pid to be opened against
 *    tns: [list str] tns for the case
 *    loas: [list str] filenames of the loas for the case
 * }
 */
interface CreateNewCaseInput {
  openedBy: string;
  openedAgainst: string;
  tns: string[];
  loas: string[];
  caseId: string;
  openedTs: number;
  expiresTs: number;
}
export function createNewCase(
  state: ConflictCaseState,
  action: PayloadAction<CreateNewCaseInput>
) {
  let { openedBy, openedAgainst, tns, loas, caseId, openedTs, expiresTs } =
    action.payload;

  state.caseList = [
    {
      caseId,
      openedBy: openedBy,
      openedAgainst: openedAgainst,
      openedTs: openedTs,
      numberTns: tns.length,
      expiresTs: expiresTs,
      openedByUser: "",
      status: CaseStatus.OPENED,
      tns: tns.map((tn) => {
        return {
          tn,
          responded: false,
          approved: false,
          result: null,
          resultTs: 0,
        };
      }),
      loas: loas.map((loa) => {
        return {
          fileName: loa,
          fileId: "dog1.jpg",
        };
      }),
      activity: [],
      nnidToProvisionTo: state.newCaseNnid!.value,
      nnidType: state.newCaseNnid!.type,
    },
    ...state.caseList,
  ];
}

export function addOrEditFilter(
  state: ConflictCaseState,
  action: PayloadAction<Filter>
) {
  let filterIndex = state.filters.findIndex((filter) => {
    return filter.id === action.payload.id;
  });

  if (filterIndex === -1) {
    state.filters.push(action.payload);
  } else {
    state.filters[filterIndex] = action.payload;
  }
}

export function removeFilter(
  state: ConflictCaseState,
  action: PayloadAction<number>
) {
  let id = action.payload;

  state.filters = state.filters.filter((filter) => filter.id !== id);
}

export function setIsLoading(
  state: ConflictCaseState,
  action: PayloadAction<boolean>
) {
  state.isLoading = action.payload;
}
export function setCaseList(
  state: ConflictCaseState,
  action: PayloadAction<SingularCase[]>
) {
  let caseList = action.payload;
  state.caseList = caseList;
}

export function setCase(
  state: ConflictCaseState,
  action: PayloadAction<SingularCase>
) {
  let caseId = action.payload.caseId;
  let caseIndex = state.caseList.findIndex(
    (singularCase) => singularCase.caseId === caseId
  );

  if (!state.caseList[caseIndex]) {
    state.caseList.push(action.payload);
  } else {
    state.caseList[caseIndex] = action.payload;
  }
}
export function setNewCaseResultsData(
  state: ConflictCaseState,
  action: PayloadAction<NewCaseResults>
) {
  state.newCaseResultsData = action.payload;
}
export function setNewCaseAsOpened(
  state: ConflictCaseState,
  action: PayloadAction<{ pid: string; caseId: string }>
) {
  const { pid, caseId } = action.payload;
  state.newCaseResultsData.error[pid].opened = true;
  state.newCaseResultsData.error[pid].caseId = caseId;
}
export function setNewCaseCurrentPage(
  state: ConflictCaseState,
  action: PayloadAction<number>
) {
  state.newCaseCurrentPage = action.payload;
}
export function setNnidList(
  state: ConflictCaseState,
  action: PayloadAction<Nnid[]>
) {
  state.nnidList = action.payload;
}

export function setNewCaseNnid(
  state: ConflictCaseState,
  action: PayloadAction<Nnid>
) {
  state.newCaseNnid = action.payload;
}
