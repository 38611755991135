import { configureStore } from "@reduxjs/toolkit";
import { conflictCaseSlice, ConflictCaseState } from "./conflictCasesSlice";
import { notificationSlice, NotificationState } from "./notificationsSlice";
import { authSlice, AuthState } from "./authSlice";
import thunkMiddleware from "redux-thunk";
import { adminSlice, AdminState } from "./adminSlice";
import { errorSlice, ErrorState } from "./errorSlice";
import caseFilterSlice from "./caseFilterSlice";
import { CaseFilterState } from "./caseFilterSlice";
import { queryTnSlice, QueryTnState } from "./queryTnSlice";
import { documentationSlice, DocumentationState } from "./documentationSlice";
import { emailSettingSlice, EmailSettingState } from "./emailSettingsSlice";

export interface AppState {
  admin: AdminState;
  conflictCase: ConflictCaseState;
  error: ErrorState;
  notification: NotificationState;
  auth: AuthState;
  caseFilter: CaseFilterState;
  queryTn: QueryTnState;
  documentation: DocumentationState;
  emailSetting: EmailSettingState;
}

const store = configureStore({
  reducer: {
    notification: notificationSlice.reducer,
    conflictCase: conflictCaseSlice.reducer,
    auth: authSlice.reducer,
    admin: adminSlice.reducer,
    error: errorSlice.reducer,
    caseFilter: caseFilterSlice.reducer,
    queryTn: queryTnSlice.reducer,
    documentation: documentationSlice.reducer,
    emailSetting: emailSettingSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(thunkMiddleware),
});

//export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export default store;
