import {
  Box,
  Button,
  Fade,
  IconButton,
  Popover,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import TextboxInput from "../../components/forms/TextboxInput";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";
import { osrGetQueryForTnMiddleware } from "../../middleware/conflictCaseMiddleware";
import { setOsrTnQueryState } from "../../reducer/queryTnReducers";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { queryTnActions } from "../../store/queryTnSlice";
import { isValidTn } from "../../utils/genericUtils";

const NO_ERROR_STATE = {
  tnError: false,
  tnErrorMessage: "",
};

const QueryTn = () => {
  const [errorState, setErrorState] = useState(NO_ERROR_STATE);
  const inputTnRef = useRef<HTMLInputElement>(null!);
  const dispatch = useAppDispatch();
  const outputPid = useAppSelector((state) => state.queryTn.outputPid);
  const outputNnid = useAppSelector((state) => state.queryTn.outputNnid);
  const inputTn = useAppSelector((state) => state.queryTn.inputTn);

  const handleSubmit = () => {
    let val = inputTnRef.current.value;
    if (isValidTn(val)) {
      setErrorState(NO_ERROR_STATE);
      dispatch(osrGetQueryForTnMiddleware(val));
    } else {
      setErrorState({
        ...errorState,
        tnError: true,
        tnErrorMessage: "You did not enter a valid Tn!",
      });
    }
  };

  return (
    <div className="query-tn-page">
      <div className="query-tn-page-header">
        <Typography variant="h3">Query Telephone Number</Typography>
      </div>
      <PopperInfo />

      <div className="line" />
      <div className="query-tn-input-container">
        {errorState.tnError === true && (
          <div className="input-error" style={{ marginBottom: "1rem" }}>
            {errorState.tnErrorMessage}
          </div>
        )}
        <div className="query-tn-input">
          <Typography id="query-tn-input-input-text" variant="h4">
            Input
          </Typography>
          <div id="query-tn-input-input-container">
            <TextboxInput
              inputRef={inputTnRef}
              variant="filled"
              size="medium"
              label="Input TN"
              sx={{ width: "100%" }}
              error={errorState.tnError}
            ></TextboxInput>
          </div>
          <Button
            id="query-tn-input-submit-button"
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      <div className="line" />
      <div className="query-tn-output-container">
        <div className="query-tn-output">
          <div className="tbd">
            <Typography id="query-tn-output-output-text" variant="h4">
              Output
            </Typography>
            <Button
              onClick={() => {
                dispatch(queryTnActions.clearOsrTnQueryState());
              }}
              variant="outlined"
              color="warning"
            >
              Clear Output
            </Button>
          </div>
          <div className="query-tn-output-results-container">
            <div className="query-tn-output-row">
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                TN:
              </Typography>
              <Typography className="nnid-pid-output" variant="h5">
                {inputTn}
              </Typography>
            </div>
            <div className="dashed-line"></div>
            <div className="query-tn-output-row">
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                PID:
              </Typography>
              <Typography className="nnid-pid-output" variant="h5">
                {outputNnid === "Not Provisioned" && (
                  <Tooltip title="This TN is not currently provisioned in the nnSR to any provisioner ID">
                    <IconButton size="small">
                      <FaInfoCircle size={"24px"} />
                    </IconButton>
                  </Tooltip>
                )}
                {outputPid}
              </Typography>
            </div>
            <div className="dashed-line"></div>

            <div className="query-tn-output-row">
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                NNID:
              </Typography>
              <Typography className="nnid-pid-output" variant="h5">
                {outputNnid === "Not Provisioned" && (
                  <Tooltip title="This TN is not currently provisioned in the nnSR to any NNID">
                    <IconButton size="small">
                      <FaInfoCircle size={"24px"} />
                    </IconButton>
                  </Tooltip>
                )}
                {outputNnid}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PopperInfo = () => {
  return (
    <Box className="query-tn-info-top">
      <FaInfoCircle size={"40px"} style={{ marginRight: "1rem" }} />
      <div>
        <Typography variant="body1">
          This page allows you to easily check the current Provisioner ID and
          NNID of a TN inside of the nnSR.
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>
          NOTE: This page will utilize your Provisioner ID's allotted "nnSR Get"
          API. This API has a limit of 60 requests per hour.
        </Typography>
      </div>
    </Box>
  );
};

export default QueryTn;
