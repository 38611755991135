import { AxiosPromise } from "axios";
import UpdateEmailConfigurationInputDTO from "../model/input/UpdateEmailConfigurationInputDTO";
import SuccessFailureOutputDTO from "../model/output/successFailureOutputDTO";
import { getAuthToken } from "./axios";
import {
  BASE_URL,
  UPDATE_CONFIGURATIONS_ENDPOINT_ADMIN,
  UPDATE_EMAIL_SETTING_ENDPOINT,
} from "./serviceConfiguration";
import axios from "./axios";

export function updateEmailSettingAxios(
  input: UpdateEmailConfigurationInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + UPDATE_EMAIL_SETTING_ENDPOINT,
    input,
    getAuthToken()
  );
}
