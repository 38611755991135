import {
  fetchCaseAxios,
  fetchCasesAxios,
  postCreateCaseAxios,
  postAcceptTnsAxios,
  postRejectTnsAxiosWithAdditionalContext,
  postRejectTnsAxiosWithoutAdditionalContext,
  postSubmitCommentAxios,
  postAttemptProvisionTnsAxios,
  fetchNnidList,
  initialSyncAxios,
  closeUnfinishedCaseAxios,
  osrGetQueryForTnAxios,
} from "../service/conflictCaseService";
import { organizeResults } from "../utils/caseUtils";
import { conflictCaseActions } from "../store/conflictCasesSlice";
import { notificationActions } from "../store/notificationsSlice";
import { authActions } from "../store/authSlice";
import { handleRequestError } from "./middlewareUtils";
import logger from "../utils/logger";
import { MiddlewareType } from "./middlewareType";
import { NnidType } from "../model/store/nnidType";
import { AxiosPromise } from "axios";
import RejectTnsOutputDTO from "../model/output/rejectTnsOutputDTO";
import LoaDTO from "../model/input/loaDTO";
import SingularCase from "../model/store/singularCase";
import { osrGetQueryForTnAdminAxios } from "../service/adminService";
import { queryTnActions } from "../store/queryTnSlice";
import { documentationActions } from "../store/documentationSlice";
import { emailSettingActions } from "../store/emailSettingsSlice";
import EmailSetting from "../model/store/emailSetting";

function fetchCasesMiddleware(): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("fetchCasesMiddleware invoked");
    if (!getState().conflictCase.isLoading && getState().auth.pid !== "") {
      // Fixed bug that something was calling this very quickly
      logger.log("inside of fetchCases");
      if (getState().conflictCase.caseList.length === 0) {
        dispatch(conflictCaseActions.setIsLoading(true));
      }

      fetchCasesAxios()
        .then((res) => {
          dispatch(conflictCaseActions.setCaseList(res.data.cases));
          dispatch(conflictCaseActions.setIsLoading(false));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
          dispatch(conflictCaseActions.setIsLoading(false));
        });
    }
  };
}

function closeUnfinishedCaseMiddleware(caseId: string): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("closeUnfinishedCaseMiddleware invoked");
    closeUnfinishedCaseAxios({ caseId })
      .then((res) => {
        dispatch(conflictCaseActions.closeUnfinishedCase(caseId));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
      });
  };
}

function initialSyncMiddleware(): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("initialSyncMiddleware invoked");
    let currTime = Math.floor(new Date().getTime() / 1000);
    if (getState().auth.pid !== "" && getState().auth.expires_at > currTime) {
      if (getState().conflictCase.caseList.length === 0) {
        dispatch(conflictCaseActions.setIsLoading(true));
      }
      initialSyncAxios()
        .then((res) => {
          logger.log("initial sync resolved");

          let nnids = res.data.nnids.map((nnidObj) => {
            return {
              display:
                `${nnidObj.nnid} / ${nnidObj.display} ` +
                (nnidObj.authPolicy === "NP_LOA"
                  ? "(NP LOA)"
                  : nnidObj.authPolicy === "SUBSCRIBER_LOA"
                  ? "(SUB LOA)"
                  : "(TF LOA)"),
              value: nnidObj.nnid,
              type: nnidObj.authPolicy,
            };
          });

          dispatch(conflictCaseActions.setNnidList(nnids));
          dispatch(
            notificationActions.setNotificationList(res.data.notifications)
          );

          let provisionerCommonName = res.data.provisionerCommonName;

          dispatch(authActions.setProvisionerCommonName(provisionerCommonName));

          dispatch(conflictCaseActions.setCaseList(res.data.cases));
          dispatch(
            documentationActions.setDocumentationList(res.data.documentation)
          );
          dispatch(
            emailSettingActions.setEmailSettingList(
              res.data.distributionEmailsV2 as EmailSetting[]
            )
          );

          dispatch(notificationActions.setIsLoading(false));
          dispatch(conflictCaseActions.setIsLoading(false));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
          dispatch(conflictCaseActions.setIsLoading(false));
          dispatch(notificationActions.setIsLoading(false));
        });
    }
  };
}

function fetchCaseMiddleware(caseId: string): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("fetchCaseMiddleware invoked");
    if (!getState().conflictCase.isLoading && getState().auth.pid) {
      logger.log("Inside of fetch case");
      dispatch(conflictCaseActions.setIsLoading(true));
      fetchCaseAxios(caseId)
        .then((res) => {
          let singularCase = res.data.conflictCase;
          dispatch(conflictCaseActions.setCase(singularCase));
          dispatch(conflictCaseActions.setIsLoading(false));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
          dispatch(conflictCaseActions.setIsLoading(false));
        });
    }
  };
}

function fetchNnidListMiddleware(): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("fetchNnidListMiddleware invoked");
    const pid = getState().auth.pid;
    fetchNnidList()
      .then((res) => {
        dispatch(conflictCaseActions.setNnidList(res.data.nnids));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
      });
  };
}

function submitCommentMiddleware(
  pid: string,
  caseId: string,
  commentText: string,
  internalComment: boolean
): MiddlewareType {
  //TODO: implement rollbacking
  return (dispatch, getState) => {
    let originalState = {
      ...getState().conflictCase.caseList.find((singularCase) => {
        return singularCase.caseId === caseId;
      }),
    } as SingularCase;
    let {
      displayName,
      pid: currentPid,
      provisionerCommonName,
    } = getState().auth;

    dispatch(
      conflictCaseActions.submitComment({
        caseId,
        comment: {
          commentBy: displayName,
          commentPid: currentPid,
          commentTs: new Date().getTime(),
          systemComment: false,
          commentProvisionerName: provisionerCommonName,
          internalComment: internalComment,
          commentText,
        },
      })
    );
    postSubmitCommentAxios({
      pid: pid,
      caseId: caseId,
      commentText: commentText,
      internalComment: internalComment,
    })
      .then((res) => {
        logger.log(res);
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(conflictCaseActions.setCase(originalState));
      });
  };
}

function submitAcceptTnsMiddleware(
  openingPid: string,
  caseId: string,
  tns: string[]
): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("submitAcceptTnsMiddleware invoked");
    let { pid, email } = getState().auth;
    let currentState = getState().conflictCase.caseList.find((singularCase) => {
      return singularCase.caseId === caseId;
    });
    dispatch(conflictCaseActions.setIsLoading(true));
    postAcceptTnsAxios({ openingPid, caseId, tns })
      .then((res) => {
        logger.log(res);
        dispatch(
          conflictCaseActions.respondTns({
            caseId,
            tns,
            acceptOrReject: true,
            newSystemCommentText: res.data.newSystemCommentText,
            newSystemCommentTs: res.data.newSystemCommentTs,
          })
        );

        dispatch(conflictCaseActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);

        if (currentState !== undefined) {
          dispatch(conflictCaseActions.setCase(currentState));
        }
        dispatch(conflictCaseActions.setIsLoading(false));
      });
  };
}

function submitRejectTnsMiddleware(
  openingPid: string,
  caseId: string,
  tns: string[],
  reason: string,
  additionalContext?: string
): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("submitRejectTnsMiddleware invoked");
    let currentState = getState().conflictCase.caseList.find((singularCase) => {
      return singularCase.caseId === caseId;
    });
    currentState = { ...currentState } as SingularCase; //Recreate as new object, not reference
    dispatch(conflictCaseActions.setIsLoading(true));

    let postRejectTnsPromise: AxiosPromise<RejectTnsOutputDTO>;

    if (additionalContext === null || additionalContext === "") {
      postRejectTnsPromise = postRejectTnsAxiosWithoutAdditionalContext({
        openingPid,
        caseId,
        tns,
        reason,
      });
    } else {
      postRejectTnsPromise = postRejectTnsAxiosWithAdditionalContext({
        openingPid,
        caseId,
        tns,
        reason,
        additionalContext,
      });
    }

    postRejectTnsPromise
      .then((res) => {
        dispatch(
          conflictCaseActions.respondTns({
            caseId,
            tns,
            acceptOrReject: false,
            newSystemCommentText: res.data.newSystemCommentText,
            newSystemCommentTs: res.data.newSystemCommentTs,
          })
        );
        dispatch(conflictCaseActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        if (currentState !== undefined) {
          dispatch(conflictCaseActions.setCase(currentState));
        }
        dispatch(conflictCaseActions.setIsLoading(false));
      });
  };
}

function submitNewCaseMiddleware(
  receivingPid: string,
  tns: string[],
  loas: LoaDTO[]
): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("submitNewCaseMiddleware invoked");
    const { pid } = getState().auth;
    const caseNnid = getState().conflictCase.newCaseNnid?.value!;
    dispatch(conflictCaseActions.setIsLoading(true));

    postCreateCaseAxios({ receivingPid, tns, loas, caseNnid })
      .then((res) => {
        logger.log(res);
        const { caseId, openedTs, expiresTs } = res.data;
        dispatch(
          conflictCaseActions.createNewCase({
            openedBy: pid,
            openedAgainst: receivingPid,
            tns: tns,
            loas: loas.map((loaDTO) => loaDTO.fileName),
            caseId,
            openedTs,
            expiresTs,
          })
        );
        dispatch(
          conflictCaseActions.setNewCaseAsOpened({
            pid: receivingPid,
            caseId: caseId,
          })
        );

        dispatch(conflictCaseActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(conflictCaseActions.setIsLoading(false));
      });
  };
}

function attemptProvisionNewCaseTnsMiddleware(tns: string[]): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("attemptProvisionNewCaseTnsMiddleware invoked");
    let nnid = getState().conflictCase.newCaseNnid?.value!;
    dispatch(conflictCaseActions.setIsLoading(true));
    postAttemptProvisionTnsAxios({ nnid, tns })
      .then((res) => {
        dispatch(
          conflictCaseActions.setNewCaseResultsData(organizeResults(res.data))
        );
        dispatch(conflictCaseActions.setNewCaseCurrentPage(1));
        dispatch(conflictCaseActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(conflictCaseActions.setIsLoading(false));
      });
  };
}

export function osrGetQueryForTnMiddleware(tn: string): MiddlewareType {
  return (dispatch, getState) => {
    dispatch(conflictCaseActions.setIsLoading(true));
    let queryTnPromise;

    if (getState().auth.isAdmin) {
      queryTnPromise = osrGetQueryForTnAdminAxios(tn);
    } else {
      queryTnPromise = osrGetQueryForTnAxios(tn);
    }

    queryTnPromise
      .then((res) => {
        let input;
        if (res.data.pid !== "NA") {
          input = {
            inputTn: tn,
            outputPid: res.data.pid,
            outputNnid: res.data.nnid,
          };
        } else {
          input = {
            inputTn: tn,
            outputPid: "Not Provisioned",
            outputNnid: "Not Provisioned",
          };
        }
        dispatch(queryTnActions.setOsrTnQueryState(input));
        dispatch(conflictCaseActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(conflictCaseActions.setIsLoading(false));
      });
  };
}

export {
  fetchCaseMiddleware,
  fetchCasesMiddleware,
  submitCommentMiddleware,
  submitAcceptTnsMiddleware,
  submitRejectTnsMiddleware,
  submitNewCaseMiddleware,
  attemptProvisionNewCaseTnsMiddleware,
  fetchNnidListMiddleware,
  initialSyncMiddleware,
  closeUnfinishedCaseMiddleware,
};
