import { AxiosPromise } from "./axiosPromise";
import SuccessFailureOutputDTO from "../model/output/successFailureOutputDTO";
import axios from "./axios";
import {
  BASE_URL,
  GET_CASE_ENDPOINT,
  GET_CASES_ENDPOINT,
  POST_CREATE_CASE_ENDPOINT,
  POST_ACCEPT_TNS_ENDPOINT,
  POST_REJECT_TNS_ENDPOINT,
  POST_SUBMIT_COMMENT_ENDPOINT,
  ATTEMPT_PROVISION_TNS_ENDPOINT,
  GET_NNID_LIST_ENDPOINT,
  INITIAL_SYNC_ENDPOINT,
  CLOSE_UNFINISHED_CASE_ENDPOINT,
  OSR_GET_QUERY_FOR_TN_ENDPOINT,
} from "./serviceConfiguration";
import SingularCase from "../model/store/singularCase";
import GetNnidListOutputDTO from "../model/output/getNnidListOutputDTO";
import InitialSyncOutputDTO from "../model/output/initialSyncOutputDTO";
import CreateNewCaseOutputDTO from "../model/output/createNewCaseOutputDTO";
import AcceptTnsOutputDTO from "../model/output/acceptTnsOutputDTO";
import RejectTnsOutputDTO from "../model/output/rejectTnsOutputDTO";
import AcceptTnsDTO from "../model/input/acceptTnsInputDTO";
import RejectTnsInputDTO from "../model/input/rejectTnsInputDTO";
import CreateNewCaseInputDTO from "../model/input/createNewCaseInputDTO";
import SubmitCommentInputDTO from "../model/input/submitCommentInputDto";
import SubmitCommentOutputDTO from "../model/output/submitCommentOutputDTO";
import AttemptProvisionInputDTO from "../model/input/attemptProvisionInputDTO";
import AttemptProvisionOutputDTO from "../model/output/attemptProvisionOutputDTO";
import CloseUnfinishedCaseInputDTO from "../model/input/closeUnfinishedCaseInputDTO";
import GetCasesOutputDTO from "../model/output/getCasesOutputDTO";
import GetCaseOutputDTO from "../model/output/getCaseOutputDTO";
import { getAuthToken } from "./axios";
import QueryTnOutputDTO from "../model/output/queryTnOutputDTO";

function fetchCasesAxios(): AxiosPromise<GetCasesOutputDTO> {
  return axios.get(BASE_URL + GET_CASES_ENDPOINT, getAuthToken());
}

function closeUnfinishedCaseAxios(
  input: CloseUnfinishedCaseInputDTO
): AxiosPromise<SuccessFailureOutputDTO> {
  return axios.post(
    BASE_URL + CLOSE_UNFINISHED_CASE_ENDPOINT,
    input,
    getAuthToken()
  );
}

function fetchCaseAxios(caseId: string): AxiosPromise<GetCaseOutputDTO> {
  return axios.get(BASE_URL + GET_CASE_ENDPOINT + `/${caseId}`, getAuthToken());
}

function fetchNnidList(): AxiosPromise<GetNnidListOutputDTO> {
  return axios.get(BASE_URL + GET_NNID_LIST_ENDPOINT, getAuthToken());
}

function initialSyncAxios(): AxiosPromise<InitialSyncOutputDTO> {
  return axios.get(BASE_URL + INITIAL_SYNC_ENDPOINT, getAuthToken());
}

function postCreateCaseAxios(
  input: CreateNewCaseInputDTO
): AxiosPromise<CreateNewCaseOutputDTO> {
  return axios.post(
    BASE_URL + POST_CREATE_CASE_ENDPOINT,
    input,
    getAuthToken()
  );
}

function postAcceptTnsAxios(
  input: AcceptTnsDTO
): AxiosPromise<AcceptTnsOutputDTO> {
  return axios.post(BASE_URL + POST_ACCEPT_TNS_ENDPOINT, input, getAuthToken());
}

function postRejectTnsAxiosWithoutAdditionalContext(
  input: RejectTnsInputDTO
): AxiosPromise<RejectTnsOutputDTO> {
  return axios.post(BASE_URL + POST_REJECT_TNS_ENDPOINT, input, getAuthToken());
}

function postRejectTnsAxiosWithAdditionalContext(
  input: RejectTnsInputDTO
): AxiosPromise<RejectTnsOutputDTO> {
  return axios.post(BASE_URL + POST_REJECT_TNS_ENDPOINT, input, getAuthToken());
}

function postSubmitCommentAxios(
  input: SubmitCommentInputDTO
): AxiosPromise<SubmitCommentOutputDTO> {
  return axios.post(
    BASE_URL + POST_SUBMIT_COMMENT_ENDPOINT,
    input,
    getAuthToken()
  );
}

function postAttemptProvisionTnsAxios(
  input: AttemptProvisionInputDTO
): AxiosPromise<AttemptProvisionOutputDTO[]> {
  return axios.post(
    BASE_URL + ATTEMPT_PROVISION_TNS_ENDPOINT,
    input,
    getAuthToken()
  );
}

export function downloadFileAxios(caseId: string, fileName: string) {
  return axios.get(BASE_URL + `${caseId}/${fileName}`, {
    responseType: "blob",
    ...getAuthToken(),
  });
}

export function osrGetQueryForTnAxios(
  tn: string
): AxiosPromise<QueryTnOutputDTO> {
  return axios.get(
    BASE_URL + OSR_GET_QUERY_FOR_TN_ENDPOINT + `/${tn}`,
    getAuthToken()
  );
}

export {
  fetchCaseAxios,
  fetchCasesAxios,
  postCreateCaseAxios,
  postAcceptTnsAxios,
  postRejectTnsAxiosWithAdditionalContext,
  postRejectTnsAxiosWithoutAdditionalContext,
  postSubmitCommentAxios,
  postAttemptProvisionTnsAxios,
  fetchNnidList,
  initialSyncAxios,
  closeUnfinishedCaseAxios,
};
