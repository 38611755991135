/**
 * The different fields that the filter dropdown will display.
 *
 *  display -> What id displayed on the dropdown
 *  value -> the field of cases that correspondes to this field, also to the operators object
 *  typeDisplay -> The type of display for value section that is shown when that field is selected
 */

import Filter from "../../model/store/filter";
import SingularCase, {
  SingularCaseFilterableAttributes,
} from "../../model/store/singularCase";
import { isValidTn } from "../../utils/genericUtils";

export enum TypeDisplay {
  DROPDOWN,
  TEXT_BOX_NUMBER,
  TEXT_BOX_STRING,
  DATE,
}

export interface IFieldValue {
  display: string;
  value: keyof SingularCaseFilterableAttributes | "specificTn" | "multipleTns";
  typeDisplay: TypeDisplay;
  infoText?: string;
  toolTip?: string;
  valueValidations?: [(value: any) => string];
}

const FIELD_VALUES: IFieldValue[] = [
  {
    display: "Case ID",
    value: "caseId",
    typeDisplay: TypeDisplay.DROPDOWN,
  },
  {
    display: "Specific TN",
    value: "specificTn",
    typeDisplay: TypeDisplay.DROPDOWN,
  },
  {
    display: "Multiple TNs",
    value: "multipleTns",
    infoText:
      "Returns any cases with any of the entered TNs. Use a comma as a delimiter. ",
    toolTip: "Whitespace will be ignored.",
    typeDisplay: TypeDisplay.TEXT_BOX_STRING,
    valueValidations: [
      (value: any) => {
        let valueString = value as string;
        let tns = valueString.split(",").map((tn) => tn.trim());
        let errorTns: string[] = [];
        tns.forEach((tn) => {
          if (!isValidTn(tn)) {
            errorTns.push(`\"${tn}\"`);
          }
        });

        if (errorTns.length > 0) {
          return "The following are not valid TNs: " + errorTns.join(", ");
        }

        return "";
      },
    ],
  },
  {
    display: "Status",
    value: "status",
    typeDisplay: TypeDisplay.DROPDOWN,
  },
  {
    display: "Opened By",
    value: "openedBy",
    typeDisplay: TypeDisplay.DROPDOWN,
  },
  {
    display: "Opened Against",
    value: "openedAgainst",
    typeDisplay: TypeDisplay.DROPDOWN,
  },
  {
    display: "# TNs",
    value: "numberTns",
    typeDisplay: TypeDisplay.TEXT_BOX_NUMBER,
  },
  {
    display: "Opened On",
    value: "openedTs",
    typeDisplay: TypeDisplay.DATE,
  },
  {
    display: "Expiration Date",
    value: "expiresTs",
    typeDisplay: TypeDisplay.DATE,
  },
];

/**
 * The different operators for each of the different fields.
 *
 *  key: the value of the data associated to that set of filters
 *  display: what will be displayed in the operatators dropdown
 *  value: the value of that operator. Corresponds to the filter function that will be called below.
 */

type OperatorValues =
  | "is"
  | "isNot"
  | "greaterThan"
  | "lessThan"
  | "containsTn"
  | "containsMultipleTns"
  | "isNumeric"
  | "isDate"
  | "greaterThanOrEqualDate"
  | "lessThanOrEqualDate"
  | "greaterThanOrEqual"
  | "lessThanOrEqual";

const OPERATOR_VALUES = {
  caseId: [
    {
      display: "Is",
      value: "is" as OperatorValues,
    },
  ],
  status: [
    {
      display: "Is",
      value: "is" as OperatorValues,
    },
    {
      display: "Is Not",
      value: "isNot" as OperatorValues,
    },
  ],
  openedBy: [
    {
      display: "Is",
      value: "is" as OperatorValues,
    },
    {
      display: "Is Not",
      value: "isNot" as OperatorValues,
    },
  ],
  openedAgainst: [
    {
      display: "Is",
      value: "is" as OperatorValues,
    },
    {
      display: "Is Not",
      value: "isNot" as OperatorValues,
    },
  ],
  numberTns: [
    {
      display: "Equal to",
      value: "isNumeric" as OperatorValues,
    },
    {
      display: "Greater than",
      value: "greaterThan" as OperatorValues,
    },
    {
      display: "Less than",
      value: "lessThan" as OperatorValues,
    },
    {
      display: "Greater than or Equal",
      value: "greaterThanOrEqual" as OperatorValues,
    },
    {
      display: "Less than or Equal",
      value: "lessThanOrEqual" as OperatorValues,
    },
  ],
  openedTs: [
    {
      display: "Equal to",
      value: "isDate" as OperatorValues,
    },
    {
      display: "After",
      value: "greaterThan" as OperatorValues,
    },
    {
      display: "Before",
      value: "lessThan" as OperatorValues,
    },
    {
      display: "After or Equal to",
      value: "greaterThanOrEqualDate" as OperatorValues,
    },
    {
      display: "Before or Equal to",
      value: "lessThanOrEqualDate" as OperatorValues,
    },
  ],
  expiresTs: [
    {
      display: "Equal to",
      value: "isDate" as OperatorValues,
    },
    {
      display: "After",
      value: "greaterThan" as OperatorValues,
    },
    {
      display: "Before",
      value: "lessThan" as OperatorValues,
    },
    {
      display: "After or Equal to",
      value: "greaterThanOrEqualDate" as OperatorValues,
    },
    {
      display: "Before or Equal to",
      value: "lessThanOrEqualDate" as OperatorValues,
    },
  ],
  specificTn: [
    {
      display: "Case Contains",
      value: "containsTn" as OperatorValues,
    },
  ],
  multipleTns: [
    {
      display: "Case Contains",
      value: "containsMultipleTns" as OperatorValues,
    },
  ],
};

/**
 * Different functions that the caseList filtering functionality can use. What filter function
 * is called is dependent on the "value" field of the operator selected
 *
 * is -> returns only rows that have the EXACT value of the filter object
 * isNot -> returns only rows that have ARENT exact value of the filter object
 * greaterThan -> returns only rows that have LESS of the value of the filter object
 * lessThan -> returns only rows that have MORE of the value of the filter object
 *
 */

const FILTER_FUNCTIONS: {
  [operator: string]: (
    rows: SingularCase[],
    filterObj: Filter
  ) => SingularCase[];
} = {
  is: (rows: SingularCase[], filterObj: Filter) => {
    return rows.filter((row) => {
      return row[filterObj.field] === filterObj.value;
    });
  },
  isNot: (rows: SingularCase[], filterObj: Filter) => {
    return rows.filter((row) => row[filterObj.field] !== filterObj.value);
  },
  isNumeric: (rows: SingularCase[], filterObj: Filter) => {
    return rows.filter(
      (row) => row[filterObj.field] === Number.parseInt(filterObj.value)
    );
  },
  isDate: (rows: SingularCase[], filterObj: Filter) => {
    return rows.filter((row) => {
      return (
        new Date(row[filterObj.field] as number).toDateString() ===
        new Date(Number.parseInt(filterObj.value)).toDateString()
      );
    });
  },
  greaterThan: (rows: SingularCase[], filterObj: Filter) => {
    let value = Number.parseInt(filterObj.value);
    return rows.filter((row) => row[filterObj.field] > value);
  },
  lessThan: (rows: SingularCase[], filterObj: Filter) => {
    let value = Number.parseInt(filterObj.value);
    return rows.filter((row) => row[filterObj.field] < value);
  },
  greaterThanOrEqual: (rows: SingularCase[], filterObj: Filter) => {
    let value = Number.parseInt(filterObj.value);
    return rows.filter((row) => row[filterObj.field] >= value);
  },
  lessThanOrEqual: (rows: SingularCase[], filterObj: Filter) => {
    let value = Number.parseInt(filterObj.value);
    return rows.filter((row) => row[filterObj.field] <= value);
  },
  greaterThanOrEqualDate: (rows: SingularCase[], filterObj: Filter) => {
    let value = Number.parseInt(filterObj.value);
    return rows.filter((row) => {
      return (
        row[filterObj.field] > value ||
        new Date(row[filterObj.field] as number).toDateString() ===
          new Date(Number.parseInt(filterObj.value)).toDateString()
      );
    });
  },
  lessThanOrEqualDate: (rows: SingularCase[], filterObj: Filter) => {
    let value = Number.parseInt(filterObj.value);
    return rows.filter((row) => {
      return (
        row[filterObj.field] < value ||
        new Date(row[filterObj.field] as number).toDateString() ===
          new Date(Number.parseInt(filterObj.value)).toDateString()
      );
    });
  },
  containsTn: (rows: SingularCase[], filterObj: Filter) => {
    let value = filterObj.value;
    return rows.filter((row) => {
      return row.tns.map((tn) => tn.tn).includes(value);
    });
  },
  containsMultipleTns: (rows: SingularCase[], filterObj: Filter) => {
    let value = filterObj.value;

    let tns = value.split(",").map((tn) => tn.trim());

    return rows.filter((row) => {
      let caseContainsATn = false;

      row.tns
        .map((tn) => tn.tn)
        .forEach((tn) => {
          if (tns.includes(tn)) {
            caseContainsATn = true;
          }
        });

      return caseContainsATn;
    });
  },
};

export { FIELD_VALUES, OPERATOR_VALUES, FILTER_FUNCTIONS };
