import { createSlice } from "@reduxjs/toolkit";
import * as NotificatioReducers from "../reducer/notificationsReducers";
import Notification from "../model/store/notification";

export interface NotificationState {
  isLoading: boolean;
  notificationList: Notification[];
}

const initNotificationState = (): NotificationState => {
  return {
    isLoading: false,
    notificationList: [],
  };
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initNotificationState(),
  reducers: {
    ...NotificatioReducers,
  },
});

const notificationActions = notificationSlice.actions;

export { notificationActions, notificationSlice };
