import { Button } from "@mui/material";
import { useState } from "react";
import { useModalContext } from "../../context/ModalContext";
import { removeDocumentationMiddleware } from "../../middleware/documentationMiddleware";
import { useAppDispatch } from "../../store/hooks";
import Documentation from "../../model/store/documentation";

interface RemoveDocumentationModalInterface {
  document: Documentation;
  clearSelectedDocumentation: () => void;
}

const RemoveDocumentationModalContent = ({
  document,
  clearSelectedDocumentation,
}: RemoveDocumentationModalInterface) => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModalContext();
  const [removalConfirmed, setRemovalConfirmed] = useState(false);

  const onClickRemoveButton = () => {
    dispatch(removeDocumentationMiddleware(document.fileId));

    clearSelectedDocumentation();
    closeModal();
  };

  const onClickNevermindButton = () => {
    clearSelectedDocumentation();
    closeModal();
  };

  return (
    <>
      <p>
        You have selected to <span className="bold">REMOVE</span> an existing
        piece of nnSR documentation.
      </p>
      <p>
        <ol>
          <span className="bold">{document.displayName}</span>
        </ol>
      </p>
      <div className="line" />
      <p>
        Please make sure you're sure about this! Once deleted, the document will
        no longer be viewable by any users!
      </p>

      <div>
        <input
          id="remove-documentation-confirmation"
          type="checkbox"
          onClick={() => {
            setRemovalConfirmed(!removalConfirmed);
          }}
        />
        <label htmlFor="remove-documentation-confirmation" className="bold">
          Yes, I'm sure I'd like to remove "{document.displayName}"
        </label>
      </div>

      <div className="prov-info-modal-action-container">
        <Button
          onClick={onClickRemoveButton}
          disabled={!removalConfirmed}
          variant="contained"
          color="error"
        >
          Remove
        </Button>
        <Button
          onClick={onClickNevermindButton}
          variant="outlined"
          color="warning"
        >
          Nevermind
        </Button>
      </div>
    </>
  );
};

export default RemoveDocumentationModalContent;
