const fieldToDisplayMappings: { [asdf: string]: string } = {
  receiveOneDayReminderEmails: "One Day Reminder Emails",
  receiveOneHourReminderEmails: "One Hour Reminder Emails",
  receiveCommentEmails: "New Comment Emails",
  receiveCaseOpenedEmails: "New Case Emails",
  receiveCaseClosedEmails: "Case Closed Emails",
  receiveTnsAcceptedEmails: "Tns Accepted Emails",
  receiveTnsRejectedEmails: "Tns Rejected Emails",
  receiveCaseExpiredInitiatingPartyEmails: "Case Expired (Initiating Party)",
  receiveCaseExpiredReceivingPartyEmails: "Case Expired (Receiving Party)",
};

export default fieldToDisplayMappings;
