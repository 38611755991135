import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import Error from "../Error/Error";
import { SlOptionsVertical } from "react-icons/sl";
import { fetchCaseMiddleware } from "../../middleware/conflictCaseMiddleware";
import { CaseHeader } from "./CaseHeader";
import { Loas } from "./Loas";
import { RequestedTns } from "./RequestedTns";
import { ActivityComponent } from "./Activity";
import { closeUnfinishedCaseMiddleware } from "../../middleware/conflictCaseMiddleware";
import { caseOpened } from "../../utils/genericUtils";
import { useModalContext } from "../../context/ModalContext";
import { Popover, Box, Fab, Button } from "@mui/material";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";
import { AppDispatch, AppState } from "../../store";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { caseFilterActions } from "../../store/caseFilterSlice";

/**
 * Component of Singular Case page.
 *
 * @returns Component
 */

const SingularCaseComponent = () => {
  const { caseId } = useParams();
  const [error, setError] = useState(false);
  const pid = useAppSelector((state) => state.auth.pid);
  const filteredCases = useAppSelector((state) => state.caseFilter.caseList);
  const { displayModal } = useModalContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isAdmin = useIsAdminAuthenticated();
  const rowsPerPage = useAppSelector(
    (state) => state.caseFilter.caseListRowsPerPage
  );

  let currentCase = useSelector((state: AppState) =>
    state.conflictCase.caseList.find(
      (singularCase) => singularCase.caseId === caseId
    )
  );

  const navigate = useNavigate();

  const isCaseCloseable =
    currentCase && currentCase.openedBy === pid && caseOpened(currentCase);
  const dispatch: AppDispatch = useDispatch();

  /**
   * Fetches the case, for any updates or if they enter directly onto this page
   */
  useEffect(() => {
    if (caseId === null) {
      setError(true);
    }

    if (caseId !== undefined) {
      dispatch(fetchCaseMiddleware(caseId));
    }
  }, [caseId]);

  const closeCase = () => {
    handleActionMenuDropdownClose();
    if (isCaseCloseable) {
      dispatch(closeUnfinishedCaseMiddleware(caseId!));
    } else {
      displayModal(
        <p>
          Sorry, you may only close a case if you are the{" "}
          <span className="bold">initiating party</span> and the case is still
          open!
        </p>,
        "Oops!",
        { width: "33%" },
        true
      );
    }
  };

  const currentIndex = filteredCases.findIndex(
    (caseItem) => caseItem.caseId === caseId
  );

  const goToPreviousCase = () => {
    const previousCase = filteredCases[currentIndex - 1];
    const newIndex = currentIndex - 1;
    let admin = isAdmin();
    if (previousCase) {
      navigate(`${isAdmin() ? "/admin" : ""}/case/${previousCase.caseId}`);
      let newPageIndex = Math.floor(newIndex / rowsPerPage);
      dispatch(caseFilterActions.setCaseListCurrentPageIndex(newPageIndex));
    } else if (newIndex === -1) {
      navigate(`${isAdmin() ? "/admin" : ""}/caselist`);
    }
  };

  const goToNextCase = () => {
    const nextCase = filteredCases[currentIndex + 1];
    const newIndex = currentIndex + 1;
    if (nextCase) {
      navigate(`${isAdmin() ? "/admin" : ""}/case/${nextCase.caseId}`);
      let newPageIndex = Math.floor(newIndex / rowsPerPage);
      dispatch(caseFilterActions.setCaseListCurrentPageIndex(newPageIndex));
    } else if (newIndex === filteredCases.length) {
      navigate(`${isAdmin() ? "/admin" : ""}/caselist`);
    }
  };

  const handleActionMenuDropdownClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuDropdownClose = () => {
    setAnchorEl(null);
  };

  /*
   * If Loading or in error, return those components
   */

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-2" : undefined;

  if (!caseId) {
    return <Error></Error>;
  }

  if (!currentCase) {
    return <div>loading</div>;
  }

  return (
    <div className="singular-case-page-container">
      <div className="singular-case-navigation-button-container">
        <button className="btn" onClick={goToPreviousCase}>
          Previous
        </button>
        <Link to={isAdmin() ? `/admin/caselist` : "/caselist"} className="btn">
          Back to Cases List
        </Link>
        <button className="btn" onClick={goToNextCase}>
          Next
        </button>
      </div>
      <div className="section singular-case-top">
        <h1 className="case-id-header">
          {" "}
          Case Id: {currentCase.caseId.substring(0, 6)}
        </h1>
        <Fab
          sx={{
            zIndex: 50,
          }}
          onClick={(event) => handleActionMenuDropdownClick(event)}
        >
          <SlOptionsVertical
            className={"options-icon " + (open && " active")}
          />
        </Fab>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleActionMenuDropdownClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{
              minWidth: "200px",
              borderRadius: "2rem",
            }}
          >
            <div
              className={
                "singular-case-options-dropdown-option " +
                (isCaseCloseable ? " enabled" : " disabled")
              }
              onClick={closeCase}
            >
              Close Case
            </div>
          </Box>
        </Popover>
      </div>
      {caseOpened(currentCase) || (
        <p className="section informational">
          <FaInfoCircle /> This case is currently closed. You may still add
          comments, but there is no further action to take at this time
        </p>
      )}
      <div className="line" />
      <CaseHeader currentCase={currentCase} />
      <div className="line" />
      <div className="section requested-container">
        <h2>Requested TNs</h2>
        <RequestedTns currentCase={currentCase} />
      </div>
      <div className="line" />
      <div className="section loa-section-container">
        <h2>Supporting LOAs</h2>
        <Loas
          loas={currentCase.loas}
          caseId={caseId}
          nnidType={currentCase.nnidType}
        />
      </div>
      <div className="line" />
      <div className="section comments-section-container">
        <h2>Activity</h2>
        <ActivityComponent
          comments={currentCase.activity}
          caseId={caseId}
          openedBy={currentCase.openedBy}
        />
      </div>
    </div>
  );
};

export default SingularCaseComponent;
