import { Stack, Chip, Typography, Button, Paper } from "@mui/material";
import { useState } from "react";
import logger from "../../utils/logger";
import Configurations from "./Configurations";
import EnabledProvisioners from "./EnabledProvisioners";
import NnidOwner from "./NnidOwner";

const AdminConfigurations = () => {
  const [pageIndex, setPageIndex] = useState<number>(0);

  const onClick = (pageIndex: number) => {
    setPageIndex(pageIndex);
  };

  return (
    <>
      <div className="admin-config-page">
        <div className="top-page-selectable-tab-buttons">
          <div className="top-page-selectable-tab-buttons-container">
            <Typography className="admin-config-menu-option" variant="h4">
              View
            </Typography>
            <Button
              variant={pageIndex === 0 ? "contained" : "outlined"}
              className="admin-config-menu-option"
              onClick={() => onClick(0)}
            >
              <Typography variant="h4">Provisioners</Typography>
            </Button>
            <Button
              variant={pageIndex === 1 ? "contained" : "outlined"}
              className="admin-config-menu-option"
              onClick={() => onClick(1)}
            >
              <Typography variant="h4">Configurations</Typography>
            </Button>
            <Button
              variant={pageIndex === 2 ? "contained" : "outlined"}
              className="admin-config-menu-option"
              onClick={() => onClick(2)}
            >
              <Typography variant="h4">Nnid Owners</Typography>
            </Button>
          </div>
        </div>
        {pageIndex === 0 && <EnabledProvisioners />}
        {pageIndex === 1 && <Configurations />}
        {pageIndex === 2 && <NnidOwner />}
      </div>
    </>
  );
};

export default AdminConfigurations;
