import { useState, useMemo } from "react";
import ActionButtons from "../../components/ui/ActionButtons";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useModalContext } from "../../context/ModalContext";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { Paper, Button, Typography } from "@mui/material";
import DropDown from "../../components/forms/DropDown";
import { DisplayableValue } from "../../model/store/displayableValue";
import {
  enableNewProvisionerMiddleware,
  removeProvisionerMiddleware,
} from "../../middleware/adminMiddleware";

const columns: GridColDef[] = [
  { field: "value", headerName: "Pid", minWidth: 200 },
  { field: "display", headerName: "Pid Common Name", minWidth: 600 },
];

const EnabledProvisioners = () => {
  const [selectedProvisioner, setSelectedProvisioner] =
    useState<GridRowSelectionModel>([]);
  const provisioners = useAppSelector(
    (state) => state.admin.enabledProvisionerList
  );
  const { displayModal } = useModalContext();
  const provisionerHasBeenSelected = selectedProvisioner.length > 0;

  const removeOnClick = () => {
    let selectedPid = provisioners.find(
      (prov) => prov.value === selectedProvisioner[0]
    );

    displayModal(
      <RemoveProvisionerModalContent provisioner={selectedPid!} />,
      "Remove Provisioner",
      { width: "50%" },
      true
    );
  };

  const newOnClick = () => {
    displayModal(
      <AddProvisionerModalContent />,
      "Add Provisioner",
      { width: "50%" },
      true
    );
  };

  const provisionerRows = useMemo(() => {
    return provisioners.map((provisioner, ind) => {
      return {
        id: provisioner.value,
        ...provisioner,
      };
    });
  }, [provisioners]);

  // event is the new state, will all selected rows,
  // however we want to enforce only 1 being selected at a time.
  // So, we pop the last "id" or disEmail, and then set it as selectedEmail
  const onRowClick = (event: GridRowSelectionModel) => {
    let newSelectedId = [] as GridRowSelectionModel;
    if (event.length > 0) {
      newSelectedId = [event.pop()] as GridRowSelectionModel;
    } else {
      newSelectedId = [];
    }
    setSelectedProvisioner(newSelectedId);
  };

  return (
    <>
      <Typography sx={{ mt: "2rem" }} variant="h3">
        Enabled Provisioners
      </Typography>
      {/* <Typography variant="body1">
          The following are the Provisioners / Pid's that are currently portal
          enabled. Being portal enabled allows others to open up a case against
          them, as well as those users to log into the portal
        </Typography> */}
      <ActionButtons
        removeEditable={!provisionerHasBeenSelected}
        editDisabled={true}
        removeOnClick={removeOnClick}
        newOnClick={newOnClick}
      />
      <Paper className="provisioner-info-data-container">
        <DataGrid
          rows={provisionerRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          onRowSelectionModelChange={onRowClick}
          rowSelectionModel={selectedProvisioner}
        />
      </Paper>
    </>
  );
};

interface RemoveProvisionerModalInterface {
  provisioner: DisplayableValue<string>;
}

const RemoveProvisionerModalContent = ({
  provisioner,
}: RemoveProvisionerModalInterface) => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModalContext();

  const onClickRemoveButton = () => {
    dispatch(removeProvisionerMiddleware(provisioner.value));
    closeModal();
  };

  const onClickNoButton = () => {
    closeModal();
  };

  return (
    <>
      <p>
        You have selected to DISABLE the following provisioner from the nnSR
        Portal
      </p>
      <ol>
        <li>
          <h2>
            <span className="bold">{provisioner.display}</span>
          </h2>
        </li>
      </ol>
      <p>Once disabled:</p>
      <ol>
        <li className="bold">
          - Other provisioners will NO LONGER be able to open cases against this
          provisioner
        </li>
        <li className="bold">
          - This provisioner's users will NO LONGER be capable of logging in to
          the portal
        </li>
      </ol>
      <div className="line" />
      <p>
        Are you sure you'd like to continue with disabling{" "}
        <span className="bold">{provisioner.display}</span>?
      </p>
      <div className="admin-config-modal-action-container">
        <Button onClick={onClickRemoveButton} variant="contained" color="error">
          remove
        </Button>
        <Button onClick={onClickNoButton} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

interface AddProvisionerModalInterface {}

const AddProvisionerModalContent = ({}: AddProvisionerModalInterface) => {
  const NO_ADMIN_STATE = {
    isProvSelectError: false,
    provSelectErrorMessage: "",
  };

  const enabledProvisioners = useAppSelector(
    (state) => state.admin.enabledProvisionerList
  ).map((prov) => prov.value);

  const provisionerOptions = useAppSelector(
    (state) => state.admin.allProvisionerList
  ).filter((possibleProv) => {
    return !enabledProvisioners.includes(possibleProv.value);
  }); // Remove already selected Pids from the list to choose from!

  const [selectedProv, setSelectedProv] =
    useState<DisplayableValue<string> | null>(null);
  const { closeModal } = useModalContext();
  const dispatch = useAppDispatch();
  const [errorState, setErrorState] = useState(NO_ADMIN_STATE);

  const onClickAddButton = () => {
    let isError = false;

    if (!selectedProv) {
      isError = true;
      setErrorState({
        isProvSelectError: true,
        provSelectErrorMessage: "You did not select a provisioner!",
      });
    }

    if (isError) {
      return;
    }

    dispatch(enableNewProvisionerMiddleware(selectedProv!));

    closeModal();
  };

  const onClickNoButton = () => {
    closeModal();
  };

  return (
    <>
      <p>You have selected to enable a new provisioner into the Portal!</p>
      <p>Once enabled:</p>
      <ol>
        <li className="bold">
          - Other provisioners will be able to open cases against this
          provisioner
        </li>
        <li className="bold">
          - This provisioner's users will be capable of logging in to the portal
        </li>
      </ol>
      <div className="line" />
      <p>Please select a provisioner:</p>
      <div
        style={{ width: "100%", marginTop: "2rem" }}
        className="admin-config-add-prov-modal-inputs"
      >
        {errorState.isProvSelectError && (
          <p className="input-error">{errorState.provSelectErrorMessage}</p>
        )}
        <DropDown
          onClick={(value) => setSelectedProv(value)}
          placeholder="Select A Provisioner"
          error={errorState.isProvSelectError}
          list={provisionerOptions}
        />
      </div>
      <div className="admin-config-modal-action-container">
        <Button onClick={onClickAddButton} variant="contained" color="success">
          Add
        </Button>
        <Button onClick={onClickNoButton} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

export default EnabledProvisioners;
