import { useSelector, useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { submitCommentMiddleware } from "../../middleware/conflictCaseMiddleware";
import { getTimeStringShort } from "../../utils/timeUtils";
import {
  Button,
  Skeleton,
  Box,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
  Popper,
  Typography,
} from "@mui/material";
import TextareaInput from "../../components/forms/TextareaInput";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";
import Activity from "../../model/store/comment";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { FaInfoCircle } from "react-icons/fa";
import { useModalContext } from "../../context/ModalContext";

/**
 * Component for Activity section of a Singular Case
 *
 * @param {list} comments List of comment objects to be displayed associated with the case.
 *
 * @returns {Component}
 */
interface ActivityComponentProps {
  comments: Activity[];
  openedBy: string;
  caseId: string;
}

const ActivityComponent = ({
  comments,
  openedBy,
  caseId,
}: ActivityComponentProps) => {
  const pid = useAppSelector((state) => state.auth.pid);
  const [internalComment, setInternalComment] = useState<boolean>(false);
  const textBoxRef = useRef<HTMLTextAreaElement>(null!);
  const isAdmin = useIsAdminAuthenticated()();
  const isLoading = useAppSelector((state) => state.conflictCase.isLoading);
  const { displayModal } = useModalContext();

  /**
   * Adds a comment to the global case state and currentCase state
   *
   * @param {String} text value of comment text
   */
  const submitComment = (commentText: string | null) => {
    if (commentText) {
      displayModal(
        <SubmitNewCommentModal
          commentText={commentText}
          internalComment={internalComment}
          openedBy={openedBy}
          caseId={caseId}
        />,
        `Submit ${internalComment ? "Internal " : "Public "}Comment`,
        { width: "50%" },
        false
      );
    }
  };

  if (!comments || comments.length === 0) {
    return <div>There are no Comments associated with this case...</div>;
  }

  if (isLoading) {
    return <ActivityIsLoading></ActivityIsLoading>;
  }
  return (
    <div className="comments-container">
      {comments.map((comment) => {
        return (
          <div
            className={
              "comment" +
              (comment.commentPid === pid ? " yourself" : " them") +
              (comment.internalComment ? " internal" : "") +
              (comment.systemComment ? " system" : "")
            }
            key={comment.commentTs}
          >
            <div className="comment-header">
              <div className="comment-by">
                {!comment.systemComment
                  ? `Provisioner: ${
                      comment.commentProvisionerName !== null //Temp, for older comments
                        ? comment.commentProvisionerName
                        : comment.commentPid
                    } | User: ${comment.commentBy}${
                      comment.internalComment ? " | INTERNAL COMMENT" : ""
                    }`
                  : "Case Information"}
              </div>

              <div className="comment-ts">
                {getTimeStringShort(comment.commentTs)}
              </div>
            </div>
            {comment.commentText}
          </div>
        );
      })}
      {!isAdmin && (
        <div className="comments-input">
          <TextareaInput
            sx={{
              minWidth: "100%",
            }}
            inputRef={textBoxRef}
          />
          <Box sx={{ alignItems: "flex-end", mt: 2 }}>
            <Tooltip
              title={
                <>
                  <Typography>
                    Internal comments will not be seen by the other party, or
                    recieve any email notifications
                  </Typography>
                </>
              }
            >
              <IconButton size="small">
                <FaInfoCircle size={"1.5rem"} />
              </IconButton>
            </Tooltip>
            <FormControlLabel
              sx={{ mr: 3 }}
              control={<Switch checked={internalComment} />}
              label="Internal Comment"
              labelPlacement="start"
              onClick={() => setInternalComment(!internalComment)}
            />
            <Button
              variant="outlined"
              onClick={() => {
                if (textBoxRef.current) {
                  submitComment(textBoxRef.current.value);
                  textBoxRef.current.value = "";
                }
              }}
            >
              Submit
            </Button>
          </Box>
        </div>
      )}
    </div>
  );
};

interface SubmitNewCommentModalProps {
  commentText: string;
  internalComment: boolean;
  openedBy: string;
  caseId: string;
}

const SubmitNewCommentModal = ({
  commentText,
  internalComment,
  openedBy,
  caseId,
}: SubmitNewCommentModalProps) => {
  const dispatch = useAppDispatch();
  const { closeModal } = useModalContext();

  const onClickSubmit = () => {
    dispatch(
      submitCommentMiddleware(openedBy, caseId, commentText, internalComment)
    );
    closeModal();
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <div>You have chosen to select the following comment</div>

      <TextareaInput
        value={commentText}
        disabled={true}
        sx={{
          mt: "1rem",
          mb: "1rem",
          minWidth: "100%",
        }}
      />
      <div>
        {internalComment ? (
          <span>
            This is an <span className="bold">internal comment</span>, and will
            only be viewable by those in your organization. The other party{" "}
            <span className="bold">WILL NOT see this message</span>.
          </span>
        ) : (
          <span>
            This is an <span className="bold">public comment</span>, and will
            viewable to any users in an organization with the case. The other
            party <span className="bold">WILL see this message</span>.
          </span>
        )}
      </div>
      <div style={{ marginTop: "1rem" }}>
        Are you sure you'd like to submit the above message?
      </div>
      <div className="accept-tns-control-container">
        <Button className="success" variant="contained" onClick={onClickSubmit}>
          Submit
        </Button>
        <Button
          variant="outlined"
          color="warning"
          className="warning"
          onClick={closeModal}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

const ActivityIsLoading = () => {
  return (
    <div className="comments-container">
      {Array(3)
        .fill(null)
        .map(() => {
          return (
            <Skeleton
              className="comment comment-loading"
              variant="rectangular"
            ></Skeleton>
          );
        })}
    </div>
  );
};

export { ActivityComponent };
