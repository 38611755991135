import { NotificationState } from "../store/notificationsSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import Notification from "../model/store/notification";

interface MarkAsReadUnreadInput {
  notificationId: string;
  readUnread: boolean;
}
export function markAsReadUnread(
  state: NotificationState,
  action: PayloadAction<MarkAsReadUnreadInput>
) {
  let { notificationId, readUnread } = action.payload;

  let notificationList = state.notificationList;

  let index = notificationList.findIndex(
    (notification) => notification.notificationId === notificationId
  );
  if (index > -1 && index < notificationList.length) {
    notificationList[index].read = readUnread;
  }
}

export function markAllAsRead(state: NotificationState) {
  let { notificationList } = state;
  state.notificationList = notificationList.map((notification) => {
    return {
      ...notification,
      read: true,
    };
  });
}

export function setIsLoading(
  state: NotificationState,
  action: PayloadAction<boolean>
) {
  state.isLoading = action.payload;
}
export function setNotificationList(
  state: NotificationState,
  action: PayloadAction<Notification[]>
) {
  state.notificationList = action.payload;
}
