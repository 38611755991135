function fileToByteArray(file: File): Promise<number[]> {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      let fileByteArray: number[] = [];
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target!.readyState === FileReader.DONE) {
          let arrayBuffer = evt.target!.result,
            array = new Uint8Array(arrayBuffer as ArrayBuffer);
          array.forEach((uint) => fileByteArray.push(uint));
        }
        resolve(fileByteArray);
      };
    } catch (e) {
      reject(e);
    }
  });
}

function fileToText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      let fileText: string | null | ArrayBuffer = "";
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = (evt) => {
        if (evt.target!.readyState === FileReader.DONE) {
          fileText = evt.target!.result;
          resolve(fileText as string);
        }
      };
    } catch (e) {
      reject(e);
    }
  });
}

export const truncateDecimals = (num: number, numDecimals: number) => {
  let divisor = Math.pow(10, numDecimals);
  return Math.floor(num * divisor) / divisor;
};

export { fileToByteArray, fileToText };
