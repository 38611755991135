import { useEffect } from "react";
import userManager, {
  signinRedirectCallback,
  signoutRedirect,
  loadUserFromStorage,
} from "../../auth/userServices";
import { useNavigate } from "react-router-dom";

import logger from "../../utils/logger";
import { authResultIsAdmin } from "../../utils/authUtils";
import { authActions } from "../../store/authSlice";
import { useAppDispatch } from "../../store/hooks";
import { User } from "oidc-client-ts";

const Callback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    async function signinAsync() {
      logger.log("Inside TestCallback!");
      await signinRedirectCallback()
        .then((res: User) => {
          logger.log("Test Callback success!!", res);
          loadUserFromStorage();
          if (authResultIsAdmin(res)) {
            navigate("/admin/caselist");
          } else {
            navigate("/caselist");
          }
        })
        .catch((err: any) => {
          logger.error("Test Callback Error!!", err);
          signoutRedirect();
        });
    }
    signinAsync();
  }, []);

  return <div>Redirecting...</div>;
};

export default Callback;
