import { UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";

const oidc_authority_url = process.env.REACT_APP_OIDC_AUTHORITY_URL;
const oidc_authority_client_id = process.env.REACT_APP_OIDC_AUTHORITY_CLIENT_ID;

export const OIDC_CONFIG: UserManagerSettings = {
  authority: oidc_authority_url || "",
  client_id: oidc_authority_client_id || "",
  response_type: "code",
  scope: "openid",
  response_mode: "query",
  accessTokenExpiringNotificationTimeInSeconds: 60,
  redirect_uri: window.location.origin + "/callback",
  post_logout_redirect_uri: window.location.origin + "/logout",
  stateStore: new WebStorageStateStore({ store: window.sessionStorage }), //Not sure if this is important
  userStore: new WebStorageStateStore({ store: window.localStorage }), //This is VERY important! Will save the user data inside of local instead of session storage, making it persistant
  metadata: {
    issuer: oidc_authority_url,
    authorization_endpoint: oidc_authority_url + "/services/oauth2/authorize",
    userinfo_endpoint: oidc_authority_url + "/services/oauth2/userinfo",
    token_endpoint: oidc_authority_url + "/services/oauth2/token",
    end_session_endpoint: oidc_authority_url + "/services/auth/idp/oidc/logout",
    jwks_uri: oidc_authority_url + "/id/keys",
  },
};
//https://netnumbersecondorg.force.com/customers/login

// issuer: oidc_authority_url,
// authorization_endpoint: `${cognito_domain}/oauth2/authorize`,
// userinfo_endpoint: `${cognito_domain}/oauth2/userInfo`,
// token_endpoint: `${cognito_domain}/oauth2/token`,
// end_session_endpoint: `${cognito_domain}/logout?logout_uri=${encodeURIComponent(
//   window.location.origin + "/logout"
// )}&client_id=${oidc_authority_client_id}`,
// jwks_uri: `${oidc_authority_url}/.well-known/jwks.jsons`,
