import { FaRegWindowClose, FaEdit } from "react-icons/fa";
import { conflictCaseActions } from "../../store/conflictCasesSlice";
import Filter from "../../model/store/filter";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface FilterListItemsProps {
  onClickEdit: (filter: Filter) => void;
}

const FilterListItems = ({ onClickEdit }: FilterListItemsProps) => {
  const dispatch = useAppDispatch();
  const filtersList = useAppSelector((state) => state.conflictCase.filters);

  const removeFilterComponent = (id: number) => {
    dispatch(conflictCaseActions.removeFilter(id));
  };

  const editFilterComponent = (id: number) => {
    let filter = filtersList.find((filter) => filter.id === id);
    onClickEdit(filter!);
  };

  return (
    <>
      {filtersList.map(
        ({ fieldDisplay, operatorDisplay, valueDisplay, id }) => {
          return (
            <div className="filter btn" key={id}>
              <div className="filter-text">
                {operatorDisplay !== "Case Contains"
                  ? applyStringLengthLimit(
                      `${fieldDisplay} ${operatorDisplay} ${valueDisplay}`
                    )
                  : applyStringLengthLimit(
                      `${operatorDisplay} ${fieldDisplay} ${valueDisplay}`
                    )}
              </div>
              <div className="close-filter-button">
                <FaEdit
                  fontSize="1.5rem"
                  style={{ marginRight: "0.5rem" }}
                  className="close-fitler-button"
                  onClick={() => editFilterComponent(id)}
                />
                <FaRegWindowClose
                  fontSize="1.5rem"
                  className="close-fitler-button"
                  onClick={() => removeFilterComponent(id)}
                />
              </div>
            </div>
          );
        }
      )}
    </>
  );
};

const FILTER_STRING_LENGTH_LIMIT = 56;
const applyStringLengthLimit = (value: string): string => {
  if (value.length > FILTER_STRING_LENGTH_LIMIT) {
    return value.substring(0, FILTER_STRING_LENGTH_LIMIT) + "...";
  }
  return value;
};

export { FilterListItems };
