import {
  initialSyncAxiosAdmin,
  initialSyncAsProvisionerAdmin,
  fetchCaseAxiosAdmin,
  removeCurrentPidEmailAxios,
  addCurrentPidEmailAxios,
  editCurrentPidEmailAxios,
  editCurrentPidUserAxios,
  removeCurrentPidNnidAxios,
  editCurrentPidNnidAxios,
  addCurrentPidNnidAxios,
  enableProvisionerPortalAxios,
  disableProvisionerPortalAxios,
  updatePortalConfigurationsAxios,
  removeNnidOwnerAxios,
  addNnidOwnerAxios,
  editNnidOwnerAxios,
} from "../service/adminService";
import { adminActions, PortalConfigurations } from "../store/adminSlice";
import { conflictCaseActions } from "../store/conflictCasesSlice";
import { notificationActions } from "../store/notificationsSlice";
import { handleRequestError } from "./middlewareUtils";
import logger from "../utils/logger";
import { MiddlewareType } from "./middlewareType";
import { NnidType } from "../model/store/nnidType";
import Nnid from "../model/store/nnid";
import { enableNewProvisioner } from "../reducer/adminReducers";
import { DisplayableValue } from "../model/store/displayableValue";
import UpdateConfigurationsInputDTO from "../model/input/UpdateConfigurationsInputDTO";
import { documentationActions } from "../store/documentationSlice";
import EmailSetting from "../model/store/emailSetting";
import { emailSettingActions } from "../store/emailSettingsSlice";
import EmailSettingDTO from "../model/generic/emailSettingDTO";
import NnidOwner from "../model/store/nnidOwner";
import RemoveNnidOwnerInputDTO from "../model/input/removeNnidOwnerInputDTO";
import EditNnidOwnerInputDTO from "../model/input/editNnidOwnerInputDTO";
import AddNnidOwnerInputDTO from "../model/input/addNnidOwnerInputDTO";

export const initialSyncAdminMiddleware = (): MiddlewareType => {
  return (dispatch, getState) => {
    logger.log("initialSyncAdminMiddleware invoked");
    if (getState().auth.isAdmin) {
      initialSyncAxiosAdmin()
        .then((res) => {
          logger.log(res);
          const {
            enabledProvisionerList,
            allProvisionerList,
            configurations,
            nnidOwnersList,
          } = res.data;

          enabledProvisionerList.sort((a, b) => {
            return a.commonName.localeCompare(b.commonName);
          });

          allProvisionerList.sort((a, b) => {
            return a.commonName.localeCompare(b.commonName);
          });

          dispatch(
            adminActions.setAllProvisionerList(
              allProvisionerList.map((provisioner) => {
                return {
                  value: provisioner.pid,
                  display: provisioner.commonName,
                };
              })
            )
          );

          dispatch(
            adminActions.setEnabledProvisionerList(
              enabledProvisionerList.map((provisioner) => {
                return {
                  value: provisioner.pid,
                  display: provisioner.commonName,
                };
              })
            )
          );

          dispatch(
            adminActions.updatePortalConfigurations({
              NEW_CASE_EXPIRES_DAYS: configurations.newCaseExpiresDays,
              NUM_TNS_ALLOWED: configurations.numTnsAllowed,
              CLOSE_EXPIRED_CASES: configurations.closeExpiredCases,
              HEALTHCHECK_ALARM_THRESHOLD:
                configurations.healthCheckAlarmThreshold,
              OSR_CONNECTION_BOS_OR_CHI: configurations.osrConnectionBosOrChi,
            })
          );

          dispatch(
            documentationActions.setDocumentationList(res.data.documentation)
          );

          let mappedNnidOwners = nnidOwnersList.map((nnidOwnerData) => {
            let prov = enabledProvisionerList.find(
              (prov) => prov.pid === nnidOwnerData.pid
            );
            let pidDisplayName = "";

            if (prov) {
              pidDisplayName = prov.commonName;
            }

            return {
              pid: nnidOwnerData.pid,
              nnid: nnidOwnerData.nnid,
              pidDisplayName: pidDisplayName,
            };
          });

          dispatch(adminActions.setNnidOwnerList(mappedNnidOwners));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
        });
    }
  };
};

export const initialSyncAsProvisionerAdminMiddleware = (): MiddlewareType => {
  return (dispatch, getState) => {
    logger.log("initialSyncAsProvisionerAdminMiddleware invoked");
    let { currentPid } = getState().admin;
    let pid;
    if (currentPid && currentPid.value) {
      pid = currentPid.value;
    }
    if (pid && getState().auth.isAdmin) {
      if (getState().conflictCase.caseList.length === 0) {
        dispatch(conflictCaseActions.setIsLoading(true));
      }
      initialSyncAsProvisionerAdmin(pid)
        .then((res) => {
          dispatch(
            notificationActions.setNotificationList(res.data.notifications)
          );
          dispatch(conflictCaseActions.setCaseList(res.data.cases));
          dispatch(
            adminActions.setProvisionerEmailDistributionList(
              res.data.distributionEmailsV2
            )
          );
          dispatch(
            adminActions.setProvisionerNnidList(
              res.data.nnids.map((nnid) => {
                return {
                  display: nnid.display,
                  value: nnid.nnid,
                  type: nnid.authPolicy,
                } as Nnid;
              })
            )
          );
          dispatch(adminActions.setProvisionerUserList(res.data.users));
          dispatch(
            emailSettingActions.setEmailSettingList(
              res.data.distributionEmailsV2 as EmailSetting[]
            )
          );

          dispatch(conflictCaseActions.setIsLoading(false));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
          dispatch(conflictCaseActions.setIsLoading(false));
          dispatch(notificationActions.setIsLoading(false));
        });
    }
  };
};

export function fetchCaseAdminMiddleware(caseId: string): MiddlewareType {
  return (dispatch, getState) => {
    logger.log("fetchCaseAdminMiddleware invoked");
    let pid = getState().admin.currentPid?.value;

    if (!getState().conflictCase.isLoading && pid) {
      logger.log("Inside of fetch case");
      dispatch(conflictCaseActions.setIsLoading(true));
      fetchCaseAxiosAdmin(pid, caseId)
        .then((res) => {
          let { data } = res;
          dispatch(conflictCaseActions.setCase(data));
          dispatch(conflictCaseActions.setIsLoading(false));
        })
        .catch((err) => {
          handleRequestError(dispatch, err);
          dispatch(conflictCaseActions.setIsLoading(false));
        });
    }
  };
}

export function addCurrentPidEmailMiddleware(
  emailSettings: EmailSettingDTO
): MiddlewareType {
  return (dispatch, getState) => {
    let pid = getState().admin.currentPid.value;
    dispatch(adminActions.setIsLoading(true));
    addCurrentPidEmailAxios({ pid, emailSettings })
      .then(() => {
        dispatch(adminActions.addCurrentPidEmail(emailSettings));
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function editCurrentPidEmailMiddleware(
  oldEmailAddress: EmailSetting,
  newEmailSettings: EmailSetting
): MiddlewareType {
  return (dispatch, getState) => {
    let pid = getState().admin.currentPid.value;
    dispatch(adminActions.setIsLoading(true));
    editCurrentPidEmailAxios({
      pid: pid,
      oldEmailAddress: oldEmailAddress.emailAddress,
      newEmailSettings: newEmailSettings,
    })
      .then((res) => {
        dispatch(
          adminActions.editCurrentPidEmail({
            oldEmail: oldEmailAddress,
            newEmail: newEmailSettings,
          })
        );
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function removeCurrentPidEmailMiddleware(
  emailSetting: EmailSetting
): MiddlewareType {
  return (dispatch, getState) => {
    let pid = getState().admin.currentPid.value;
    dispatch(adminActions.setIsLoading(true));
    removeCurrentPidEmailAxios({ pid: pid, email: emailSetting.emailAddress })
      .then((res) => {
        dispatch(adminActions.removeCurrentPidEmail(emailSetting));
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function editCurrentPidUserUsernameMiddleware(
  email: string,
  newDisplayName: string
): MiddlewareType {
  return (dispatch, getState) => {
    let pid = getState().admin.currentPid.value;
    dispatch(adminActions.setIsLoading(true));
    editCurrentPidUserAxios({ pid, email, newDisplayName })
      .then((res) => {
        dispatch(
          adminActions.editCurrentPidUsersDisplayname({
            email,
            newDisplayName,
          })
        );
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function addCurrentPidNnidMiddleware(
  nnidValue: string,
  nnidDisplay: string,
  nnidType: NnidType
): MiddlewareType {
  return (dispatch, getState) => {
    let pid = getState().admin.currentPid.value;
    dispatch(adminActions.setIsLoading(true));
    addCurrentPidNnidAxios({
      pid,
      nnid: nnidValue,
      display: nnidDisplay,
      type: nnidType,
    })
      .then((res) => {
        dispatch(
          adminActions.addCurrentPidNnid({
            value: nnidValue,
            display: nnidDisplay,
            type: nnidType,
          } as Nnid)
        );
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function editCurrentPidNnidMiddleware(
  nnid: string,
  newNnidDisplay: string,
  newNnidType: NnidType
): MiddlewareType {
  return (dispatch, getState) => {
    let pid = getState().admin.currentPid.value;
    dispatch(adminActions.setIsLoading(true));
    editCurrentPidNnidAxios({ pid, nnid, newNnidDisplay, newNnidType })
      .then((res) => {
        dispatch(
          adminActions.editCurrentPidNnid({
            oldNnidValue: nnid,
            newNnidDisplay: newNnidDisplay,
            newNnidType: newNnidType,
          })
        );
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function removeCurrentPidNnidMiddleware(nnid: string): MiddlewareType {
  return (dispatch, getState) => {
    let pid = getState().admin.currentPid.value;
    dispatch(adminActions.setIsLoading(true));
    removeCurrentPidNnidAxios({ pid, nnid })
      .then((res) => {
        dispatch(adminActions.removeCurrentPidNnid(nnid));
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function enableNewProvisionerMiddleware(
  provisioner: DisplayableValue<string>
): MiddlewareType {
  return (dispatch, getState) => {
    dispatch(adminActions.setIsLoading(true));
    enableProvisionerPortalAxios({ pid: provisioner.value })
      .then((res) => {
        dispatch(adminActions.enableNewProvisioner(provisioner));
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function removeProvisionerMiddleware(pid: string): MiddlewareType {
  return (dispatch, getState) => {
    dispatch(adminActions.setIsLoading(true));
    disableProvisionerPortalAxios({ pid })
      .then((res) => {
        dispatch(adminActions.disableProvisioner(pid));
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function updatePortalConfigurationsMiddleware(
  newPortalConfig: PortalConfigurations
): MiddlewareType {
  return (dispatch, getState) => {
    dispatch(adminActions.setIsLoading(true));
    let input: UpdateConfigurationsInputDTO = {
      newConfigurations: {
        newCaseExpiresDays: newPortalConfig.NEW_CASE_EXPIRES_DAYS,
        numTnsAllowed: newPortalConfig.NUM_TNS_ALLOWED,
        closeExpiredCases: newPortalConfig.CLOSE_EXPIRED_CASES,
        healthCheckAlarmThreshold: newPortalConfig.HEALTHCHECK_ALARM_THRESHOLD,
        osrConnectionBosOrChi: newPortalConfig.OSR_CONNECTION_BOS_OR_CHI,
      },
    };
    updatePortalConfigurationsAxios(input)
      .then(() => {
        dispatch(adminActions.updatePortalConfigurations(newPortalConfig));
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function removeNnidOwnerMiddleware(
  nnidOwner: NnidOwner
): MiddlewareType {
  return (dispatch, getState) => {
    dispatch(adminActions.setIsLoading(true));
    let input: RemoveNnidOwnerInputDTO = {
      nnidOwner: nnidOwner,
    };
    removeNnidOwnerAxios(input)
      .then((res) => {
        dispatch(adminActions.removeNnidOwner(nnidOwner));
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function editNnidOwnerMiddleware(
  nnid: string,
  updatedNnidOwner: NnidOwner
): MiddlewareType {
  return (dispatch, getState) => {
    dispatch(adminActions.setIsLoading(true));
    let input: EditNnidOwnerInputDTO = {
      updatedNnidOwner: updatedNnidOwner,
    };
    editNnidOwnerAxios(input)
      .then((res) => {
        dispatch(adminActions.editNnidOwner({ nnid, updatedNnidOwner }));
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}

export function addNnidOwnerMiddleware(nnidOwner: NnidOwner): MiddlewareType {
  return (dispatch, getState) => {
    dispatch(adminActions.setIsLoading(true));
    let input: AddNnidOwnerInputDTO = {
      nnidOwner: nnidOwner,
    };
    addNnidOwnerAxios(input)
      .then((res) => {
        dispatch(adminActions.addNnidOwner(nnidOwner));
        dispatch(adminActions.setIsLoading(false));
      })
      .catch((err) => {
        handleRequestError(dispatch, err);
        dispatch(adminActions.setIsLoading(false));
      });
  };
}
