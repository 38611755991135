import { useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useModalContext } from "../../context/ModalContext";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import ActionButtons from "../../components/ui/ActionButtons";
import { Button, Paper, Typography } from "@mui/material";
import Nnid from "../../model/store/nnid";
import TextboxInput from "../../components/forms/TextboxInput";
import DropDown from "../../components/forms/DropDown";
import { NnidType } from "../../model/store/nnidType";
import {
  addCurrentPidNnidMiddleware,
  editCurrentPidNnidMiddleware,
  removeCurrentPidNnidMiddleware,
} from "../../middleware/adminMiddleware";
import { isValidNnid } from "../../utils/genericUtils";
import { useDispatch } from "react-redux";
import InfoToolTip from "../../components/ui/InfoToolTip";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Nnid",
    minWidth: 120,
    valueGetter: (params: GridValueGetterParams) => params.row.value,
  },
  { field: "display", headerName: "Nnid Display Name", minWidth: 400 },
  {
    field: "type",
    headerName: "Nnid Type",
    minWidth: 200,
  },
];

const Nnids = () => {
  const nnids = useAppSelector((state) => state.admin.currentPidNnids);
  const [selectedNnid, setSelectedNnid] = useState<GridRowSelectionModel>([]);
  const { displayModal } = useModalContext();

  const noSelectedNnid = selectedNnid.length < 1;

  const nnidRows = useMemo(() => {
    return nnids.map((nnid, index) => {
      return {
        id: nnid.value,
        ...nnid,
      };
    });
  }, [nnids]);

  const onRowClick = (event: GridRowSelectionModel) => {
    let newSelectedId = [] as GridRowSelectionModel;
    if (event.length > 0) {
      newSelectedId = [event.pop()] as GridRowSelectionModel;
    } else {
      newSelectedId = [];
    }
    setSelectedNnid(newSelectedId);
  };

  const removeOnClick = () => {
    let nnid = nnids.find((nnid) => {
      return nnid.value === selectedNnid[0];
    });

    displayModal(
      <RemoveNnidModalContent nnid={nnid!} />,
      "Remove NNID",
      { width: "50%" },
      true
    );
  };

  const editOnClick = () => {
    let nnid = nnids.find((nnid) => {
      return nnid.value === selectedNnid[0];
    });

    displayModal(
      <NewOrEditNnidModalContent
        isEdit={true}
        nnid={nnid}
        clearSelectedNnid={() => {
          setSelectedNnid([]);
        }}
      />,
      "Edit NNID",
      { width: "50%" },
      true
    );
  };

  const newOnClick = () => {
    displayModal(
      <NewOrEditNnidModalContent />,
      "New NNID",
      { width: "50%" },
      true
    );
  };

  return (
    <>
      <Typography variant="h3" sx={{ mt: "2rem", mb: "2rem" }}>
        Nnids
        <InfoToolTip>
          <Typography>
            <span className="bold">NOTE:</span> This will only affect what
            options the PORTAL gives to the users. It has no affect on the
            actual nnSR, or what NNIDs the nnSR will allow them to use. A new
            NNID must be configured in both locations.
          </Typography>
        </InfoToolTip>
      </Typography>
      <p className="italic"></p>
      <ActionButtons
        removeEditable={noSelectedNnid}
        editEditable={noSelectedNnid}
        removeOnClick={removeOnClick}
        editOnClick={editOnClick}
        newOnClick={newOnClick}
      />
      <Paper className="provisioner-info-data-container">
        <DataGrid
          rows={nnidRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          onRowSelectionModelChange={onRowClick}
          rowSelectionModel={selectedNnid}
        />
      </Paper>
    </>
  );
};

interface NewOrEditNnidModalInterface {
  isEdit?: boolean;
  nnid?: Nnid;
  clearSelectedNnid?: () => void;
}

const NewOrEditNnidModalContent = ({
  isEdit = false,
  nnid,
  clearSelectedNnid,
}: NewOrEditNnidModalInterface) => {
  const NO_ERROR_STATE = {
    isValueError: false,
    valueErrorMessage: "",
    isDisplayError: false,
    displayErrorMessage: "",
    isTypeError: false,
    typeErrorMessage: "",
  };

  const provisionerCommonName = useAppSelector(
    (state) => state.admin.currentPid.display
  );
  const currentPidNnids = useAppSelector(
    (state) => state.admin.currentPidNnids
  ).map((nnid) => nnid.value);
  const [nnidValue, setNnidValue] = useState(nnid?.value);
  const [nnidDisplay, setNnidDisplay] = useState(nnid?.display);
  const [nnidType, setNnidType] = useState(nnid?.type);
  const [errorState, setErrorState] = useState(NO_ERROR_STATE);
  const { closeModal } = useModalContext();
  const dispatch = useAppDispatch();

  const onClickAddButton = () => {
    let isError = false;
    if (!nnidValue || nnidValue === "") {
      isError = true;
      setErrorState((currError) => {
        return {
          ...currError,
          isValueError: true,
          valueErrorMessage: "This field cannot be empty!",
        };
      });
    }

    if (!nnidDisplay || nnidDisplay === "") {
      isError = true;
      setErrorState((currError) => {
        return {
          ...currError,
          isDisplayError: true,
          displayErrorMessage: "This field cannot be empty!",
        };
      });
    }

    if (!nnidType) {
      isError = true;
      setErrorState((currError) => {
        return {
          ...currError,
          isTypeError: true,
          typeErrorMessage: "This field cannot be empty!",
        };
      });
    }

    if (!isValidNnid(nnidValue!)) {
      isError = true;
      setErrorState((currError) => {
        return {
          ...currError,
          isValueError: true,
          valueErrorMessage: "This is not a valid Nnid!",
        };
      });
    }

    if (!isEdit && currentPidNnids.includes(nnidValue!)) {
      isError = true;
      setErrorState((currError) => {
        return {
          ...currError,
          isValueError: true,
          valueErrorMessage: "This provisioner already has this Nnid!",
        };
      });
    }

    if (isError) {
      return;
    }

    if (!isEdit) {
      dispatch(
        addCurrentPidNnidMiddleware(nnidValue!, nnidDisplay!, nnidType!)
      );
    } else {
      dispatch(
        editCurrentPidNnidMiddleware(nnidValue!, nnidDisplay!, nnidType!)
      );
      clearSelectedNnid!();
    }
    closeModal();
  };

  const onClickNoButton = () => {
    closeModal();
  };

  return (
    <>
      <p>
        You have selected to{" "}
        <span className="bold">{isEdit ? "edit" : "add"}</span> a nnid for
        provisioner {provisionerCommonName}.
      </p>
      {isEdit && (
        <ol>
          <span className="bold">{nnid?.value}</span>
        </ol>
      )}
      <p>
        The <span className="bold">Nnid</span> is the actual value of the Nnid
      </p>
      <p>
        The <span className="bold">Display</span> is what customers will see on
        the dropdown to select it
      </p>
      <p>
        The <span className="bold">Type</span> is the Nnid Type
      </p>
      <div className="line"></div>
      <p>Please {isEdit ? "edit" : "provide"} the following information</p>
      <div
        style={{ width: "100%" }}
        className="prov-info-nnid-edit-modal-inputs"
      >
        {errorState.isValueError && (
          <p className="input-error">{errorState.valueErrorMessage}</p>
        )}
        <TextboxInput
          sx={{ width: "100%" }}
          label="Nnid"
          variant="outlined"
          error={errorState.isValueError}
          disabled={isEdit}
          value={nnidValue}
          onChange={(e) => {
            setNnidValue(e.target.value);
          }}
        />
        {errorState.isDisplayError && (
          <p className="input-error">{errorState.displayErrorMessage}</p>
        )}
        <TextboxInput
          sx={{ width: "100%" }}
          label="Nnid Display"
          variant="outlined"
          error={errorState.isDisplayError}
          value={nnidDisplay}
          onChange={(e) => setNnidDisplay(e.target.value)}
        />
        {errorState.isTypeError && (
          <p className="input-error">{errorState.typeErrorMessage}</p>
        )}
        <DropDown
          sx={{ width: "100%" }}
          error={errorState.isTypeError}
          placeholder="Nnid Type"
          onClick={(dispValue) => setNnidType(dispValue.value)}
          initialValue={nnid?.type}
          list={["NP_LOA", "SUBSCRIBER_LOA", "TOLLFREE_LOA"].map((nnidType) => {
            return {
              value: nnidType,
              display: nnidType,
            };
          })}
        />
      </div>
      <div className="prov-info-modal-action-container">
        <Button
          onClick={onClickAddButton}
          variant="contained"
          color={isEdit ? "warning" : "success"}
        >
          {isEdit ? "Edit" : "Add"}
        </Button>
        <Button onClick={onClickNoButton} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

interface RemoveNnidModalInterface {
  nnid: Nnid;
}

const RemoveNnidModalContent = ({ nnid }: RemoveNnidModalInterface) => {
  const provisionerCommonName = useAppSelector(
    (state) => state.admin.currentPid.display
  );
  const { closeModal } = useModalContext();
  const dispatch = useAppDispatch();

  const onClickRemoveButton = () => {
    dispatch(removeCurrentPidNnidMiddleware(nnid.value));
    closeModal();
  };

  const onClickNoButton = () => {
    closeModal();
  };

  return (
    <>
      <p>
        You have selected to remove Nnid {nnid.value} from{" "}
        {provisionerCommonName}'s selection of Nnids to provision to on the
        portal
      </p>
      <p>
        If you continue, the provisioner will{" "}
        <span className="bold">
          no longer see this nnid on their dropdown of Nnids to select from!
        </span>
      </p>
      <p>Would you like to delete the following Nnid?</p>
      <ol>
        <li>
          <span className="bold">{nnid.value}</span> / {nnid.display}
        </li>
      </ol>
      <div className="prov-info-modal-action-container">
        <Button onClick={onClickRemoveButton} variant="contained" color="error">
          Remove
        </Button>
        <Button onClick={onClickNoButton} variant="outlined" color="warning">
          Nevermind
        </Button>
      </div>
    </>
  );
};

export default Nnids;
