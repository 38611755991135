import { User } from "oidc-client-ts";

const USER_ROLE_ATTRIBUTE_NAME = "userRole";
const USER_PID_ATTRIBUTE_NAME = "provisionerId";
const OPERATIONS_ENGINEER_ROLE = "Operations Engineer";
const OPERATIONS_VP_ROLE = "VP, Operations";

//TODO; Find type for this stuff?

export function authResultIsAdmin(res: any) {
  if (!res.profile.custom_attributes) return false;
  if (!res.profile.custom_attributes[USER_ROLE_ATTRIBUTE_NAME]) return false;

  const userRole = res.profile.custom_attributes[USER_ROLE_ATTRIBUTE_NAME];

  return (
    userRole === OPERATIONS_ENGINEER_ROLE || userRole === OPERATIONS_VP_ROLE
  );
}

export function authResultGetPid(res: any) {
  if (!res.profile.custom_attributes) return "";
  if (!res.profile.custom_attributes[USER_PID_ATTRIBUTE_NAME]) return "";

  return res.profile.custom_attributes[USER_PID_ATTRIBUTE_NAME];
}
