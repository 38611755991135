import LogLevel from "../model/generic/logLevel";
import Log from "../model/store/log";

const envLevel = process.env.REACT_APP_LOGGING_LEVEL;
const NO_OP = () => {};

interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}

interface ConsoleLoggerInterface {
  error: LogFn;
  warn: LogFn;
  log: LogFn;
  debug: LogFn;
}

interface ConsoleLoggerSettings {
  level: string | undefined;
}

const cleanAuthenticationTokens = (obj: { [key: string]: any }) => {
  try {
    Object.keys(obj).forEach((key) => {
      if (
        key === "Authorization" ||
        key === "authorization" ||
        key === "id_token" ||
        key === "access_token" ||
        key === "refresh_token"
      ) {
        delete obj[key];
      } else if (
        typeof obj[key] === "object" &&
        !Array.isArray(obj[key]) &&
        obj[key] !== null
      ) {
        cleanAuthenticationTokens(obj[key]);
      }
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * Allowed Levels: [error, warn, log, debug]
 */
export class ConsoleLogger implements ConsoleLoggerInterface {
  readonly debug!: LogFn;
  readonly log: LogFn;
  readonly warn: LogFn;
  readonly error: LogFn;

  private _logQueue: Log[];

  private readonly _appendLog = (message: string, level: LogLevel) => {
    this._logQueue.push({
      ts: new Date().getTime(),
      message: message,
      level: level,
      countRetries: 0,
    });
  };

  public popLogs = (popAmount: number) => {
    let returned: Log[] = [];
    for (let i = 0; i < popAmount; i++) {
      let log = this._logQueue.pop();
      if (log) {
        returned.push(log);
      }
    }
    return returned;
  };

  OP =
    (loggingFunction: LogFn, level: LogLevel) =>
    (...optionalParams: any[]) => {
      let message: string = "";
      loggingFunction(...optionalParams);
      optionalParams.forEach((thing) => {
        if (typeof thing === "object") {
          cleanAuthenticationTokens(thing);
          message += JSON.stringify(thing);
        } else {
          message += thing.toString();
        }
      });

      this._appendLog(message, level);
    };

  public popLog = () => {
    return this._logQueue.pop();
  };

  public pushLog = (log: Log) => {
    this._logQueue.push(log);
  };

  constructor(options: ConsoleLoggerSettings = { level: "warn" }) {
    const { level } = options;
    this._logQueue = [];

    this.error = this.OP(console.error, "ERROR");

    if (level === "error") {
      this.warn = NO_OP;
      this.log = NO_OP;
      this.debug = NO_OP;

      return;
    }

    this.warn = this.OP(console.warn, "WARN");

    if (level === "warn") {
      this.log = NO_OP;
      this.debug = NO_OP;

      return;
    }

    this.log = this.OP(console.log, "INFO");

    if (level === "log") {
      this.debug = NO_OP;

      return;
    }

    this.debug = this.OP(console.log, "DEBUG");
  }
}

export default new ConsoleLogger({ level: envLevel });
