import { createSlice } from "@reduxjs/toolkit";
import Filter from "../model/store/filter";
import NewCaseResults from "../model/store/newCaseResults";
import Nnid from "../model/store/nnid";
import SingularCase from "../model/store/singularCase";
import * as ConflictCaseReducers from "../reducer/conflictCaseReducers";

export interface ConflictCaseState {
  caseList: SingularCase[];
  isLoading: Boolean;
  newCaseResultsData: NewCaseResults;
  newCaseCurrentPage: Number;
  filters: Filter[];
  nnidList: Nnid[];
  newCaseNnid: Nnid | null;
}

const initConflictCaseState = (): ConflictCaseState => {
  return {
    caseList: [],
    isLoading: false,
    newCaseResultsData: {} as NewCaseResults,
    newCaseCurrentPage: 0,
    filters: [],
    nnidList: [],
    newCaseNnid: null,
  };
};

const conflictCaseSlice = createSlice({
  name: "conflictCase",
  initialState: initConflictCaseState(),
  reducers: { ...ConflictCaseReducers },
});

const conflictCaseActions = conflictCaseSlice.actions;

export { conflictCaseActions, conflictCaseSlice };
