import { Typography, Checkbox, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useModalContext } from "../../context/ModalContext";
import EmailSetting from "../../model/store/emailSetting";
import ChangeEmailSettingsModal from "./ChangeEmailSettingsModal";
import currentStateHasChanged from "./currentStateHasChanged";
import fieldToDisplayMappings from "./fieldToDisplayMappings";

interface PanelBodyProps {
  emailSetting: EmailSetting;
  isEditing?: boolean;
  disableEditingMode: () => void;
}

const INIT_PANEL_ERROR_STATE = {
  hasNotChangedError: false,
  hasNotChangedErrorMessage: "",
};

const EmailPanelBody = ({
  emailSetting,
  isEditing,
  disableEditingMode,
}: PanelBodyProps) => {
  const [panelState, setPanelState] = useState<EmailSetting>(emailSetting);
  const { displayModal } = useModalContext();
  const hasValuesChanged = !currentStateHasChanged(emailSetting, panelState);
  const [errorState, setErrorState] = useState(INIT_PANEL_ERROR_STATE);

  useEffect(() => {
    setPanelState(emailSetting);
  }, [emailSetting]);

  const onClickCheckBox = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPanelState({
      ...panelState,
      //@ts-ignore
      [event.target.id!]: event.target.checked,
    });
  };

  const onClickSaveChanges = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (hasValuesChanged) {
      setErrorState(INIT_PANEL_ERROR_STATE);
      if (isEditing) {
        displayModal(
          <ChangeEmailSettingsModal
            initState={emailSetting}
            currentState={panelState}
            disableEditingMode={disableEditingMode}
          />,
          "Change Email Setting",
          { width: "50%" },
          false
        );
      }
    } else {
      setErrorState({
        hasNotChangedError: true,
        hasNotChangedErrorMessage:
          "You have not changed any settings for this email!",
      });
    }
  };

  return (
    <div>
      <div className="email-settings-panel-body-container">
        <Typography>Opt In</Typography>
        <Typography>Opt In</Typography>
        <div className="email-settings-option-container">
          <Checkbox
            size="small"
            id={"receiveCaseOpenedEmails"}
            disabled={!isEditing}
            checked={panelState.receiveCaseOpenedEmails}
            onClick={onClickCheckBox}
          />
          <span>{fieldToDisplayMappings.receiveCaseOpenedEmails}</span>
        </div>
        <div className="email-settings-option-container">
          <Checkbox
            size="small"
            id={"receiveCaseClosedEmails"}
            disabled={!isEditing}
            checked={panelState.receiveCaseClosedEmails}
            onClick={onClickCheckBox}
          />
          <span>{fieldToDisplayMappings.receiveCaseClosedEmails}</span>
        </div>
        <div className="email-settings-option-container">
          <Checkbox
            size="small"
            id={"receiveOneDayReminderEmails"}
            disabled={!isEditing}
            checked={panelState.receiveOneDayReminderEmails}
            onClick={onClickCheckBox}
          />
          <span>{fieldToDisplayMappings.receiveOneDayReminderEmails}</span>
        </div>
        <div className="email-settings-option-container">
          <Checkbox
            size="small"
            id={"receiveOneHourReminderEmails"}
            disabled={!isEditing}
            checked={panelState.receiveOneHourReminderEmails}
            onClick={onClickCheckBox}
          />
          <span>{fieldToDisplayMappings.receiveOneHourReminderEmails}</span>
        </div>
        <div className="email-settings-option-container">
          <Checkbox
            size="small"
            id={"receiveTnsAcceptedEmails"}
            disabled={!isEditing}
            checked={panelState.receiveTnsAcceptedEmails}
            onClick={onClickCheckBox}
          />
          <span>{fieldToDisplayMappings.receiveTnsAcceptedEmails}</span>
        </div>
        <div className="email-settings-option-container">
          <Checkbox
            size="small"
            id={"receiveTnsRejectedEmails"}
            disabled={!isEditing}
            checked={panelState.receiveTnsRejectedEmails}
            onClick={onClickCheckBox}
          />
          <span>{fieldToDisplayMappings.receiveTnsRejectedEmails}</span>
        </div>

        <div className="email-settings-option-container">
          <Checkbox
            size="small"
            id={"receiveCaseExpiredInitiatingPartyEmails"}
            disabled={!isEditing}
            checked={panelState.receiveCaseExpiredInitiatingPartyEmails}
            onClick={onClickCheckBox}
          />
          <span>
            {fieldToDisplayMappings.receiveCaseExpiredInitiatingPartyEmails}
          </span>
        </div>
        <div className="email-settings-option-container">
          <Checkbox
            size="small"
            id={"receiveCaseExpiredReceivingPartyEmails"}
            disabled={!isEditing}
            checked={panelState.receiveCaseExpiredReceivingPartyEmails}
            onClick={onClickCheckBox}
          />
          <span>
            {fieldToDisplayMappings.receiveCaseExpiredReceivingPartyEmails}
          </span>
        </div>
        <div className="email-settings-option-container">
          <Checkbox
            size="small"
            id={"receiveCommentEmails"}
            disabled={!isEditing}
            checked={panelState.receiveCommentEmails}
            onClick={onClickCheckBox}
          />
          <span>{fieldToDisplayMappings.receiveCommentEmails}</span>
        </div>
      </div>
      <div className="email-settings-save-changes-button-container">
        {errorState.hasNotChangedError && (
          <span className="input-error" style={{ marginRight: "1rem" }}>
            {errorState.hasNotChangedErrorMessage}
          </span>
        )}
        <Button
          className="email-settings-save-changes-button"
          variant={hasValuesChanged ? "contained" : "outlined"}
          disabled={!isEditing}
          onClick={onClickSaveChanges}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default EmailPanelBody;
