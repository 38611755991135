import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { initialSyncMiddleware } from "../middleware/conflictCaseMiddleware";
import { Outlet } from "react-router";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import logger from "../utils/logger";

const InitialSync = () => {
  const dispatch = useAppDispatch();
  const { pid, email, expires_at } = useAppSelector((state) => state.auth);

  let currTime = Math.floor(new Date().getTime() / 1000);

  const userDataLoadedFromStorage =
    pid !== "" && email !== "" && expires_at > currTime;

  logger.log({
    userDataLoadedFromStorage,
  });

  useEffect(() => {
    if (userDataLoadedFromStorage) {
      dispatch(initialSyncMiddleware());
    }
  }, [userDataLoadedFromStorage]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default InitialSync;
